<template>
  <!-- 公告详情 -->
  <div class="Announcement">
    <div class="Announcement-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"
      />
      <div class="title">{{$t('gonggaodetails.title')}}</div>
      <div></div>
    </div>
    <div class="detil" ref="detils" @click="eventAgent"></div>
    <!-- 遮罩层-->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <img :src="bigimg" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Noticelistapi } from '@/api/index'
export default {
  name: 'AnnounCement',
  data () {
    return {
      id: 0,
      //   gonggaolist: [],
      details: '',
      show: false,
      bigimg: ''
    }
  },
  //   created () {
  //   },
  async mounted () {
    await this.detils()
    await this.Noticelist()
  },
  methods: {
    detils () {
      this.id = this.$route.query.id
      // console.log(this.id)
    },
    async Noticelist () {
      const data = {
        id: this.id
      }
      const res = await Noticelistapi(data)
      // console.log(res)
      // res.data.forEach(i => {
      //   if (parseFloat(this.id) === i.id) {
      //     this.details = i.content
      //   }
      // })
      //   console.log(this.details)
      this.$refs.detils.innerHTML = res.data.content
    },
    eventAgent(e) {
      const target = e.target.nodeName
      if(target && target == 'IMG'){
        const targetsrc = e.target.src
        console.log(111, targetsrc)
        this.show = true
        this.bigimg = targetsrc
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Announcement {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Announcement-title {
     padding-top: 2.5rem;
    display: flex;
     justify-content: space-between;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 7rem;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .detil {
     margin-top: 3rem;
      color: #333;
      font-size: 1.3rem !important;
      font-weight: 500 !important;
    //   text-align: center;
    margin-left: .6rem;

    }

}
</style>
