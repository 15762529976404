<template>
  <!-- 矿机详情 -->
  <div class="product">
     <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="product-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"/>
      <div class="title">{{ title }}</div>
      <div></div>
    </div>
    <!-- 热门矿机 -->
    <div v-show="type == 1">
      <div class="Content" v-for="(item, index) in typelist1" :key="index">
        <div class="top">
          <div class="img">
            <img :src="$imgUrl + item.pic" alt="" />
          </div>
          <div class="Content-title">
            <div>{{ item.title }}</div>
            <div class="green">{{ item.status_text }}</div>
          </div>
          <div class="number">
            {{ $t("Mining.purchases") }} : {{ item.member_can_buy }}
          </div>
        </div>
        <div class="Content-info">
          <div class="left">{{ $t("Mining.Purchasing") }}</div>
          <div class="right">{{ item.price }} USDT</div>
        </div>
        <div class="Content-info">
          <div class="left">{{ $t("Mining.Daily") }}</div>
          <div class="right">{{ item.daily_output }} USDT</div>
        </div>
        <div class="Content-info">
          <div class="left">{{ $t("Mining.Valid") }}</div>
          <div class="right">{{ item.days }} {{ $t("Mining.Days") }}</div>
        </div>
        <div class="Content-info">
            <div class="left">Return rate</div>
            <div class="right">{{ item.rate }}</div>
          </div>
        <div class="tips">{{ item.remark }}</div>
        <div class="bottom">
          <div class="left" @click="torecharge">
            {{ $t("Mining.Recharge") }}
          </div>
          <div class="right" @click="showcell(item.id)">
            {{ $t("Mining.purchase") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 折扣矿机 -->
   <div v-show="type == 2">
       <div class="Content" v-for="(item, index) in typelist1" :key="index">
      <div class="top">
        <div class="img">
          <img :src="$imgUrl + item.pic" alt="" />
        </div>
        <div class="Content-title">
          <div>{{ item.title }}</div>
          <div class="green">{{ item.status_text }}</div>
        </div>
        <div class="number">
          {{ $t("Mining.purchases") }} : {{ item.member_can_buy }}
        </div>
      </div>
      <div class="Content-info">
        <div class="left">{{ $t("Mining.Purchasing") }}</div>
        <div class="right">{{ item.price }} USDT</div>
      </div>
      <div class="Content-info">
        <div class="left">{{ $t("Mining.Daily") }}</div>
        <div class="right">{{ item.daily_output }} USDT</div>
      </div>
      <div class="Content-info">
        <div class="left">{{ $t("Mining.Valid") }}</div>
        <div class="right">{{ item.days }} {{ $t("Mining.Days") }}</div>
      </div>
      <div class="tips">{{ item.remark }}</div>
      <div class="bottom">
        <div class="left" @click="torecharge">
          {{ $t("Mining.Recharge") }}
        </div>
        <div class="right" @click="showcell(item.id)">
          {{ $t("Mining.purchase") }}
        </div>
      </div>
    </div>
   </div>

    <van-action-sheet v-model="show" @select="onSelect">
      <div class="boxcontent">
        <div class="title">{{ $t("Mining.Experience") }}</div>
        <div class="power">
          <div class="Price">{{ $t("Mining.Price") }}</div>
          <div>{{ list.price }} USDT</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.profit") }}</div>
          <div>{{ list.daily_output }} USDT</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.Start") }}</div>
          <div>{{ list.start }}</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.End") }}</div>
          <div>{{ list.end }}</div>
        </div>
        <div class="agreement">
          <van-checkbox
            icon-size="2rem"
            v-model="checked"
            shape="square"
          ></van-checkbox>
          <div class="Agree">
            {{ $t("Mining.Agree") }} 《{{ $t("Mining.Escrow") }}》
          </div>
        </div>
        <div class="boxinfo" ref="boxinfo"></div>
        <div class="btn" @click="Purchase">{{ $t("Mining.Confirm") }}</div>
      </div>
    </van-action-sheet>
     </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Productpurchaseapi, Hotminingapi, discountedproductsapi, DeviceHitsapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'proDuct',
  data () {
    return {
      title: '',
      show: false,
      checked: true,
      list: {},
      id: 0,
      typelist1: [],
      typelist2: [],
      Miningmachinelist: [],
      Experience: '',
      Advanced: '',
      type: 0,
      isLoading: false
    }
  },
  created () {
    this.type = this.$route.query.type
    // console.log(this.type)
    if (this.type === '1') {
      this.title = this.$t('activity.hot')
    } else if (this.type === '2') {
      this.title = this.$t('activity.discounted')
    }
    this.Miningmachine()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 购买矿机
    async Miningmachine () {
      if (this.type === '1') {
        const res = await Hotminingapi()
        // console.log('7', res)
        this.typelist1 = res.data.product_list
        this.Miningmachinelist = res.data.product_list
      } else {
        const res = await discountedproductsapi()
        // console.log('777', res)
        this.typelist1 = res.data.product_list
        this.Miningmachinelist = res.data.product_list
      }
    },
    async showcell (id) {
      this.id = id
      this.checked = true
      this.show = true
      this.Miningmachinelist.forEach(async (i) => {
        if (i.id === id) {
          this.list = await i
          // console.log('6666', this.Miningmachinelist[v])
          this.$refs.boxinfo.innerHTML = await this.list.detail
        }
      })
      const data = {
        pro_id: id
      }
      await DeviceHitsapi(data)
    },
    // 产品购买
    async Purchase () {
      if (this.checked) {
        const data = {
          pro_id: this.id
        }
        const res = await Productpurchaseapi(data)
        // console.log('0123', res)
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.show = false
        this.checked = false
        // 购买之后重新获取数据
        this.Miningmachine()
      } else {
        Toast({
          message: this.$t('Mining.first'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    onSelect (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false
      this.checked = true
      //   Toast(item.name)
    },
    torecharge () {
      // 去充值页面
      this.$router.push('/recharges')
    }
  }
}
</script>

<style lang="less" scoped>
.product {
  background-color: #060606;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  padding-bottom: 5.8rem;
   width: 100%;
  min-height: 100vh;
  .product-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  ::v-deep .van-tab {
    line-height: 2rem;
  }
  ::v-deep .van-tab__text--ellipsis {
    font-size: 1.6rem;
  }
  ::v-deep .van-tabs__line {
    width: 4rem;
    height: 0.2rem;
  }
  .Content {
    width: 100%;
    // height: 39.1rem;
    background-color: #1f1f1f;
    margin-top: 2.7rem;
    border-radius: 1rem;
    padding-bottom: 1.55rem;
    .top {
      width: 100%;
      position: relative;
      height: 13.25rem;
      border-radius: 1rem 1rem 0rem 0rem;
      .img {
        width: 100%;
        height: 13.25rem;
        img {
          width: 100%;
          height: 13.25rem;
        }
      }
      .Content-title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        color: #fff;
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        font-size: 1.3rem;
        padding: 0rem 1.3rem;
        background-color: rgba(0, 0, 0, 0.4);
        .green {
          right: 0;
          color: #15fbb5;
        }
      }
      .number {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        color: #fc9900;
        //  font-weight: 600;
        font-size: 1.3rem;
      }
    }
    .Content-info {
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0rem 1.3rem;
      font-size: 1.2rem;
      .left {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .tips {
      color: #fb8815;
      font-size: 1.1rem;
      margin-left: 1.3rem;
      margin-top: 1.5rem;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin: 0rem 1.3rem;
      margin-top: 1.75rem;
      .left,
      .right {
        width: 14.9rem;
        height: 4.4rem;
        background-color: #15b4fb;
        border-radius: 0.7rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .right {
        background-color: #fb8815;
      }
    }
  }
  ::v-deep .van-action-sheet {
    background-color: #1f1f1f;
    // padding: 0rem 1.6rem;
    .boxcontent {
      width: 34.3rem;
      margin: 0 auto;
      .title {
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
        margin-top: 2rem;
      }
      .power {
        padding: 1.6rem 0rem;
        display: flex;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 1.2rem;
        .Price {
          font-size: 1.4rem;
          color: #fff;
        }
      }
      .agreement {
        display: flex;
        color: #fff;
        font-size: 1.4rem;
        margin-top: 1.9rem;
        .Agree {
          margin-left: 1.3rem;
        }
      }
      .boxinfo {
        width: 90%;
        color: #fff;
        font-size: 1.4rem;
        margin-top: 3rem;
        margin-bottom: 7rem;
         p {
          // margin: 1.5rem 0rem;
          span {
        background-color: #1f1f1f !important;
      }
        }
      }

      .btn {
        position: fixed;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 34.3rem;
        height: 4.4rem;
        background-color: #15b4fb;
        margin: 0 auto;
        // margin-top: 5rem;
        border-radius: 2.75rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        // margin-bottom: 5rem;
      }
    }
  }
}
</style>
