<template>
  <!-- 矿机状态 -->
  <div class="product">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh"
    :pulling-text="$t('addpull.pullyi')" :loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="product-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"/>
      <div class="title">{{$t('jjbuy.title')}}</div>
      <div class="title" @click="$router.push('/funds')">
        {{$t('jjbuy.title1')}}
      </div>
    </div>
    
	
        <div style="padding: 0rem 1.6rem;margin-top: -12rem;padding-bottom: 3rem;">
			<div class="Content"
			  v-for="(item, index) in Miningmachinelist"
			  :key="index">
			  
			  <div class="top">
			    <div class="img">
			      <img :src="item.image" alt="" />
				  <div class="newtitle">
					  <div>{{ item.name }}</div>
					  <div class="green">
					   {{$t('jjbuy.title2')}}：{{item.buy_time}}
					  </div>
				  </div>
			    </div>
			    <div class="Content-title" @click="buy(item)"> 
					   {{$t('jjbuy.title3')}}
			    </div>
			
			  </div>
			  
			  <div class="Content-info">
			    <div class="left">
					<div style="color: #41D6A5;font-weight: 600;margin-bottom: 1rem;">{{item.interest}} USDT</div>
					<div>{{$t('jjbuy.title4')}}</div>
				</div>
			    <div class="left">
			    	<div style="color: #000;font-weight: 600;margin-bottom: 1rem;">{{item.days}} {{$t('jjbuy.title5')}}</div>
			    	<div>{{$t('jjbuy.title6')}}</div>
			    </div>
				<div class="left">
					<div style="color: #000;font-weight: 600;margin-bottom: 1rem;white-space:nowrap;">{{item.price}} USDT</div>
					<div>{{$t('jjbuy.title7')}}</div>
				</div>
				<div class="left">
					<div style="color: #EE822F;font-weight: 600;margin-bottom: 1rem;">{{item.left_point}}</div>
					<div>{{$t('jjbuy.title8')}}</div>
				</div>
			  </div>
			  <div class="continfobox">
				  {{$t('jjbuy.title9')}} : <van-count-down style="margin-left: .5rem;color: #fff;" :time="item.left_time*1000" format="DD : HH : mm : ss " />
			  </div>
			</div>
		</div>
      
      <div style="position: fixed;bottom: 0;left: 0;width: 100%;background-color: #5979fb;display: flex;justify-content: space-between;align-items: center;padding:1.6rem;font-size: 1.4rem;color: #fff;">
		<div >
			<span style="white-space:nowrap;">{{$t('jjbuy.title10')}}</span>：{{pic}}  
		</div>
		<div style="display: flex;align-items: center;">
			<div style="background-color: #ee822f;border-radius: .5rem;width: 9rem;height: 4rem;text-align: center;padding-top: .5rem;" @click="$router.push('/recharges?entrance=yuebao')">{{$t('jjbuy.title11')}}</div>
			<div style="background-color: #92d050;border-radius: .5rem;margin-left: 1.5rem;width: 11rem;height: 4rem;text-align: center;line-height: 1.1;padding-top: .5rem;" @click="getback_money()">{{$t('jjbuy.title12')}}</div>
		</div>
	  </div>
    </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getproducts , getyuebaobuy , getback_money } from '@/api/index'
import { Toast } from 'vant'
import { Dialog } from 'vant';
export default {
  name: 'FUND',
  data () {
    return {
      // time:0,
	  isLoading: false,
	  Miningmachinelist:[],
	  pic:'',
	  isend:''
    }
  },
  created () {
    this.getproducts()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    async getproducts() {
		const res = await getproducts()
		this.Miningmachinelist = res.data.data
		this.pic = res.data.balance
		this.isend = res.data.is_end
	},
	async getyuebaobuy(id) {
		let data = {
			pro_id:id
		}
		const res = await getyuebaobuy(data)
		Toast({
		  message: res.msg,
		  duration: 1200,
		  className: 'noticeWidth' // 所加样式名
		})
		if(res.code==1) {
			setTimeout(()=>{
				this.$router.go(0)
			},1500)
		}
	},
	buy(id) {
		if(parseFloat(this.pic)<parseFloat(id.price)) {
			Dialog.confirm({
			  message: this.$t('jjbuy.title22'),
			  confirmButtonText:this.$t('jjbuy.title14'),
			  cancelButtonText:this.$t('jjbuy.title15')
			})
			  .then(() => {
			    // on confirm
				// console.log(1);
				this.$router.push('/recharges?entrance=yuebao')
			
			  })
			  .catch(()=>{
				  
			  })
		}else {
			Dialog.confirm({
			  message: this.$t('jjbuy.title13'),
			  confirmButtonText:this.$t('jjbuy.title14'),
			  cancelButtonText:this.$t('jjbuy.title15')
			})
			  .then(() => {
			    // on confirm
				// console.log(1);
				setTimeout(()=>{
					this.getyuebaobuy(id.id)
				},500)
			
			  })
			  .catch(()=>{
			  				  
			  })
		}

	},
	async getback_money() {
		if(this.isend !=2) {
			Toast({
			  message: this.$t('newhome.title2'),
			  duration: 1200,
			  className: 'noticeWidth' // 所加样式名
			})
			return
		}
		
		const res = await getback_money()
		   Toast({
		     message: res.msg,
		     duration: 1200,
		     className: 'noticeWidth' // 所加样式名
		   })
		if(res.code==1) {
			setTimeout(()=>{
				this.$router.go(0)
			},1500)
		}
		
	}
  }
}
</script>

<style lang="less" scoped>
.product {
  background-color: #F5F6FA;
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  padding-bottom: 5.8rem;
  width: 100%;
  min-height: 100vh;
  
  .product-title {
	  background: linear-gradient( 135deg, #5979FB 0%, #394EA7 100%);
	  background-size: 100% 20rem;
	  border-radius: 0rem 0rem 3.5rem 3.5rem;
	  background-repeat: no-repeat;
	  padding: 0rem 1.6rem;
     padding-top: 2.5rem;
	 height: 20rem;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      color: #fff;
    }
  }
  ::v-deep .van-tabs__wrap{
    border-radius: 3rem;
  }
  ::v-deep .van-tab {
    line-height: 2rem;
  }
  ::v-deep .van-tab__text--ellipsis {
    font-size: 1.6rem;
  }
  ::v-deep .van-tabs__line {
    width: 4rem;
    height: 0.2rem;
  }
  .Content {
	  padding:1.6rem;
    width: 100%;
    // height: 39.1rem;
    background-color: #fff;
    // margin-top: 2.7rem;
    border-radius: 1rem;
    // padding-bottom: 1.55rem;
	margin-bottom: 2rem;
	box-shadow: 0px 2px 3px rgba(70, 64, 244, 0.16);
    .top {
     
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  .img {
		  display: flex;
		   align-items: center;
		  img {
			  width: 5rem;
			  height: 5rem;
			  // background-color: red;
			  border-radius: .8rem;
			  vertical-align: middle;
			  margin-right: 1rem;
		  }
		  .newtitle {
			  font-size: 1.3rem;
			  font-weight: 600;
			  .green {
				  margin-top: 1rem;
			  }
		  }
	  }
	  .Content-title {
		  background-color: rgb(238, 130, 47);
		  border-radius: .5rem;
		  padding: .8rem 2rem;
		  font-size: 1.3rem;
		  color: #fff;
		  
	  }
      
     
    }
	
	.Content-info {
		width: 100%;
		border-radius: 4px 4px 4px 4px;
		border: 1.5px solid #D9D9D9;
		display: flex;
		// align-items: center;
		margin-top: 1.5rem;
		padding: 1rem 0rem;
		.left {
			width: 25%;
			text-align: center;
			font-size: 1.3rem;
			color: #A7A7A7;
		}
	}
	.continfobox {
		width: 100%;
		height: 4rem;
		line-height: 4rem;
		background: #5979FB;
		font-size: 1.3rem;
		border-radius: .5rem;
		margin-top: 1.5rem;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
    .tips {
      color: #fb8815;
      font-size: 1.1rem;
      margin-left: 1.3rem;
      margin-top: 1.5rem;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin: 0rem 1.3rem;
      margin-top: 1.75rem;
      .left,
      .right {
        width: 14.9rem;
        height: 4.4rem;
        background-color: #15b4fb;
        border-radius: 0.7rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .right {
        background-color: #fb8815;
      }
    }
  }
  ::v-deep .van-action-sheet {
    background-color: #1f1f1f;
    padding: 0rem 1.6rem;
    .title {
      margin-top: 2rem;
    }
    .power {
      padding: 1.6rem 0rem;
      display: flex;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 1.2rem;
      .Price {
        font-size: 1.4rem;
        color: #fff;
      }
    }
    .agreement {
      display: flex;
      color: #fff;
      font-size: 1.2rem;
      margin-top: 1.9rem;
      .Agree {
        margin-left: 1.3rem;
      }
    }
    // ::v-deep .van-radio__icon {
    //   width: 2rem;
    //   height: 2rem;
    //   border: 1px solid #787373;
    //   margin-right: 1.2rem;
    //   margin-top: 0.3rem;
    // }
    .btn {
      width: 34.3rem;
      height: 4.4rem;
      background-color: #15b4fb;
      margin: 0 auto;
      margin-top: 20rem;
      border-radius: 2.75rem;
      text-align: center;
      line-height: 4.4rem;
      color: #fff;
      margin-bottom: 5rem;
    }
  }
}
</style>
