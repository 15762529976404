<template>
  <!-- 公司详情 -->
  <div class="company">
    <div class="company-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"
      />
      <div class="title">{{ $t("company.title") }}</div>
      <div></div>
    </div>
    <div class="detil" ref="detils"></div>
  </div>
</template>

<script>
import { CompanyProfileapi } from '@/api/index'
export default {
  name: 'AnnounCement',
  data () {
    return {
      id: 0,
      details: ''
    }
  },
  created () {
    this.CompanyProfile()
  },
  methods: {
    async CompanyProfile () {
      const res = await CompanyProfileapi()
      // console.log(res)
      this.$refs.detils.innerHTML = res.data
    }
  }
}
</script>

<style lang="less" scoped>
.company {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  padding-bottom: 4rem;
  // padding-top: 2.5rem;
   width: 100%;
  min-height: 100vh;
  .company-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 7rem;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .detil {
    margin-top: 3rem;
    color: #333;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 1rem;
  }
 ::v-deep u {
    text-decoration: none;
  }
}
</style>
