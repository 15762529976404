<template>
  <div class="machineLog">
    <div class="record-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"/>
      <div class="title">{{$t('mlog.title')}}</div>
      <div></div>
    </div>
    <div class="body">
      <div v-for="item in loglist" :key="item" class="list">
        <img src="../assets/logimg.png" />
        <div class="right">
          <p class="yi">{{$t('mlog.listtime')}}</p>
          <p class="er">{{item.createtime}}</p>
        </div>
      </div>
    </div>
    <!-- 列表-->
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('Mypurse.Theres')"
      :loading-text="$t('Mypurse.Loading')"
      @load="onLoad"
    >
      
    </van-list> -->
  </div>
</template>

<script>
import { runningLog } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      loglist: []
    }
  },
  created () {
    this.getlog()
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        console.log(this.list.length)
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    async getlog () {
      const res = await runningLog()
      this.loglist = res.data
      console.log(res)
    }
  }
}
</script>

<style lang="less">
.machineLog{
  .record-title {
    padding-top: 2.5rem;
    padding-left: 2rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7rem;
    position: relative;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
      position: absolute;
      left: 35%;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .body{
    padding: 0 4rem;
    img{
      width: 3rem;
      height: 3rem;
      margin-right: 3rem;
    }
    .list{
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 2rem;
      border-bottom: 2px solid #EEEEEE;
      padding-bottom: 1.5rem;
      font-weight: bold;
      .yi{
        margin-bottom: 0.5rem;
        font-size: 15px;
      }
      .er{
        color: #797979;
      }
    }
  }
}
</style>
