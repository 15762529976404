<template>
  <!-- 视频介绍 -->
  <div class="Video">
    <div class="Video-title">
      <van-icon
        @click="$router.push('/my')"
        name="arrow-left"
        size="2rem"
        color="#000"/>
      <div class="title">{{$t('Video.title')}}</div>
      <div></div>
    </div>
    <div class="video" v-for="(i,v) in videolist" :key="v">
      <div class="videotitle">{{i.remark}}</div>
      <video :src="$imgUrl+i.url" controls loop x5-video-player-fullscreen="true" x5-video-player-type="h5"></video>
    </div>
  </div>
</template>

<script>
import { Videointroductionapi } from '@/api/index'
// import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      videolist: []
    }
  },
  created () {
    this.Videointroduction()
  },
  methods: {
    // 视频介绍
    async Videointroduction () {
      const res = await Videointroductionapi()
      this.videolist = res.data
    }

  }
}
</script>

<style lang="less" scoped>
.Video {
  background-color: #fff;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100vw;
  // min-height: 150vh;
  .Video-title {
    padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
      font-weight: bold;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  Video {
    width: 100%;
    height: 19rem;
    border-radius: 1rem;
    background-color: #2b2b2b;
  }
  .videotitle{
    font-size: 1.5rem;
    word-wrap: break-word;
    word-break: break-all;
    margin-bottom: .5rem;
    font-weight: bold;
  }
  .video{
    margin-top: 2.5rem;
  }
}
</style>
