<template>
  <div id="app">
    <router-view />
    <van-popup v-model="show" class-name="tcc" v-if="appcheck == 1" z-index="99">
      <div class="czbody">
        <div class="one">
          <div class="oneyi">Mi informacion</div>
          <img src="./assets/appcz.png" @click="gbcztc" />
        </div>
        <div class="two">
          <div class="twobody">
            <img src="./assets/appczer.png" />
            <span>La recarga ha sido recibida.</span>
          </div>
          <!-- <div class="twobody">
            <img src="./assets/appczer.png" />
            <span style="color: #596379;">La recarga ha sido recibida.</span>
          </div> -->
        </div>
        <div class="san">
          <p>Recarga exitosa:{{czarr.price}}USDT，Tiempo:{{czarr.updatetime}}</p>
        </div>
        <div class="four">
          <van-checkbox v-model="checked" shape="square" @change="checkchange">No más recordatorios hoy</van-checkbox>
        </div>
      </div>
    </van-popup>
    <!-- 收益到账-->
    <!-- <van-overlay :show="syshow" class-name="tcc" z-index="10000">
      <div class="czbody">
        <div class="one">
          <div class="oneyi">Mi informacion</div>
          <img src="./assets/appcz.png" @click="syshow = false" />
        </div>
        <div class="two">
          <div class="twobody">
            <img src="./assets/appczer.png" />
            <span>Llegan las ganancias</span>
          </div>
          <div class="twobody">
            <img src="./assets/appczer.png" />
            <span style="color: #596379;">Llegan las ganancias</span>
          </div>
        </div>
        <div class="san">
          <p>Monto de ingresos:15.000000USDT，Tiempo:2024-03-1111:18:06</p>
        </div>
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import { recharge_tips, recharge_ishow_tips } from '@/api/index'
export default {
  data () {
    return{
      showComponent: true, // 控制组件是否显示的变量
      show: true,
      timer: null,
      czdata: {
        show_tips: 0
      },
      czarr: '',
      checked: false,
      appcheck: 0
      // syshow: true // 收益到账弹窗
    }
  },
  created () {
    // this.getcz()
  },
  mounted () {
    this.startTimer()
  },
  beforeDestroy () {
    this.timerout()
  },
  methods: {
    getcz () {
      recharge_ishow_tips().then(res=>{
        // console.log(res)
        this.appcheck = res.data.show_tips
        // console.log(this.appcheck)
        // 测试
        // this.appcheck = 1
        // return 
        // setTimeout(()=>{
        //   res.data.show_tips = 1
        //   this.appcheck = 1
        // },6000)

        if(res.data.show_tips == 1) {
          this.timerout() // 先关闭定时器
          recharge_tips().then(res2=>{
            this.czarr = res2.data
            console.log(this.czarr, '推送充值内容')
          })
        }
        // this.show = true
      })
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.getcz()
      }, 3000); // 每秒更新一次
    },
    timerout () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    checkchange (e) {
      console.log(e)
      if (e) {
        // localStorage.setItem('appcz', 1)
        recharge_ishow_tips(this.czdata).then(res=>{
          if(res.code == 1){
            this.show = false
            this.timerout()
          }
        })
      }
    },
    gbcztc () { // 关闭按钮做的操作
      // this.show = false
      recharge_ishow_tips(this.czdata).then(res=>{
        if(res.code == 1){
          this.show = false
          this.appcheck = 0
          this.startTimer()
        }
      })
    }
  }
}
</script>

<style lang="less">
@import "./fontstyle/font.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background-color: #060606;
} */
img {
  width: 100%;
  vertical-align: middle;
}
.noticeWidth {
  background-color: #ccc !important;
  color: #000 !important;
  font-size: 1.3rem !important;
}
body {
  background-color: #fff;
  font-family:  Roboto, Arial, sans-serif !important;
}

/* app充值到账提示弹窗*/
.tcc{
  display: flex;
  align-items: center;
}
.czbody{
  background-color: #Fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  width: 35rem;
  margin: 0 auto;
  font-size: 15px;
  .one{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img{
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 1rem;
    }
  }
  .two{
    margin-top: 2rem;
    .twobody{
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      img{
        width: 2rem;
        height: 2rem;
        margin-right: .5rem;
      }
    }
  }
  .san{
    margin-top: 2rem;
  }
  .four{
    display: flex;
    justify-content: end;
    margin-top: 4rem;
  }
}
</style>
