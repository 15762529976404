// 2、创建英文语言包对象
export default {
    // 登录
    login: {
      language: 'Idioma',
      login: 'Iniciar sesión / Registro',
      usernameplaceholder: 'Por favor complete su número de teléfono',
      passwordplaceholder: 'Por favor ingrese su contraseña de inicio de sesión',
      Forgotpassword: 'Has olvidado tu contraseña',
      Determine: 'Acceder',
      rulesusername: 'El número de teléfono no puede estar vacío',
      rulespassword: 'La contraseña no puede estar vacía',
      bottomtext: 'Por favor haga clic para Acceder. Acepte los Términos de uso y la Declaración de privacidad de AMPGPT.'
    },
    // 忘记密码
    Forgot: {
      title: 'Has olvidado tu contraseña',
      phone: 'número de teléfono',
      Newpassword: 'Nueva contraseña',
      Confirmationpassword: 'Contraseña de confirmación',
      code: 'Código de verificación',
      codes: 'código',
      Determine: 'Confirmar',
      rulesemail: 'El número de teléfono no puede estar vacío',
      emailplaceholder: 'por favor ingrese su correo electrónico', // 请输入邮箱
      newpassword: 'Por favor ingrese una nueva contraseña',
      rulespassword: 'Por favor, ingrese contraseña',
      torulespassword: 'Las dos contraseñas deben ser consistentes.',
      rulescode: 'El código de verificación no puede estar vacío.',
      Changecode: 'Por favor, primero introduzca su número de teléfono.'
    },
    // 注册
    register: {
      username: 'Nombre de usuario',
      phone: 'Número de teléfono',
      password: 'Contraseña',
      code: 'Código de verificación',
      Invitation: 'Código de invitación',
      Determine: 'Confirmar',
      phonecode: 'Código de verificación móvil',
      rulesusername: 'El nombre de usuario no puede estar vacío',
      rulesnumber: 'El número de teléfono no puede estar vacío',
      rulespassword: 'La contraseña no puede estar vacía',
      rulescode: 'El código de verificación no puede estar vacío.',
      rulesInvitation: 'El código de invitación no puede estar vacío',
      Changecode: 'Por favor, primero introduzca su número de teléfono',
      codes: 'Obtener código',
      newnum: 'Crea una cuenta',
      bottomyi: 'Por favor haga clic en Confirmar. Acepte los Términos de uso y la Declaración de privacidad de AMPGPT.',
      bottomer: '© 2021-2024',
      bottomerer: 'AMPGPT.site',
      bottomersan: ', Inc. o sus afiliados'
    },
    // 首页
    home: {
      top: 'Bienvenido de nuevo',
      platform: 'La plataforma funciona de forma segura.',
      timeday: 'DÍA',
      timehour: 'HORA',
      timemin: 'MINUTO',
      timesec: 'SEGUNDO',
      currencies: 'Monedas populares',
      SignIn1: 'Día 1',
      SignIn2: 'Día 2',
      SignIn3: 'Día 3',
      SignIn4: 'Día 4',
      SignIn5: 'Día 5',
      SignIn6: 'Día 6',
      SignIn7: 'Día 7',
      gift: 'Caja de regalo misteriosa',
      Sign: 'Regístrate',
      chick: 'Iniciado sesión hoy'
    },
    // 公告
    gonggao: {
      title: 'Anuncio'
    },
    // 公告详情
    gonggaodetails: {
      title: 'Detalles del anuncio'
    },
    // 签到
    SignIn: {
      title: 'Centro de inicio de sesión',
      profit: 'Gana recompensas',
      SignIn1: 'Día 1',
      SignIn2: 'Día 2',
      SignIn3: 'Día 3',
      SignIn4: 'Día 4',
      SignIn5: 'Día 5',
      SignIn6: 'Día 6',
      SignIn7: 'Día 7',
      gift: 'Caja de regalo misteriosa',
      Sign: 'Regístrate',
      chick: 'Iniciado sesión hoy'
    },
    // 签到记录
    CheckinRecord: {
      title: 'Registro de check-in diario'
    },
    // k线图
    diagram: {
      height: 'Altura',
      low: 'bajo',
      quantity: 'cantidad 24H',
      Recharge: 'Recargar',
      purchase: 'compra',
      min1: '1 minuto',
      min5: '5 minutos',
      min30: '30 minutos',
      hour1: '1 hora',
      dayk: 'Día k',
      Weekk: 'Semana k'
    },
    // 矿机运行
    Receive: {
      Remaining: 'Hora de inicio restante',
      Start: 'Comenzar',
      Click: 'Click para ver',
      Help: 'Centro de ayuda',
      Team: 'Grupals',
      Totalnumber: 'Número total de personas',
      Todays: 'Ganancias de hoy',
      available: 'Beneficios del siguiente nivel',
      Recharge: 'Recargar',
      Withdraw: 'retirar',
      friends: 'Invita a amigos a unirse para obtener recompensas.',
      qd: 'Machine log', // 新增--启动
      yq: 'invitar', // 邀请
      lrtj: 'Estadísticas de ganancias', // 利润统计
      tjsj: 'Datos estadísticos', // 统计数据
      xj: 'informe detallado' // 细节
    },
    // 帮助中心
    Helpcenter: {
      title: 'Centro de ayuda'
    },
    // 帮助中心详情
    Helpcenterdetails: {
      title: 'Detalles del Centro de ayuda'
    },
    // 团队
    party: {
      title: 'Mi equipo',
      Totalnumber: 'Número total de personas',
      Todays: 'Recompensas de registro',
      available: 'Recargar recompensas',
      team1: 'equipo de nivel 1',
      team2: 'equipo de nivel 2',
      team3: 'equipo de nivel 3',
      Referral: 'Código de referencia',
      Share: 'Compartir enlace',
      Success: 'Replicar el éxito',
      Job: 'Título profesional',
      Income: 'Ingreso',
      Receive: 'Recibir',
      zczrs: 'Número total de inscripciones', // 注册总人数
      czzrs: 'Número total de recargas', // 充值总人数
      czyj: 'Comisión de Recarga', // 充值佣金
      zczje: 'Monto total de recarga' // 总充值金额
    },
    // 团队等级
    Grade: {
      title1: 'Nivel 1',
      title2: 'Nivel 2',
      title3: 'Nivel 3',
      Username: 'Nombre de usuario',
      Phone: 'Teléfono',
      Recharge: 'Recargar',
      qxz: 'Por favor seleccione', // 请选择 修改
      wcz: 'No recargado', // 未充值
      ycz: 'Recargado', // 有充值
      cpgq: 'Caducidad del producto', // 产品过期
      zzyx: 'Correr', // 正在运行
      title6: 'Número de serie',
    },
    // 充值选择
    Recharge: {
      title: 'Canal de recarga',
      recharge: 'Recargar',
      amount: 'Monto de recarga',
      Please: 'Por favor ingresa el monto de la recarga',
      confirm: 'Confirmar',
      success: 'elige el éxito',
      less: 'El monto de la recarga no puede ser menor a uno'
    },
    // 充值详情
    Charge: {
      Address: 'Copiar dirección',
      reminder: 'Recordatorio de recarga',
      assets: 'No agregue activos a cuentas que no sean la cuenta oficial; de lo contrario, los activos no se recuperarán',
      amount: 'El importe de la transferencia debe estar en total conformidad con el importe real del pedido.',
      Upload: 'Enviar orden de recarga',
      Please: 'Por favor asegúrese de que la imagen esté seleccionada.',
      Success: 'Replicar el éxito',
      Remark: 'Observación: Recuerde cargar la captura de pantalla después de recargar'
    },
    // 充值记录
    Rechargerecord: {
      title: 'Registro de recarga',
      Date: 'Fecha',
      Amount: 'Cantidad',
      Channel: 'Canal',
      State: 'Estado',
      recharged: 'Ser recargado',
      successful: 'Recarga exitosa',
      Notrecharged: 'No recargado',
      shzt: 'Estado de auditoría', // 审核状态
      shz: 'en revisión' // 审核中
    },
    // 提现
    Withdraw: {
      title: 'Retirar',
      Amount: 'Cantidad',
      Available: 'Disponible',
      All: 'Todo',
      address: 'DIRECCIÓN',
      Submit: 'Entregar',
      Currently: 'Actualmente admite dos métodos de retiro',
      Bank: 'Horario de retiro bancario: los retiros se pueden realizar en cualquier momento de lunes a viernes',
      Arrival: 'Hora de llegada: prepárese según la hora de llegada del banco, generalmente entre 30 y 48 horas.',
      within: 'La hora de llegada es dentro de las 72 horas durante el período del subsidio de día libre de impuestos.',
      withdrawal: 'El monto del retiro no puede ser inferior a 15',
      receiving: 'Primero vincule la cuenta receptora antes de continuar con la operación.',
      min: 'Mín.',
      max: 'Máx.',
      fee: 'Tarifa'
    },
    // 采矿页面
    Mining: {
      title: 'Estrategia comercial',
      Power: 'Fuerza',
      progress: 'En curso',
      Computer: 'Potencia de la computadora',
      Purchasing: 'Precio de compra',
      Daily: 'Ingreso diario',
      Valid: 'Periodo válido',
      Days: 'Días',
      Recharge: 'Recargar',
      purchase: 'Comprar ahora', // purchase
      Miner: 'Minero',
      After: 'Después del vencimiento, el minero puede darte en cualquier momento.',
      Experience: 'Estrategia comercial',
      Price: 'Precio',
      profit: 'Beneficio del día',
      Start: 'Fecha de inicio',
      End: 'Fecha final',
      Agree: 'Aceptar',
      Escrow: 'Acuerdo de custodia',
      Artificial: 'Solución de minería de inteligencia artificial',
      Users: ' Los usuarios pueden minar comprando máquinas de minería o alquilando potencia de computación en la nube',
      Hash: 'Tasa de hash: 1TH/S-100TH/S ',
      Benefit: 'Beneficio: Obtenga el 80% de cada moneda producida, en el sistema se liquidará automáticamente en la cuenta del usuario.',
      Withdrawal: 'Retiro: Retiro en cualquier momento, 24 horas para retiro',
      experience: '(El minero de experiencias es solo para la experiencia del usuario)',
      Special: 'Nota especial',
      calculation: 'El cálculo de estos datos se basa en el precio de la moneda actual y se estima de acuerdo con los datos de la moneda del grupo de minería, y el sistema los convierte automáticamente a USD. Para obtener datos específicos de producción de moneda real, consulte la situación real para los datos de moneda verificados.',
      machine: 'Los ingresos por energía de las máquinas mineras y la computación en la nube pueden disminuir con el tiempo, y el ingreso anualizado específico puede estar sujeto al ingreso anualizado real en ese momento.',
      mining: 'Compre una máquina minera o alquile un plan de energía de computación en la nube y la tarifa de mantenimiento de custodia de la producción de moneda representa el 20% del costo del equipo de soporte de ajuste dinámico temprano. La cantidad esperada de moneda no será inferior al 70% por día. El cálculo actual se basa en el valor de producción más bajo para calcular los ingresos. La expectativa anualizada real es superior al 300%. Prevalecerá la cantidad real de moneda producida.',
      Currency: 'Currency production time T+1 (that is, within one day after the completion of nuclear delivery and custody, and deposit of pledged currency), please refer to the official actual official currency production date for details',
      Confirm: 'Confirmar',
      first: 'Confirme primero para aceptar el acuerdo de depósito en garantía',
      purchases: 'Número de compras',
      gmsl: 'Cantidad de compra',
      yjsy: 'Ingresos proyectados'
    },
    // 我的页面
    my: {
      Accumulated: 'Recompensas acumuladas',
      Today: 'Ganancias de hoy',
      total: 'los ingresos totales',
      available: 'Saldo disponible',
      wallet: 'Mi billetera',
      GPT: 'Mi GPT',
      center: 'Centro de ayuda',
      address: 'Dirección de billetera',
      envelope: 'Código Cupón',
      Video: 'Función de vídeo',
      Download: 'Descarga de la aplicación',
      company: 'Sobre la empresa',
      Sign: 'Desconectar',
      Exit: 'Salir exitosamente',
      mykj: 'Mi robot' // 我的矿机 修改
    },
    // 设置页面
    install: {
      title: 'Instalar',
      switch: 'cambiar de idioma',
      name: 'Modificar nombre',
      Password: 'Cambiar la contraseña',
      Phone: 'modificar telefono',
      binding: 'Enlace de aplicaciones'
    },
    // 上传头像
    Headimage: {
      Avatar: 'Subir avatar',
      Upload: 'Subir',
      image: 'La imagen cargada no puede estar vacía.'
    },
    // 修改名字
    ModifyName: {
      name: 'Modificar nombre',
      Determine: 'Confirmar',
      cannot: 'El nombre no puede estar vacío'
    },
    // 修改密码
    ChangePassword: {
      title: 'Cambiar la contraseña',
      Old: 'Contraseña de inicio de sesión antigua',
      New: 'Nueva contraseña de inicio de sesión',
      Confirm: 'confirmar Contraseña',
      Determine: 'Confirmar',
      oldpassword: 'Por favor ingrese la contraseña anterior',
      newpassword: 'Por favor ingrese una nueva contraseña',
      Confirmationpassword: 'Por favor, ingrese contraseña',
      twopasswords: 'Las dos contraseñas deben ser consistentes.'
    },
    // 修改手机号
    Phonenumber: {
      title: 'Número de teléfono',
      phone: 'Número de teléfono',
      code: 'Código de verificación móvil',
      codes: 'código',
      Determine: 'Confirmar',
      Phonerules: 'El número de teléfono no puede estar vacío',
      coderules: 'El código de verificación no puede estar vacío.',
      Please: 'Por favor, primero introduzca su número de teléfono.'
    },
    // 所有记录
    Mypurse: {
      title: 'My bolso',
      All: 'Todo',
      type: 'tipo',
      Recharge: 'Recargar',
      withdraw: 'Retirar',
      product: 'compra de producto',
      daily: 'ingreso diario',
      Direct: 'recompensa por referencia directa',
      envelope: 'Colección de sobres rojos',
      fixed: 'renta fija',
      check: 'registrarse',
      benefits: 'beneficios del equipo',
      Cancel: 'Cancelar',
      Theres: 'No hay mas',
      Loading: 'Cargando',
      System: 'Ajuste del sistema'
  
    },
    // 矿机状态
    product: {
      title: 'Comprar producto',
      progress: 'En curso',
      purchases: 'Número de compras',
      Computer: 'potencia de la computadora',
      price: 'Precio de compra',
      Daily: 'Ingresos diarios de producción',
      running: 'Correr',
      expired: 'Caducado',
      Expired: 'Caducado',
      start: 'hora de inicio',
      end: 'hora de finalización'
    },
    // 购买记录
    Purchaserecord: {
      title: 'Historial de compras',
      types: 'tipos de',
      product: 'nombre del producto',
      price: 'precio del producto',
      Daily: 'Regreso diario',
      start: 'hora de inicio',
      end: 'hora de finalización'
    },
    // 地址
    Address: {
      title: 'Dirección de billetera',
      already: 'Ya has añadido una dirección. Si necesita modificarlo, comuníquese con el administrador para modificarlo.'
    },
    // 新增地址
    Addressdetails: {
      title: 'Añadir dirección',
      Address: 'DIRECCIÓN',
      Determine: 'Confirmar',
      empty: 'La dirección no puede estar vacía.',
      Successfully: 'Añadido exitosamente'
    },
    // 领取红包
    Redemption: {
      title: 'Código de canje',
      Please: 'Por favor ingresa el código de canje',
      hbyi: 'Number of Inputs',
      hber: 'Enter amount',
      Determine: 'Confirmar',
      Determineer: 'Confirmar colección',
      Determinesan: 'Quiero enviar USDT',
      Exchange: 'El código de intercambio no puede estar vacío'
    },
    // 视频介绍
    Video: {
      title: 'Introducción en vídeo'
    },
    // 公司介绍
    company: {
      title: 'Sobre la empresa'
    },
    // 活动列表
    activity: {
      title: 'actividad',
      hot: 'producto caliente',
      discounted: 'productos con descuento'
    },
    // 绑定邮箱
    mail: {
      mailbox: 'Buzón',
      yzm: 'Código',
      srmail: 'Por favor, introduzca su dirección de correo electrónico', // 请输入您的电子邮件地址
      sryzm: 'enviar', // 请输入验证码
      sendcode: 'Enviar', // 发送验证码
      emptyemail: 'El correo electrónico no puede estar vacío.' // 电子邮件不能为空
    },
    // 利润
    profit: {
      jrsy: "Las ganancias de hoy", // 今日收益
      zcyqjl: 'Registrar recompensas por invitación', // 注册邀请奖励
      jqsy: 'Ingresos de la máquina', // 机器收益
      tdyjjl: 'Recompensas de comisiones de equipo', // 团队佣金奖励
      yxsr: 'Ingresos salariales mensuales', // 月薪收入
      lrze: 'Beneficio total', // 利润总额
      zzcyqjl: 'Recompensas totales por invitación de registro', // 总注册邀请奖励
      zjqsy: 'Ingresos totales de la máquina', // 总机器收益
      ztdyjjl: 'Recompensa de comisión total del equipo', // 总团队佣金奖励
      zyxsr: 'Ingresos salariales mensuales totales', // 总月薪收入
      jrzlr: "Beneficio total de hoy", // 今日总利润
      zlr: 'Beneficio tota' // 总利润
    },
    // 底部导航
    foot: {
      home: 'Hogar', // 首页
      yx: 'Compartir', // 运行
      jy: 'Robot', // 交易
      hd: 'Transacción', // 活动
      my: 'Mi' // 我的
    },
    // rechargettwo
    rechargettwo: {
      name: 'nombre',
      bank: 'banco',
      account: 'cuenta',
      qtjczjt: 'Por favor agregue una captura de pantalla de recarga',
      czje: 'Monto de recarga'
    },
    add: {
      addemail: 'Correo electrónico',
      addforbottom: 'Si la verificación del correo electrónico falla, haga clic en Contacto',
      addforbottomer: 'Official customer service',
      addregtitle: 'Registro',
      addregts: 'Registro exitoso, redireccionamiento'
    },
    addhome: {
        inbodyyi: 'Introducir',
        inbodyer: 'Recargar',
        inbodysan: 'Retirar',
        inbodysi: 'Compartir amigos',
        inbodywu: 'Grupals',
        inbodyliu: 'Centro de recompensas',
        rw: 'Recompensa',
        wallet: 'Mi billetera',
        walletyi: 'Activos totales',
        walleter: 'Saldo disponible',
        walletsan: 'Ganancia',
        walletsi: 'Ganancias de hoy',
        walletwu: 'Total de tokens AMP',
        walletliu: 'Valor total de AMP',
        help: 'Centro de ayuda',
        bottomamp: 'AMPGPT',
        bottom: ` te ayuda a dominar los secretos para ganar dinero en la era de la inteligencia artificial!`,
        bottomer: ' recopila automáticamente señales comerciales y compra y vende automáticamente. Le permite ganar dinero fácilmente las 24 horas del día y obtener ganancias comerciales automáticas. Análisis de datos independiente, comercio inteligente y garantía de ganancias estables! Únase a nosotros y comience su viaje hacia la libertad financiera!',
        bottomtwo: 'Operación segura',
        bottomtwoday: 'Día',
        bottomtwoer: 'Ingresos acumulados',
        hezuotitle: 'Plataforma de Cooperación',
        hezuoyi: 'Binance',
        hezuoer: 'Kraken',
        hezuosan: 'KuCoin',
        hezuosi: 'Marca de bits',
        hezuowu: 'Gate.io',
        hezuoliu: 'Coinbase',
        hezuoqi: 'Bybit',
        hezuoba: 'OKX',
        hezuojiu: 'bitget',
        hezuoshi: 'Bitfinex',
        tcyi: 'Regalo misterioso',
        tcer: 'caja',
        tcsan: 'Último publicado',
        gb: 'Cierre'
    },
    addrech: {
        maxusdt: 'El monto de la recarga no puede ser mayor a',
        tsyi: 'El valor de la recarga no puede exceder los 5.000',
        tser: 'Valor mínimo de carga',
        tsmessyi: 'Utilice USDT para recargar y disfrutar de un reembolso adicional. Por ejemplo, si el índice de bonificación es del 3%, si el usuario usa USDT para recargar 100USDT, puede obtener 103USDT.',
        tsmesser: 'Al completar la recarga de la cuenta utilizando cualquier método de pago, los usuarios pueden recibir recompensas en tokens AMP, puntos de lotería, recompensas en efectivo, etc. Preste atención a la información del anuncio de la empresa para obtener recompensas específicas.'
    },
    addbank: {
        banktitle: 'Recargar',
        banktitlevalue: 'Introduzca el importe de la recarga',
        tsyi: 'El monto de la recarga no puede ser cero',
        tser: 'El valor de sobrecarga no puede ser inferior a',
        btn: 'Enviar recarga',
        tssan: 'Por favor sube la imagen primero',
        bankbtn: 'Confirmar recarga'
    },
    addwith: {
        address: 'Dirección de cartera',
        addresser: 'Dirección de cuenta bancaria',
        addresssan: 'Tipo de salida',
        addresssi: 'Banco',
        tsyi: 'El monto del retiro no podrá ser menor a',
        tser: 'El monto del retiro no debe exceder',
        tssan: 'El monto del retiro no podrá ser menor a',
        jiluyi: 'Salir del registro',
        jiluer: 'Fecha',
        jilusan: 'Cantidad',
        jilusi: 'Canal',
        jiluwu: 'Estado'
    },
    address: {
        Bitcoin: 'Revertir',
        message: 'Para la seguridad de su cuenta, vuelva a emitir el retiro 24 horas después de modificar la información de la cuenta.',
        messageer: 'Por favor, únete primero.',
        usdtyi: 'Por favor ingresa la dirección de tu billetera',
        usdter: 'Solo se pueden agregar direcciones TRC20',
        usdtsan: 'Email de contacto',
        usdtsi: 'Código de verificación de correo electrónico',
        addbankyi: 'Número de cuenta',
        addbanker: 'Ingresar cuenta',
        addbanksan: 'Nombre del banco',
        addbanksi: 'Introduzca el nombre del banco',
        addbankwu: 'Por favor, escriba su nombre',
        addbankliu: 'Por favor, escriba su nombre',
        addbankqi: 'Email de contacto',
        addbankba: 'Email de contacto',
        addbankjiu: 'Código de verificación de correo electrónico',
        addbankp: 'Para garantizar que los fondos de su retiro lleguen sin problemas a su cuenta. Por favor vincule su nombre real y cuenta bancaria. De lo contrario, es posible que no reciba el retiro.',
        tsyi: 'La cuenta bancaria no puede estar vacía.',
        tser: 'el número no puede estar vacío',
        tssan: 'El correo electrónico no puede estar vacío.',
        tssi: 'El código de verificación no puede estar vacío.',
        tswu: 'el nombre no puede estar vacío',
        tsliu: 'El formato del correo electrónico es incorrecto.'
    },
    addtask: {
        title: 'Centro de recompensas',
        conright: 'Unirse',
        conrighter: 'Ahora',
        tsyi: 'Recibido',
        tser: 'Deshecho',
        titleer: 'Compartir',
        shareyi: 'Subir Enviar foto',
        shareer: 'Por favor cargue el certificado de uso compartido',
        sharesan: 'Entregar',
        sharets: 'La imagen aún no se ha subido.'
    },
    addturn: {
        start: 'Hora de finalización restante',
        problenyi: 'Esta ronda de sorteo aún no está abierta, así que estad atentos.',
        problener: 'El sorteo de lotería giratoria consumirá los puntos primero y cambiará automáticamente al saldo cuando los puntos sean insuficientes.',
        problensan: 'Consume',
		newproblensan:"puntos para participar en un sorteo.",
        problensi: 'Consume',
		newproblensi:'saldos para participar en un sorteo.',
        topboxyi: 'Puntos restantes',
        topboxer: 'Saldo disponible',
        topboxsan: 'Número de puntos acumulados',
        btn: 'Registros de lotería',
        tsyi: 'Si los puntos son inferiores a ',
		newtsyi:'has sido transferido al saldo para participar en el sorteo',
        tser: 'Saldo inferior a ',
		newtser:'no puede participar',
        tssan: 'Los que tengan menos de ',
		newtssan:'puntos no podrán participar',
        tssi: 'Saldo inferior a ',
		newtssi:' no puede participar',
        tswu: 'El sorteo aún no ha comenzado, así que estad atentos.',
        tsliu: 'No has comprado un robot y no puedes asistir al evento',
        tsqi: 'El sorteo aún no ha comenzado, así que estad atentos.',
        title: 'Disco giratorio',
        Bitcoin: 'Consumo puntual',
        Bitcoiner: 'pegar'
    },
    addrece: {
        title: 'Grupals',
        tcyi: 'Copiar link',
        tcer: 'Compartir a',
        tsyi: 'Por favor recarga y activa tu cuenta primero'
    },
    addparty: {
        incomeyi: 'Reembolso no recibido',
        incomeer: 'Ingreso diario',
        incomesan: 'Recompensas promocionales',
        lbodyyi: 'Total / Válido',
        lbodyer: 'Recarga/Comisión',
        level: 'Nivel',
        sqyj: 'Cobrar comisión',
        levelteam: 'Equipo de nivel',
        jieshaotitle: 'Reglas específicas:',
        jieshaoyi: 'Reglas de invitación',
        jieshaoer: 'Invita a más amigos a unirse a tu equipo AMP. Puede obtener más recompensas por invitación y bonificaciones por desarrollo de equipo.',
        jieshaosan: 'Invita a cada amigo a obtener el robot AMPGPT y podrás obtener una recompensa por invitación de 3USDT. Cuantos más amigos invites, más recompensas recibirás, ¡no hay límite superior!',
        jieshaosi: 'Puedes obtener una comisión permanente del equipo. Obtenga el monto de recarga de los miembros de nivel 1 (4%), el monto de recarga de los miembros de nivel 2 (2%) y el monto de recarga de los miembros de nivel 3 (0.5%).',
        jieshaowu: 'Cuando los miembros de tu equipo de nivel 123 alcancen números diferentes, podrás obtener bonificaciones y premios de la empresa en diferentes etapas cada semana y cada mes.',
        jieshaoliu: 'Cuando los usuarios activos de tu equipo de nivel 123 alcanzan números diferentes, puedes obtener bonificaciones y premios en diferentes etapas, como semanal y mensual.',
        thiszhou: 'Esta semana',
        thismonth: 'Este mes',
        lastmonth: 'El mes pasado',
        all: 'Todo',
        zdytime: 'Personalizar',
        tsyi: 'Actualmente no hay comisiones disponibles por ingresos',
        gradeyi: 'Nivel 1 Número actual de personas activas',
        gradeer: 'Nivel 2 Número actual de personas activas',
        gradesan: 'Nivel 3 Número actual de personas activas',
        gradetitle: 'Nivel Equipo',
        jintian: 'Hoy',
        kstime: 'Tiempo de empezar',
        jstime: 'Hora de finalización',
        btnqd: 'Seguro',
        qd: 'confirmar',
        qx: 'Cancelar',
        qxz: 'Porfavorelige'
    },
    addpull: {
        pullyi: 'Tire hacia abajo para refrescar',
        puller: 'Déjalo ir y actualiza',
        pullsan: 'Cargando...'
    },
    addprofit: {
        title: 'Ganancia',
        datayi: 'Proporción de recompensa de registro',
        dataer: 'Relación de ingresos de la máquina',
        datasan: 'Ratio de comisión del equipo',
        datasi: 'Relación de ingresos salariales mensuales'
    },
    addmin: {
        content: 'Tasa de retorno',
        tcyi: 'Cálculo',
        tsyi: 'Actualmente no tienes la cantidad de máquinas compradas',
        tser: 'Ya no hay cantidad',
        tssan: 'Actualmente no tienes la cantidad de máquinas compradas',
        tssi: 'La cantidad de compra no puede ser cero.'
    },
    addgift: {
        nav: 'BTC/USDT',
        normal: 'Predicción',
        navrightyi: 'Historia',
        navrighter: 'Rule',
        qhyi: 'Modo normal',
        qher: 'Modo estable',
        msgyi: 'Alto',
        msger: 'bajo',
        msgsan: 'Volumen 24H',
        fxk: 'Política de privacidad financiera',
        bonex: 'Boleto',
        btwoyi: 'Monto de la apuesta:',
        datanull: 'Actualmente no hay datos disponibles',
        tcyi: 'Cantidad',
        tcer: 'Se espera que esté disponible después de la rentabilidad.',
        tcsan: 'Tasa de tramitación:',
        tcsi: 'Cantidad en USDT',
        tcsitwo: 'Saldo disponible:',
        tcwu: 'Entregar',
        tarbaryi: '1 minuto',
        tarbarer: '5 minuto',
        tarbarsan: 'Comercio',
        tabarsi: 'Transacciones históricas',
        tabarwu: 'Esperando transacción',
        tabarliu: 'Registro de la transacción',
        tsyi: 'Por favor, consulte primero el acuerdo de privacidad.',
        tser: 'La cantidad no puede estar vacía o ser cero',
        tssan: 'No puede ser inferior al mínimo de',
        htitle: 'Historia de los números ganadores',
        htsyi: 'Qué diablos está pasando',
        rtitle: 'Regla',
        Ttitle: 'Análisis de tendencia',
        tbodyyi: 'Predicción 28',
        tbodyer: '[Señal]',
        ycboyi: 'Planificador',
        ycboer: 'Boleto',
        ycbosan: 'Predecir',
        ycbosi: 'Resultado',
        ycbowu: 'ganar',
        ycboliu: 'perder',
        ycboqi: 'Número de compradores:',
        ycboba: 'Selección rápida',
        yctcyi: 'Orden de confirmación',
        yctcer: 'Boleto',
        yctcsan: 'Opciones de apuesta',
        yctcsi: 'Cantidad de apuesta única',
        yctcwu: 'Total del pedido',
        yctcliu: 'Cancelar',
        yctcqi: 'Confirmación',
        ycts: 'El monto ingresado no puede estar vacío'
    },
    addmy: {
        mywyi: 'Activos totales:',
        mywer: 'Ganancias de hoy:',
        mywsan: 'Los ingresos totales:',
        mywsi: 'Recompensas:',
        mywwu:'Saldo disponible:',
        mywliu:'Recargar',
        mywqi: 'Retirar',
        myba: 'Duración',
        sboyi: 'Regalo misterioso',
        sboer: 'caja',
        iboyi: 'WhatsApp',
        iboer: 'Telegrama',
        ibosan: 'Correo electrónico',
        xgpassts: 'Después de cambiar la contraseña, se necesitan 24 horas para iniciar el retiro',
        appyi: 'vincular cuenta',
        apper: 'Vinculaste exitosamente Whatsapp',
        tele: 'Has vinculado Telegram correctamente',
        yx: 'Has vinculado correctamente tu dirección de correo electrónico',
        appsan: 'Cuenta:',
        appsi: 'Revertir',
        appts: 'Ya agregaste una cuenta, si necesitas modificarla, contacta al administrador para su operación.',
        yxts: 'Después de hacer clic en enviar, no olvides revisar tu carpeta de spam. Si aún no recibe el código de verificación, inténtelo nuevamente más tarde.',
        mywts: 'Detalles del registro de facturas de esta semana',
        codeyi: 'Recompensas de intercambio de USDT',
        codeer: 'Recompensas de canje de AMP',
        duryi: 'Duración',
        durer: 'Duración en línea',
        dursan: 'La duración en línea es de 30 minutos.',
        mailts: 'Somente letras minúsculas podem ser inseridas' // 只能输入小写
    },
    evaluate: {
      one: 'Opiniones de los usuarios',
      tabone: 'Última versión',
      tabtwo: 'Quiero publicar',
      input: '¡Tenga en cuenta que consulte el contenido de la revisión!',
      imgone: 'Contenido de la evaluación',
      imgtwo: 'Enviar imágenes',
      kong: 'El contenido cargado no puede estar vacío'
    },
    hongbao: {
      listtitle: 'Enviar registro',
      listyi: 'Todo',
      lister: 'Recibir',
      listsan: 'Enviar',
      tsyi: 'El sobre rojo y el monto no pueden estar vacíos',
      daodil: 'Es el final'
    },
    mlog: {
      title: 'Machine log',
      listtime: 'Hora de inicio'
    },
	newkx: {
		title: 'Monto acumulado del pedido',
		title1: 'Obtener monto de comisión',
	},
	jjbuy: {
		title:'Apreciación de la riqueza',
		title1:'Historia',
		title2:'Límite de compra',
		title3:'Comprar',
		title4:'Ingreso diario',
		title5:'Dias',
		title6:'El término',
		title7:'Monto de la suscripción',
		title8:'Cantidad restante',
		title9:'la distancia termina',
		title10:'Saldo disponible',
		title11:'Recargar',
		title12:'Reembolso de saldo',
		title13:'¿Está seguro de que desea comprar el producto modificado?',
		title14:'Confirmar',
		title15:'Cancelar',
		title16:'Historial de compras',
		title17:'Revisión pendiente',
		title18:'No pasó',
		title19:'En Curso',
		title20:'Terminado',
		title21:'Tiempo de aplicación',
		newtitle21: 'Pasando el tiempo',
		title22: 'Si el saldo es insuficiente, primero complete la recarga.',
	},
	newhome: {
		title:'La actividad ha finalizado',
		title1:'El evento no ha comenzado.',
		title2:'Espere a que termine la cuenta regresiva.'
	}
  }
  