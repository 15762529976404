<!-- 可拖拽的小球 封装 -->
<template>
    <div
      class="backHome"
      ref="floatButton"
      :style="{
        width: itemWidth + 'rem',
        height: itemHeight + 'rem',
        left: left + 'px',
        top: top + 'px',
      }">
      <!-- <div @click="goCreatePage"><img src="../assets/plus.png" alt="" /></div> -->
      <div v-if="telegram" class="display" @click="totiaozhuan(2)">
        <img src="../assets/aeroplane.png" alt="" />
      </div>
      <div v-if="whatsapp" class="display" @click="totiaozhuan(1)">
        <img src="../assets/telephone.png" alt="" />
      </div>
    </div>
</template>

<script>
import { gethomeapi } from '@/api/index'
export default {
  name: 'BackHome',
  props: {
    itemWidth: {
      // 悬浮按钮宽度
      type: Number,
      default: 5
    },
    // itemHeight: {
    //   // 悬浮按钮高度
    //   type: Number,
    //   default: 35
    // },
    gapWidth: {
      // 距离左右两边距离
      type: Number,
      default: 50
    },
    coefficientHeight: {
      // 从上到下距离比例
      type: Number,
      default: 0.46
    }
  },
  data () {
    return {
      ssicId: '',
      clientWidth: 0,
      clientHeight: 0,
      timer: null,
      currentTop: 0,
      left: 0,
      top: 0,
      none: true,
      itemHeight: 9,
      telegram: '', // 小飞机外链
      whatsapp: '' // 电话外链
    }
  },
  created () {
    // 首页详情
    this.homelist()
    // console.log(111)
    // this.ssicId = sessionStorage.getItem("ssicId");
    this.clientWidth = document.documentElement.clientWidth
    this.clientHeight = document.documentElement.clientHeight
    this.left = this.clientWidth - this.itemWidth - this.gapWidth
    this.top = this.clientHeight * this.coefficientHeight
  },

  methods: {
    totiaozhuan (id) {
      // console.log(id)
      if (id === 1) {
        window.location.href = this.whatsapp
      } else {
        window.location.href = this.telegram
      }
    },
    async homelist () {
      const res = await gethomeapi()
      // console.log('11222', res)
      this.telegram = res.data.telegram
      this.whatsapp = res.data.whatsapp
    },
    // 返回首页菜单
    goCreatePage () {
      this.$emit('goManage')
      if (this.none) {
        this.itemHeight = 15
        this.none = false
      } else {
        this.itemHeight = 3.5
        this.none = true
      }
    },
    handleScrollEnd () {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop === this.currentTop) {
        if (this.left > this.clientWidth / 2) {
          this.left = this.clientWidth - this.itemWidth - this.gapWidth
        } else {
          this.left = this.gapWidth
        }
      }
      clearTimeout(this.timer)
    }
  },
  mounted () {
    this.$nextTick(() => {
      const floatButton = this.$refs.floatButton
      floatButton.addEventListener('touchstart', () => {
        floatButton.style.transition = 'none'
      })
      // 在拖拽过程中，组件应该跟随手指的移动而移动
      floatButton.addEventListener('touchmove', (e) => {
        e.preventDefault()
        if (e.targetTouches.length === 1) {
          // 一根手指
          const touch = e.targetTouches[0]
          this.left = touch.clientX - 20
          this.top = touch.clientY - 25
        }
      })
      // 拖拽结束后，重新调整组件的位置并重新设置过渡动画
      floatButton.addEventListener('touchend', () => {
        floatButton.style.transition = 'all 0.3s'
        if (this.left > document.documentElement.clientWidth / 2) {
          this.left = document.documentElement.clientWidth - 60
        } else {
          this.left = 0
        }
      })
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollStart)
  }
}
</script>

<style lang="less" scoped>
.backHome {
  width: 3.4rem;
  height: 3.4rem;
  overflow: hidden;
  position: fixed;
  z-index: 99;
  transition: 1s all ease-out;
  // transform: translateX(90deg);
  img {
    width: 3.4rem;
    height: 3.4rem;
  }
  .display {
    margin-top: .5rem;
  }
}
</style>
