<template>
  <div class="AssiStminning">
    <div class="party-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#fff"
        @click="$router.back()"/>
      <div class="title">Robot details</div>
    </div>
    <!-- body-->
    <div class="body">
      <div class="one" v-for="(item,index) in list" :key="index">
        <img :src="item.img" class="left" />
        <div class="right">
          <div class="rightyi">
            <p>Numero de serie: {{item.sn}}</p>
            <p class="rightyip">{{item.day}}</p>
          </div>
          <ProGress :width="230" :progress="parseInt((item.already/item.number)*100)||0" :strokeWidth="15" :showInfo="true" style="margin: 1rem 0;margin-bottom: 0.5rem;"></ProGress>
          <div class="righter">
            <p>Fragmentos asistidos <span>{{item.already}}/{{item.number}}</span></p>
          </div>
        </div>
        <div class="bodytz" @click="toxq(item)">View details</div>
      </div>
    </div>
  </div>
</template>

<script>
import { my_shive_list } from '@/api/index'
import ProGress from '@/components/Progress.vue';
export default {
  components: {
    ProGress
  },
  data () {
    return {
      list: [],
	  // pgnumber:0
    }
  },
  created () {
    this.getmy_shive_list()
  },
  methods: {
    async getmy_shive_list () {
      const res = await my_shive_list()
      console.log(res,'111')
      this.list = res.data
	  // this.pgnumber = parseInt((res.data.already/res.data.number)*100)
    },
    toxq (item) {
      this.$router.push({
        path: '/AssiStminningxq',
        query: {
          item: item
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .AssiStminning{
     background-image: linear-gradient(to bottom,#DFA3FF,#5687FD);
     min-height: 100vh;
     padding-bottom: 5rem;
     .party-title {
      padding-top: 2.5rem;
      display: flex;
      margin-bottom: 4rem;
      .title {
        color: #fff;
        font-size: 1.8rem;
        margin-left: 11rem;
        font-weight: 700;
      }
      ::v-deep .van-icon-arrow-left:before {
        margin-top: 0.4rem;
      }
    }
    .body{
      margin-top: 4rem;
      padding: 0 2rem;
      .one{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        border-radius: 1rem;
        background: #D9B7FF;
        position: relative;
        padding-bottom: 3rem;
        margin-top: 3rem;
        .bodytz{
          background: rgba(99, 0, 213, 1);
          color: #fff;
          font-weight: bold;
          font-size: 1.2rem;
          border-radius: 1rem 0rem 1rem 0rem;
          position: absolute;
          right: 0;
          bottom: 0;
          padding: .5rem 1rem;
        }
        .left{
          width: 7rem;
          height: 5rem;
        }
        .right{
          .rightyi{
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
              color: #000000;
              font-weight: bold;
            }
            .rightyip{
              font-size: 1.1rem;
              color: #8B7299;
            }
          }
          .righter{
            color: #000;
            span{
              color: #96F1FF;
            }
          }
        }
      }
    }
  }
</style>
