<template>
  <!-- 登录 -->
  <div class="login">
    <div class="logo">
      <img src="../assets/logo.png" alt="" />
    </div>
    <div class="title">AMP GPT</div>
    <div class="box">
      <div class="loginname">{{ $t("login.login") }}</div>
      <div></div>
      <!-- <div @click="switchlanguage">{{ $t("login.language") }}</div> -->
    </div>
    <van-form @submit="onSubmit" ref="from">
     <div style="position: relative;">
		 <van-field
		   v-model="username"
		   name="username"
		   type="text"
		   :placeholder="$t('login.usernameplaceholder')"
		   :rules="rules.username"
		 		style="padding-left: 6rem;"
		 />
		 			<div style="position: absolute; top: 50%;transform: translateY(-50%);left: 1.5rem;font-size: 1.3rem;" @click=changequhao()>
		 				+ {{areacode}}
		 			</div>
	 </div>
      <div class="position">
        <van-field
          v-model="password"
          name="password"
          type="password"
          :placeholder="$t('login.passwordplaceholder')"
          :rules="rules.password"/>
      </div>
      <div class="Forgot">
        <!-- <div @click="toregister">Register</div> -->
        <div></div>
        <div @click="toForgot">{{ $t("login.Forgotpassword") }}?</div>
      </div>
      <div>
        <van-button round block type="info" native-type="submit">{{
          $t("login.Determine")
        }}</van-button>
      </div>
    </van-form>
    <div class="bottombj"></div>
    <!-- 文字-->
    <div class="xiatext">
      <div class="text">{{$t('login.bottomtext')}}</div>
      <div class="texter">
        <span>{{$t('register.bottomer')}} &nbsp;</span>
        <a href="https://ampgpt.site/">{{$t('register.bottomerer')}}</a>
        <span>{{$t('register.bottomersan')}}</span>
      </div>
    </div>
	
	<van-popup v-model="show" position="bottom" :style="{ height: '30%' }" >
		
		<!-- <div v-for="(i,v) in codelist" :key="v">{{i.code}}</div> -->
		<van-picker
		  title=""
		  show-toolbar
		  :columns="columns"
		  @confirm="onConfirm"
		  @cancel="onCancel"
		  @change="onChange"
		  confirm-button-text='Confirmar'
		  cancel-button-text='Cancelar'
		/>
	</van-popup>
	
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getlogin, getservice,getquhao } from '@/api/index'

export default {
  name: 'LoGin',
  data () {
    return {
      Language: localStorage.getItem('lagn') || 'pt-br',
      username: '',
      password: '',
      rules: {},
      loading: 1,
      hrefurl: '',
      shivetype: false,
      shiveid: '',
      sn: '',
	  areacode:'52',
	  show: false,
	  columns:[]
    }
  },
  created () {
    this.geturl()
    console.log(localStorage.getItem('type'))
    if (localStorage.getItem('type') != null) {
      this.shiveid = localStorage.getItem('shiveid')
      this.sn = localStorage.getItem('sn')
      this.shivetype = true
      console.log(this.shiveid, this.sn, 'logins')
    }
  },
  mounted () {
    this.setfromrules()
  },
  watch: {
    // 监听语言切换
    '$i18n.locale' () {
      this.setfromrules()
    }
  },
  methods: {
	  onConfirm(e) {
	  				// console.log(e);
	  				this.areacode = e
	  				this.show = false
	  			},
	  			onCancel() {
	  				this.show = false
	  			},
	  			onChange() {
	  				
	  			},
	  			async changequhao() {
	  				
	  				await this.getquhao()
	  				
	  				this.show = true 
	  			},
	  async getquhao() {
	  			   this.columns=[]
	  				const res = await getquhao()		
	  					console.log(res);
	  					 res.data.forEach((i)=>{
	  						 this.columns.push(i.code)
	  					 })
	  					
	  				},
    // 判断是否是手机端，如果是，返回true
    // tochange () {
    //   this.username = this.username.replace(/[^\d]/g, '')
    // },
    // 校验规则
    setfromrules () {
      this.rules = {
        username: [
          {
            required: true,
            message: this.$t('login.rulesusername'),
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('login.rulespassword'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    async onSubmit (value) {
      if (this.loading === 1) {
        this.loading = 0
        const params = {
          account: this.username,
          password: this.password,
		  area:this.areacode
        }
        const res = await getlogin(params)
        this.loading = 1
        console.log('123', res)
        if (parseFloat(res.code) === 1) {
          localStorage.setItem('token', res.data.userinfo.token)
          localStorage.setItem('is_email', res.data.is_email)
          if (localStorage.getItem('tink_var')) {
          // console.log(111)
          } else {
            localStorage.setItem('tink_var', 'en-us')
          }
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          if (this.shivetype) {
            this.$router.push({
              path: '/AssiSter',
              query: {
                shive_id: this.shiveid,
                sn: this.sn
              }
            })
          } else {
            setTimeout(() => {
              this.$router.push('/home')
            }, 1500)
          }
        } else {
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      }
    },
    // 去忘记密码
    toForgot () {
      this.$router.push('/forgot')
      // window.location.href = this.hrefurl
    },
    // 去注册页面
    toregister () {
      this.$router.push('/register')
    },
    // 切换语言
    switchlanguage () {
      console.log(this.Language)
      if (this.Language === 'zh-cn') {
        this.Language = 'en-us'
        this.$i18n.locale = 'en-us'
        localStorage.setItem('lang', 'en-us')
        localStorage.setItem('tink_var', 'en-us')
      } else if (this.Language === 'en-us') {
        this.$i18n.locale = 'zh-cn'
        this.Language = 'zh-cn'
        localStorage.setItem('lang', 'zh-cn')
        localStorage.setItem('tink_var', 'zh-cn')
      }
    },
    // 获取绑定电报url
    async geturl () {
      const url = '/api/index/get_service'
      const res = await getservice(url)
      this.hrefurl = res.data.telegram
      console.log(res.data.telegram, 'url')
    }
  }
}
</script>

<style lang='less' scoped>
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #E5E5E5 !important;
}
.login {
  background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  background-size: cover;
  background-position: bottom;
  background-color: #fff;
  padding: 7.5rem 1.6rem;
  padding-bottom: 0rem;
  width: 100%;
  min-height: 100vh;
  // ::v-deep --van-field-placeholder-text-color{
  //   font-weight: 700;
  //   color: red;
  // }
  ::v-deep .van-field__control::-webkit-input-placeholder {
    // color: #9b9da9;
    font-weight: 700;
  }
  .logo {
    width: 13rem;
    height: 12rem;
    margin: 0 auto;
  }
  .box {
    display: flex;
    color: #fff;
    justify-content: space-between;
    font-size: 1.4rem;
    margin-top: 6.7rem;
  }
  .title {
    color: #000;
    text-align: center;
    font-weight: 800;
    font-size: 2rem;
    // margin-top: 2.1rem;
  }
  .loginname {
    color: #000;
    font-size: 1.4rem;
    font-weight: 500;

    margin-bottom: -0.5rem;
  }
  ::v-deep .van-cell {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    color: #000000;
    border-radius: 2.2rem;
    margin-top: 2rem;
    padding: 1.2rem 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.3rem;
    &:nth-child(1) {
      padding-right: 7rem;
    }
    &::after {
      border-bottom: 0px solid #000;
    }
  }
  ::v-deep .van-field__control {
    color: #000;
  }
  ::v-deep .position {
    position: relative;
  }
  // .code {
  //   color: #15b4fb;
  //   font-size: 1.4rem;
  //   position: absolute;
  //   top: 1.2rem;
  //   right: 2.2rem;
  // }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #5979FB;
    border: none;
    font-size: 1.4rem;
    margin-top: 5rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Forgot {
    color: #3997E6;
    text-align: right;
    font-weight: 700;
    margin: 1.5rem 0rem;
    // margin-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
  }

  ::v-deep input[type="password"]::-ms-reveal {
    display: none;
  }
  // .bottombj {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 25rem;
  //   background: url('../assets/dibubj.png') no-repeat;
  //   background-size: 100% 100%;
  // }
   // bottom文字
   .xiatext{
    margin: 0 1rem;
    margin-top: 5rem;
    .text{
      color: #000;
      // margin: 0.5rem 0rem;
      // margin-top: 1.5rem;
      font-size: 1.4rem;
    }
    .texter{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1rem;
    }
  }
}
</style>
