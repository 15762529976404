<template>
  <div class="Share">
    <!-- 自定义nav-->
    <div class="Phonenumber-title">
      <van-icon
          name="arrow-left"
          size="2rem"
          color="#fff"
          @click="$router.push('/Activity')" />
      <div class="title">{{$t('addtask.titleer')}}</div>
      <div></div>
    </div>
    <!-- 内容-->
    <div class="body">
      <div class="bodytitle">{{$t('addtask.shareyi')}}</div>
      <div class="bodyimg">
        <van-uploader
          v-model="fileList"
          :max-count="1"
          :after-read="afterRead"
          preview-size="15rem"
          :before-delete="afetdelete"
        />
        <!-- <div class="imgdiv">
          +
        </div>
        <img src="" style="display: none;" /> -->
      </div>
      <div class="tishi">*{{$t('addtask.shareer')}}</div>
      <div class="btn" @click="btn">{{$t('addtask.sharesan')}}</div>
    </div>
  </div>
</template>
<script>
import { Uploadpicturesapi, upshare } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'ShAre',
  data (){
    return {
      headimg: '',
      fileList: [],
      updata: {
        id: '',
        image: ''
      }
    }
  },
  created (){
    this.updata.id = this.$route.query.id
  },
  methods: {
    // 文件删除
    afetdelete (detail) {
      this.fileList = []
      this.url = ''
      // console.log('3232', this.fileList)
    },
    // 图片事件
    async afterRead (file) {
      // console.log('66666', file)
      // 文件读取完成后的回调函数
      const formData = new FormData()
      formData.append('file', file.file)
      // console.log(formData.get('file'))
      this.files = file.file
      const res = await Uploadpicturesapi(formData)
      // console.log(res)
      this.url = res.data.fullurl
      this.updata.image = res.data.url
    },
    async btn (){
      console.log(this.updata)
      if(this.updata.image == ''){
        Toast({
          message: this.$t('addtask.sharets'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      const res = await upshare(this.updata)
      if(res.code == 1){
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.$router.push('/Activity')
      }else{
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.Share{
  background: #000;
  min-height: 100vh;
  .Phonenumber-title {
    padding-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: #fff;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .body{
    color: #fff;
    margin: 0 4rem;
    .bodytitle{
      font-weight: 700;
      font-size: 1.5rem;
    }
    .bodyimg{
      width: 15rem;
      height: 15rem;
      // background: #ccc;
      margin: 0 auto;
      margin-top: 3rem;
      .imgdiv{
        width: 100%;
        height: 100%;
        border: 5px dashed #ccc;
        font-size: 8rem;
        text-align: center;
      }
      .img{
        width: 100%;
        height: 100%;
      }
    }
    .tishi{
      color: red;
      font-weight: 700;
      margin-top: 1.5rem;
    }
    .btn{
      background: #5979FB;
      color: #fff;
      font-weight: 700;
      text-align: center;
      padding: 1rem;
      border-radius: 3rem;
      margin-top: 4rem;
    }
  }
}
</style>
  