<template>
  <div class="Duration">
    <!-- 自定义nav-->
    <div class="Phonenumber-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="back" />
      <div class="title">{{$t('addmy.duryi')}}</div>
      <div></div>
    </div>
    <!-- 内容-->
    <!-- 倒计时-->
    <div class="drown">
      <div class="drowntime">
      <div>{{$t('addmy.durer')}}</div>
      <div>{{time}}</div>
      <!-- <van-count-down :time="100000">
          <template #default="timeData">
          <span class="block">{{ timeData.hours }}</span>
          <span class="block">:</span>
          <span class="block">{{ timeData.minutes }}</span>
          <span class="block">:</span>
          <span class="block">{{ timeData.seconds }}</span>
          </template>
      </van-count-down> -->
      </div>
      <div class="tishi">{{$t('addmy.dursan')}}</div>
    </div>
  </div>
</template>
<script>
import { onlinetime } from '@/api/index.js'
export default {
  name: 'DuraTion',
  data (){
    return {
      time: "00:00:00",
      flag: null,
      hour: 0,
      minute: 0,
      second: 0,
      id: '' // 判断跳转首页还是个人中心
    }
  },
  created (){
    this.getonlinetime()
    this.id = this.$route.query.id
  },
  methods: {
    async getonlinetime (){
      const res = await onlinetime()
      console.log(res)
      // 时间
      this.hour = parseInt(res.data.onlinetime/60/60)
      this.minute = parseInt(res.data.onlinetime/60)
      this.second = parseInt(res.data.onlinetime-this.minute*60)
      console.log(this.minute,this.second)
      this.start()
    },
    start() {
      this.flag = setInterval(() => {
        this.second = this.second + 1;
        if (this.second >= 60) {
          this.second = 0;
          this.minute = this.minute + 1;
        }

        if (this.minute >= 60) {
          this.minute = 0;
          this.hour = this.hour + 1;
        }
        this.time =
          this.complZero(this.hour) +
          ":" +
          this.complZero(this.minute) +
          ":" +
          this.complZero(this.second);
      }, 1000)
    },
    //补零
    complZero(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    back(){
      this.$router.push('/my')
    }
  }
}
</script>
<style lang="less" scoped>
.Duration{
  background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  background-size: cover;
  min-height: 100vh;
  .Phonenumber-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
    color: #000;
    font-size: 1.8rem;
    // margin-left: 9rem;
    font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
    margin-top: 0.4rem;
    }
  }
  // 11.15 倒计时
.drown{
  margin-top: 5rem;
  .drowntime{
    background-image: linear-gradient(to bottom,#369BE1,#1DB9AD);
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: 6.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    margin: 0 10rem;
    .block{
      color: #fff;
      font-size: 1.5rem;
    }
  }
  .tishi{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 3rem;
    color: #8E8E8E;
    font-weight: 700;
  }
}
}
</style>
  