<template>
  <!-- 团对页面 -->
  <div class="party">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="party-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"/>
      <div class="title">{{ $t("party.title") }}</div>
    </div>
    <div class="Incomebox">
      <div class="Income">
        <div class="income">
          <div>{{ $t("party.Totalnumber") }}</div>
          <div class="green">
            {{ teamobj.total_number ? teamobj.total_number : "0" }} <!-- total_number-->
          </div>
        </div>
        <div class="income">
          <div>{{ $t("party.zczrs") }}</div>
          <div class="green">
            {{ teamobj.reg_counts ? teamobj.reg_counts : "0" }} <!-- reg_counts-->
          </div>
        </div>
        <div class="income">
          <div>{{$t('addparty.incomesan')}}</div>
          <div class="green">
            {{ teamobj.reg_income ? teamobj.reg_income : "0" }}
          </div>
        </div>
      </div>
      <div class="xian"></div>
      <div class="Income">
        <div class="income">
          <div>{{ $t("party.czzrs") }}</div>
          <div class="green">
            {{ teamobj.rech_counts ? teamobj.rech_counts : "0" }}
          </div>
        </div>
        <div class="income">
          <div>{{ $t("party.zczje") }}</div>
          <div class="green">
            {{ teamobj.all_recharge ? teamobj.all_recharge : "0" }}
          </div>
        </div>
        <div class="income">
          <div>{{ $t("party.czyj") }}</div>
          <div class="green" style="color: #02D79A;">
            {{ teamobj.rech_income ? teamobj.rech_income : "0" }}
          </div>
        </div>
      </div>
      <div class="xian"></div>
      <div class="Income">
        <div class="income" style="width: 33.33%;">
          <div>{{$t('addparty.incomeyi')}}</div>
          <div class="green">
            {{ parseFloat(teamobj.commissions_available).toFixed(2) ? parseFloat(teamobj.commissions_available).toFixed(2) : "0" }}
          </div>
        </div>
		
		<div v-show="newteam.team_on==1" style="display: flex;justify-content: space-between;width: 66.66%;">
			<div class="income" style="width: 50%;">
			  <div>Nuevo miembro nivel 1</div>
			  <div class="green" style="color: #02D79A;">
			    {{ parseFloat(newteam.one_team_news) ? parseFloat(newteam.one_team_news) : "0" }}
			  </div>
			</div>
			<div class="income" style="width: 50%;">
			  <div>Nuevo miembro nivel 123</div>
			  <div class="green" style="color: #02D79A;">
			    {{ parseFloat(newteam.three_team_news) ? parseFloat(newteam.three_team_news) : "0" }}
			  </div>
			</div>
		</div>
     <!--   <div class="income">
          <div>{{$t('addparty.incomeer')}}</div>
          <div class="green">
            {{ teamobj.team_daily_income ? teamobj.team_daily_income : "0" }}
          </div>
        </div> -->
      </div>
      <!-- <div class="xian"></div>
      <div class="bottom">
        <div class="left">
          <div>{{ $t("party.Job") }}</div>
          <div class="lv">{{job_list.job_title}}</div>
        </div>
        <div class="right">
          <div>{{ $t("party.Income") }}</div>
          <div class="number">{{job_list.salary}}</div>
        </div>
        <div class="btn" @click="Receivebtn">{{ $t("party.Receive") }}</div>
      </div> -->
    </div>
    <!-- <div class="Grade">
      <div class="left" @click="toGrade(1)">
        <div class="user1">
          <img src="../assets/user1.png" alt="" />
        </div>
        <div class="number">
          {{ team_level_list[1] }}<span>

            </span>
        </div>
        <div class="one">Equipe de nível 1</div>
      </div>
      <div class="right">
        <div class="toteam" @click="toGrade(2)">
          <div class="toteam-left">
            <div class="tuo">Equipe de nível 2</div>
            <div class="number">
              {{ team_level_list[2]
              }}<span>

              </span>
            </div>
          </div>
          <div class="user2">
            <img src="../assets/user2.png" alt="" />
          </div>
        </div>
        <div class="threeteam" @click="toGrade(3)">
          <div class="threeteam-left">
            <div class="three">Equipe de nível 3</div>
            <div class="number">
              {{ team_level_list[3]
              }}<span>
                </span>
            </div>
          </div>
          <div class="user3">
            <img src="../assets/user3.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- 新增的一二三级团队-->
    <div class="levelteam">
      <!-- <div class="levelone">
        <div class="oneleft" style="margin-right: 2rem;">
          <div class="leftleft">
            <img src="../assets/levelteam1.png" />
          </div>
          <div class="leftright">
            <div>Tier 1 team</div>
            <div>{{teamz[1]}} <span>\{{teamhy[1]}}</span></div>
          </div>
        </div>
        <div class="oneleft">
          <div class="leftright" style="margin-right: 2rem;">
            <div>Tier 2 team</div>
            <div style="color: #FED88D;">{{teamz[2]}} <span>\{{teamhy[2]}}</span></div>
          </div>
          <div class="leftleft" style="margin-right: 0;">
            <img src="../assets/levelteam2.png" />
          </div>
        </div>
      </div>
      <div class="levelone" style="margin-top: 1rem;">
        <div class="levets" style="margin-right: 2rem;">
          <div class="tstitle">Team commission</div>
          <div class="tsbottom">
            <div style="margin-right: 4rem;">
              <img src="../assets/levelteam3.png" />
            </div>
            <div>{{yongjinz}}</div>
          </div>
        </div>
        <div class="oneleft">
          <div class="leftright" style="margin-right: 2rem;">
            <div>Tier 3 team</div>
            <div style="color: #CABDFE;">{{teamz[3]}} <span>\{{teamhy[3]}}</span></div>
          </div>
          <div class="leftleft" style="margin-right: 0;">
            <img src="../assets/levelteam4.png" />
          </div>
        </div>
      </div> -->
      <!-- 11.21修改-->
      <div class="xglevel">
        <div class="nav">
          <div class="navtime" @click="show = true">
            <div>{{text}}</div>
            <img src="../assets/tdxia.png"/>
          </div>
          <van-popover
            v-model="show"
            trigger="click"
            @select="onSelect"
            :actions="actions"
            placement="bottom-end"
            :offset="[150, 10]">
            <!-- <div class="items">11111</div> -->
          </van-popover>
        </div>
        <div class="lbody" style="margin-top: 0;">
          <div class="one">
            <img src="../assets/tdhg.png" />
            <div>{{$t('addparty.level')}} 1</div>
          </div>
          <div class="two">
            <div class="top">{{$t('addparty.lbodyyi')}}</div>
            <div class="top">{{teamz[1]}}/{{teamhy[1]}}</div>
          </div>
          <div class="two">
            <div class="top">{{$t('addparty.lbodyer')}}</div>
            <div class="top">{{team_recharge_money[1]}}/{{team_commission[1]}}</div>
          </div>
          <!-- <img src="../assets/tdright.png" class="rightimg" /> -->
        </div>
        <div class="lbody">
          <div class="one">
            <img src="../assets/tdhg.png" />
            <div>{{$t('addparty.level')}} 2</div>
          </div>
          <div class="two">
            <div class="top">{{$t('addparty.lbodyyi')}}</div>
            <div class="top">{{teamz[2]}}/{{teamhy[2]}}</div>
          </div>
          <div class="two">
            <div class="top">{{$t('addparty.lbodyer')}}</div>
            <div class="top">{{team_recharge_money[2]}}/{{team_commission[2]}}</div>
          </div>
          <!-- <img src="../assets/tdright.png" class="rightimg" /> -->
        </div>
        <div class="lbody">
          <div class="one">
            <img src="../assets/tdhg.png" />
            <div>{{$t('addparty.level')}} 3</div>
          </div>
          <div class="two">
            <div class="top">{{$t('addparty.lbodyyi')}}</div>
            <div class="top">{{teamz[3]}}/{{teamhy[3]}}</div>
          </div>
          <div class="two">
            <div class="top">{{$t('addparty.lbodyer')}}</div>
            <div class="top">{{team_recharge_money[3]}}/{{team_commission[3]}}</div>
          </div>
          <!-- <img src="../assets/tdright.png" class="rightimg" /> -->
        </div>
      </div>
      <div class="levebutton" @click="collect">{{$t('addparty.sqyj')}}</div>
    </div>
    <!-- /新增的一二三级团队-->
   <div class="Equipe1" @click="toGrade(1)">
    <div class="left">{{$t('addparty.levelteam')}}</div>
    <div class="right">
      <div>{{team_level_list[1]}}</div>
      <div class="img">
        <img src="../assets/RightArrow.png" alt="">
      </div>
    </div>
   </div>
   <!-- <div class="Equipe2" @click="toGrade(2)">
    <div class="left">Equipe de nível 2</div>
    <div class="right">
      <div>{{team_level_list[2]}}</div>
      <div class="img">
        <img src="../assets/RightArrow.png" alt="">
      </div>
    </div>
   </div>
   <div class="Equipe3" @click="toGrade(3)">
    <div class="left">Equipe de nível 3</div>
    <div class="right">
      <div>{{team_level_list[3]}}</div>
      <div class="img">
        <img src="../assets/RightArrow.png" alt="">
      </div>
    </div>
   </div> -->
    <!-- <div class="Referral">
      <div>{{ $t("party.Referral") }}</div>
      <div class="right">
        <div class="title">{{ invite_code }}</div>
        <div class="copyimg">
          <img src="../assets/copy.png" alt="" />
        </div>
      </div>
    </div> -->
    <!-- <div class="Share">
      <div>{{ $t("party.Share") }}</div>
      <div class="right" @click="share(2)">
        <div class="title">{{ invite_url }}</div>
        <div class="copyimg">
          <img src="../assets/copy.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="jieshao">
       <!-- <p class="tit">Recompensas de desenvolvimento da equipe Xiao-i:</p> -->
       <p style="text-align: center;">{{$t('addparty.jieshaoyi')}}</p>
       <p class="tit">{{$t('addparty.jieshaoer')}}</p>
       <p>{{$t('addparty.jieshaotitle')}}</p>
       <!-- <p class="tit">Regras específicas:</p> -->
       <p>1. {{$t('addparty.jieshaosan')}}</p>
       <p>2. {{$t('addparty.jieshaosi')}}</p>
       <p>3. {{$t('addparty.jieshaowu')}}</p>
    </div>
    </div>
    </van-pull-refresh>
    <!-- 时间选择器-->
    <!-- <van-calendar v-model="tiemshow" type="range" @confirm="onConfirm" /> -->
    <van-popup v-model="tiemshow" closeable position="bottom" :style="{ height: '30%' }" >
      <!-- 自定义图标 -->
      <van-popup
        :v-model="true"
        closeable
        close-icon="close"
        position="bottom"
        :style="{ height: '30%' }"
      />
      <div class="timebody">
        <div class="xztime">
          <p>{{$t('addparty.kstime')}}：</p><div @click="startime = true">{{timedata.begin}}</div>
        </div>
        <div class="xztime">
          <p>{{$t('addparty.jstime')}}：</p><div  @click="endtime = true">{{timedata.end}}</div>
        </div>
        <div class="btn" @click="btntime">{{$t('addparty.btnqd')}}</div>
      </div>
    </van-popup>
    <!-- 开始时间-->
    <van-popup v-model="startime" position="bottom" class="popup">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="Elegir"
        :min-date="minDate"
        :max-date="maxDate"
        :confirm-button-text="$t('addparty.qd')"
        @confirm="start_time"
        :cancel-button-text="$t('addparty.qx')"
        @cancel="startime = false"
      />
    </van-popup>
    <!-- 结束时间-->
    <van-popup v-model="endtime" position="bottom" class="popup">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="Elegir"
        :min-date="minDate"
        :max-date="maxDate"
        :confirm-button-text="$t('addparty.qd')"
        @confirm="end_time"
        :cancel-button-text="$t('addparty.qx')"
        @cancel="endtime = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getpartyapi, startCountdownapi, Receivesalaryapi, dailyTeamIncome } from '@/api/index'
// import { forEach } from 'core-js/core/array'
import { Toast } from 'vant'
export default {
  name: 'paRty',
  data () {
    return {
		newteam:{},
		isshow:'',
      invite_code: '',
      invite_url: '',
      team_level_list: 0, // 每个团队的总人数
      team_new_member_list: 0, // 团队新增的人数
      teamobj: {},
      job_list: {},
      code: 1,
      msg: '',
      isLoading: false,
      teamhy: '',
      teamz: '',
      yongjinz: '',
      show: false,
      actions: [{ text: this.$t('addparty.jintian') }, { text: this.$t('addparty.thiszhou') }, { text: this.$t('addparty.thismonth') },
                { text: this.$t('addparty.lastmonth') }, { text: this.$t('addparty.all') }, { text: this.$t('addparty.zdytime') }],
      text: this.$t('addparty.all'),
      team_recharge_money: '',
      team_commission: '',
      data:{
        month: 1,
        begin: '',
        end: ''
      },
      tiemshow: false,
      startime: false, // 开始时间
      endtime: false, // 结束时间
      currentDate: new Date(),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 10, 1),
      timedata: {
        begin: this.$t('addparty.qxz'),
        end: this.$t('addparty.qxz')
      },
	  isset:null
    }
  },
  created () {
    this.partypage()
	this.isset = setInterval(()=>{
		this.partypage()
	},2000)
    this.startCountdown()
  },
  beforeDestroy() {
	  clearInterval(this.isset)
	  this.isset = null
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 设备倒计时
    async startCountdown () {
      const res = await startCountdownapi()
      console.log(res)
      this.teamobj = res.data.team

      // this.teamobj.total_number = parseFloat(this.teamobj.total_number).toFixed(2)
      this.teamobj.daily_income = parseFloat(this.teamobj.daily_income).toFixed(2)
      this.teamobj.next_income = parseFloat(this.teamobj.next_income).toFixed(2)
    },
    // 团队页面
    async partypage () {
      const res = await getpartyapi(this.data)
      console.log('index', res)
      this.teamhy = res.data.team_valid_user
      this.teamz = res.data.team_level_list
      this.yongjinz = res.data.team_benefits
      this.invite_code = res.data.invite_code
      this.invite_url = res.data.invite_url
      this.team_level_list = res.data.team_level_list
      this.team_new_member_list = res.data.team_new_member_list
      this.job_list = res.data.job_list
      this.team_recharge_money = res.data.team_recharge_money
      this.team_commission = res.data.team_commission
	  this.isshow = res.data.team_on
	  this.newteam = res.data
    },
    // 复制粘贴板的点击事件
    share (id) {
      // console.log(id)
      if (id === 1) {
       setTimeout(()=>{
				 this.$copyText(this.invite_code).then()
				 Toast({
				   message: this.$t('party.Success'),
				   duration: 1200,
				   className: 'noticeWidth' // 所加样式名
				 })
			 },500)
      } else {
        setTimeout(()=>{
			this.$copyText(this.invite_url).then()
			Toast({
			  message: this.$t('party.Success'),
			  duration: 1200,
			  className: 'noticeWidth' // 所加样式名
			})
		},500)
      }
    },
    // 去等级排行
    toGrade (number) {
      // console.log(number)
      this.$router.push({
        path: '/grade',
        query: {
          number
        }
      })
    },
    // 提现操作
    async Receivebtn () {
      if (this.code === 1) {
        this.Receiveshow = false
        const res = await Receivesalaryapi()
        // console.log('6666', res)
        this.msg = res.msg
        this.code = res.code
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      } else {
        Toast({
          message: this.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    // 收取全部佣金
   async collect () {
      if (parseFloat(this.teamobj.commissions_available)+parseFloat(this.teamobj.team_daily_income) <= 0) { // eslint-disable-line
        Toast({
          message: this.$t('addparty.tsyi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      const url = 'api/team/dailyTeamIncome'
      const res = await dailyTeamIncome(url)
      console.log('333',res)
      if(res.code == 1){
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        // this.$router.push('/receive')
        this.partypage()
        this.startCountdown()
      }else{
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }

    },
    onSelect(e){
      console.log(e)
      this.data.begin = ''
      this.data.end = ''
      this.text = e.text
      if(this.text == this.$t('addparty.thismonth')){  // 这个月
        this.data.month = 2
        this.partypage()
      }else if(this.text == this.$t('addparty.lastmonth')){ // 上个月
        this.data.month = 3
        this.partypage()
      }else if(this.text == this.$t('addparty.thiszhou')){ // 本周
        this.data.month = 4
        this.partypage()
      }else if(this.text == this.$t('addparty.jintian')){ // 今天
        this.data.month = 5
        this.partypage()
      }else if(this.text == this.$t('addparty.all')){ // 所有
        this.data.month = 1
        this.partypage()
      }else{
        this.tiemshow = true
      }
    },
    // 时间处理
    // formatter (type, val) {
    //   if (type === 'year') {
    //     return val + '年';
    //   }
    //   if (type === 'month') {
    //     return val + '月';
    //   }
    //   if (type === 'day') {
    //     return val + '日';
    //   }
    //   return val;
    // },
    timeFormat(time){ // 时间格式的转换
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      return y + '-' + m + '-' + d
    },
    start_time (value) {
      // console.log(this.timeFormat(value))
      this.timedata.begin = this.timeFormat(value)
      this.startime = false
    },
    end_time (value) {
      console.log(this.timeFormat(value))
      this.timedata.end = this.timeFormat(value)
      this.endtime = false
    },
    btntime () {
      // console.log(this.timedata)
      // forEach(item in this.timedata)
      if(this.timedata.begin == this.$t('addparty.qxz') || this.timedata.end == this.$t('addparty.qxz')){
        Toast({
          message: this.$t('addparty.qxz'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      this.data.begin = this.timedata.begin
      this.data.end = this.timedata.end
      this.data.month = ''
      // console.log(this.data)
      this.partypage()
      this.tiemshow = false
    }
  }
}
</script>

<style lang="less" scoped>
.party {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .party-title {
     padding-top: 2.5rem;
    display: flex;
    margin-bottom: 4rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      margin-left: 11rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .Incomebox {
    width: 100%;
    // height: 20rem;
    background-image: linear-gradient(to left,#3898E5,#1CBBAA);
    border-radius: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    .Income {
      width: 100%;
      // height: 10rem;

      color: #ADD8F0;
      display: flex;
      // justify-content: space-between;
      padding: 1rem;
      // align-items: center;
      box-sizing: border-box;
      .revenue,
      .income {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 33.33%;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.2rem;
        .green {
          font-size: 1.6rem;
          color: #fff;
          font-weight: 700;
          // margin: 1rem 0rem;
          margin-top: 1.5rem;
          margin-bottom: .5rem;
          // margin-top: 1.5rem;
        }
      }
    }
    .xian {
      width: 95%;
      height: 1px;
      background-color: #E8E8E8;
      margin: 0 auto;
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: rgba(255, 255, 255, 0.4);
      // margin-top: 2rem;
      font-size: 1.2rem;
      padding: 2rem;
      .left {
        text-align: center;
        .lv {
          color: #fff;
          font-size: 1.5rem;
          margin-top: 1rem;
        }
      }
      .right {
        text-align: center;
        .number {
          color: #fff;
          font-weight: 700;
          margin-top: 1rem;
          font-size: 1.5rem;
        }
      }
      .btn {
         width: 6.85rem;
         height: 2.7rem;
         background-color: #5979FB;
         color: #fff;
         text-align: center;
         line-height: 2.7rem;
         border-radius: 1.5rem;
         margin-top: .5rem;
         font-weight: 600;
      }
    }
  }
   .Equipe1,
   .Equipe2,
   .Equipe3 {
    width: 100%;
    height: 5rem;
    background-color: #F4F6F8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem;
    border-radius: .8rem;
    color: #333;
    font-size: 1.2rem;
    margin-top: 1rem;
    .right {
      display: flex;
      color: #eb9302;
       .img {
        width: .8rem;
        height: .6rem;
        // vertical-align: middle;
        margin-top: -.1rem;
        margin-left: .5rem;
       }
    }
   }
  // .Grade {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 2rem;
  //   .left {
  //     width: 16.4rem;
  //     height: 14.55rem;
  //     border: 1px solid #262626;
  //     background-color: #1f1f1f;
  //     border-radius: 1rem;
  //     .user1 {
  //       width: 3rem;
  //       height: 3rem;
  //       margin-top: 1.5rem;
  //       margin-left: 1.9rem;
  //     }
  //     .number {
  //       font-size: 2.5rem;
  //       color: #b1e5fb;
  //       margin-top: 3.5rem;
  //       margin-left: 1.6rem;
  //       font-weight: bold;
  //       span {
  //         font-size: 1.5rem;
  //         font-weight: 500;
  //         margin-left: 0.3rem;
  //       }
  //     }
  //     .one {
  //       color: rgba(255, 255, 255, 0.6);
  //       font-size: 1.2rem;
  //       margin-left: 1.6rem;
  //     }
  //   }
  //   .right {
  //     width: 16.4rem;
  //     .toteam,
  //     .threeteam {
  //       height: 6.5rem;
  //       border: 1px solid #262626;
  //       background-color: #1f1f1f;
  //       border-radius: 1rem;
  //       color: #fff;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: center;
  //       .toteam-left,
  //       .threeteam-left {
  //         margin-left: 1.4rem;
  //         .number {
  //           text-align: center;
  //           font-size: 1.6rem;
  //           color: #fed88d;
  //           font-weight: bold;
  //           margin-top: 0.3rem;
  //           span {
  //             font-size: 1.1rem;
  //             font-weight: 500;
  //             margin-left: 0.3rem;
  //           }
  //         }
  //         .tuo,
  //         .three {
  //           color: rgba(255, 255, 255, 0.6);
  //           font-size: 1.2rem;
  //         }
  //       }
  //       .user2,
  //       .user3 {
  //         width: 3rem;
  //         height: 3rem;
  //         margin-right: 1.85rem;
  //       }
  //     }
  //     .threeteam {
  //       margin-top: 1.5rem;
  //       .threeteam-left {
  //         .number {
  //           color: #cabdfe;
  //         }
  //       }
  //     }
  //   }
  // }
  .Share,
  .Referral {
    display: flex;
    justify-content: space-between;
    background-color: #F4F6F8;
    color: #333;
    font-size: 1.4rem;
    padding: 1.5rem 1rem;
    // border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: .8rem;
    margin-top: 1rem;
    .right {
      display: flex;
      justify-content: space-between;
      // font-size: .8rem;
      text-align: right;
      .copyimg {
        width: 1.42rem;
        height: 1.53rem;
        margin-left: 0.5rem;
      }
      .title {
        width: 13.7rem;
        height: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }
  // .Referral {
  //   // border-bottom: 0px solid rgba(255, 255, 255, 0.1);
  // }
  .jieshao {
    width: 100%;
    color: #333;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    background-color: #F4F6F8;
    margin-top: 1.5rem;
    border-radius: 1rem;
    margin-bottom: 3rem;
    p {
      margin-top: 1rem;
    }
    .tit {
      font-size: 1.5rem;
    }
  }
}
/* 修改*/
.levelteam{
  color: #Fff;
  margin-top: 1rem;
  .levelone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .oneleft{
      width: 20.5rem;
      height: 8.125rem;
      background-color: #F4F6F8;
      color: #333;
      font-weight: 700;
      border-radius: 1rem;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 1rem;
      .leftleft{
        margin-right: 2rem;
      }
    }
  }
  .levets{
    width: 20.5rem;
    height: 8.125rem;
    background-color: #F4F6F8;
    color: #333;
    font-weight: 700;
    border-radius: 1rem;
    align-items: center;
    padding: 1rem;
    .tstitle{
      color: #333;
      font-size: 1.5rem;
    }
    .tsbottom{
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      font-size: 1.5rem;
      color: #FBB1B1;
    }
  }
  .levebutton{
    color: #fff;
    background-color: #5979FB;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    padding: 1rem 0;
    margin-top: 1rem;
  }
}
.oneleft img, .levets img{
  width: 3rem;
}
.leftright div:nth-child(1){
  color: #333;
  font-size: 1.5rem;
}
.leftright div:nth-child(2){
  color: #B1E5FB;
  font-weight: 700;
  font-size: 1.5rem;
  margin-left: 2rem;
}
.leftright div:nth-child(2) span{
  font-size: 1rem;
}
// 11.21 修改团队页
.xglevel{
  color: #333;
  .nav{
    .navtime{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      padding: .2rem .5rem;
      width: 12.5rem;
      border-radius: 1rem;
      border: 1px solid #E9A92E;
      img{
        width: 2rem;
        margin-left: 1.5rem;
      }
    }
    ::v-deep .van-popover__action{
      width: 100%;
    }
  }
  .lbody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.08);
    border-radius: 10px 10px 10px 10px;
    color: #333333;
    font-size: 1.2rem;
    margin: 1.5rem 0;
    padding: 1rem 1.5rem;
    padding-right: 3rem;
    .one{
      text-align: center;
      color: #3A4865;
      img{
        width: 2.5rem;
      }
    }
    .two{
      text-align: center;
    }
    .rightimg{
      width: 2rem;
    }
  }
  
}
/* 时间选择*/
.timebody{
  margin-top: 6rem;
  .xztime{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    div{
      border: 1px solid #EBDAD9;
      padding: 1rem 2rem;
      border-radius: 10px;
    }
  }
  .btn{
    color: #fff;
    background-color: #5979FB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 1rem 0;
    margin: 0 10rem;
  }
}
</style>
