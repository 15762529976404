<template>
  <div class="profit">
    <!-- 自定义nav-->
    <div class="nav">
        <div class="Phonenumber-title">
            <van-icon
                name="arrow-left"
                size="2rem"
                color="#000"
                @click="$router.push('/receive')"/>
            <div class="title">{{title}}</div>
            <div></div>
        </div>
    </div>
    <div class="title">{{$t('profit.jrsy')}}</div>
    <!-- 饼图-->
    <div class="probody">
        <div ref="chart" style="width: 100%;height: 300px;"></div>
        <div class="probodytwo">
            <div>{{profitarr.todayInvitationBonus}} {{$t('profit.zcyqjl')}}</div>
            <div>{{$t('profit.jrsy')}}</div>
        </div>
        <div style="margin-top: 5rem;">
            <div class="probcon">
                <div>{{$t('profit.jqsy')}}</div>
                <div>{{profitarr.todayMiningBonus}}</div>
            </div>
            <div class="probcon">
                <div>{{$t('profit.tdyjjl')}}</div>
                <div>{{profitarr.todayTeamBonus}}</div>
            </div>
            <div class="probcon">
                <div>{{$t('profit.yxsr')}}</div>
                <div>{{profitarr.todayAchievementBonus}}</div>
            </div>
        </div>
    </div>
    <div class="title" style="margin-top: 4rem;">{{$t('profit.lrze')}}</div>
    <!-- 总-->
    <div class="probody" style="margin-bottom: 5rem;padding-top: 3rem;">
        <!-- <div ref="chart" style="width: 100%;height: 300px;"></div> -->
        <div class="probodytwo">
            <div>{{profitarr.allInvitationBonus}} {{$t('profit.zzcyqjl')}}</div>
            <div>{{$t('profit.lrze')}}</div>
        </div>
        <div style="margin-top: 5rem;">
            <div class="probcon">
                <div>{{$t('profit.zjqsy')}}</div>
                <div>{{profitarr.allMiningBonus}}</div>
            </div>
            <div class="probcon">
                <div>{{$t('profit.ztdyjjl')}}</div>
                <div>{{profitarr.allTeamBonus}}</div>
            </div>
            <div class="probcon">
                <div>{{$t('profit.zyxsr')}}</div>
                <div>{{profitarr.allAchievementBonus}}</div>
            </div>
            <div class="probcon">
                <div>{{$t('profit.jrzlr')}}</div>
                <div>{{profitarr.todayBonus}}</div>
            </div>
            <div class="probcon">
                <div>{{$t('profit.zlr')}}</div>
                <div>{{profitarr.allBonus}}</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getMyBonus } from '@/api/index'
const echarts = require('echarts')
export default {
  data () {
    return {
      title: this.$t('addprofit.title'),
      profitarr: '',
      data_arr: ''
    }
  },
  created () {
    this.getprofit()
  },
  mounted () {
    // this.inCharts()
  },
  methods: {
    inCharts () { // echarts
      const that = this
      const myChart = echarts.init(this.$refs.chart)
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          textStyle: {
            color: '#fff'
          }
        //   backgroundColor: '#060606'
        },
        series: [{
        //   name: 'Access From',
          type: 'pie',
          radius: ['75%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: that.data_arr
        }]
      })
    },
    async getprofit () {
      const res = await getMyBonus()
      this.profitarr = res.data
      // console.log(this.profitarr)
      const dataarr = [
        { value: this.profitarr.todayInvitationBonus / this.profitarr.allInvitationBonus, name: this.$t('addprofit.datayi') },
        { value: this.profitarr.todayMiningBonus / this.profitarr.allMiningBonus, name: this.$t('addprofit.dataer') },
        { value: this.profitarr.todayTeamBonus / this.profitarr.allTeamBonus, name: this.$t('addprofit.datasan') },
        { value: this.profitarr.todayAchievementBonus / this.profitarr.allAchievementBonus, name: this.$t('addprofit.datasi') }
      ]
      this.data_arr = dataarr
      this.inCharts()
    }
  }
}
</script>

<style lang="less" scoped>
.profit{
    // background-image: url("../assets/indexback.png");
    // background-size: contain 100%;
    // background-repeat: no-repeat;
    background: #fff;
    padding-bottom: 5rem;
    .nav{
        color: #333;
        .Phonenumber-title {
            padding-top: 1.5rem;
            display: flex;
            justify-content: space-between;
            margin-bottom: 3.5rem;
            .title {
                color: #000;
                font-size: 1.8rem;
                // margin-left: 9rem;
                font-weight: 700;
            }
            ::v-deep .van-icon-arrow-left:before {
                margin-top: 0.4rem;
            }
        }
    }
    .probody{
        color: #fff;
        padding: 0 1.5rem;
        padding-bottom: 3rem;
        margin: 0 2rem;
        border-radius: 1rem;
        background-color: #31B588;
        // opacity: 0.9;
        .probodytwo{
            text-align: center;
        }
        .probcon{
            background: #268865;
            text-align: center;
            padding: 0.5rem 0;
            border-radius: 1rem;
            margin: 0 1.5rem;
            margin-top: 2rem;
        }
    }
    .title{
        color: #333;
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
    }
}
.probodytwo div:nth-child(1){
    font-size: 3rem;
    color: yellow;
    font-weight: 700;
}
.probodytwo div:nth-child(2){
    font-size: 1.5rem;
    margin-top: 0.3rem;
}
.probcon div:nth-child(1){
    color: #FFB312;
    font-size: 1.5rem;
    font-weight: 700;
}
</style>
