import { render, staticRenderFns } from "./gift.vue?vue&type=template&id=82fc5548&scoped=true&"
import script from "./gift.vue?vue&type=script&lang=js&"
export * from "./gift.vue?vue&type=script&lang=js&"
import style0 from "./gift.vue?vue&type=style&index=0&id=82fc5548&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82fc5548",
  null
  
)

export default component.exports