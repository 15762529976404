<template>
  <!-- 修改手机号 -->
  <div class="Phonenumber">
    <div class="Phonenumber-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#fff"
         @click="$router.push('/install')"
      />
      <div class="title">{{$t('Phonenumber.title')}}</div>
      <div></div>
    </div>
    <van-form @submit="onSubmit">
      <div>
        <div class="Email">{{$t('Phonenumber.phone')}}</div>
        <van-field v-model="phone" type="number" :rules="rules.phone" />
      </div>
      <div>
        <div class="Email">{{$t('Phonenumber.code')}}</div>
        <div class="position">
          <van-field v-model="code" type="number" :rules="rules.code" />
          <div class="code" @click="Changecode" ref="code">{{$t('Phonenumber.codes')}}</div>
        </div>
      </div>
      <div class="submit">
        <van-button round block type="info" native-type="submit"
          >{{$t('Phonenumber.Determine')}}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { Changenumberapi, obtaincodeapi } from '@/api/index'
export default {
  name: 'PhoneNumber',
  data () {
    return {
      timeshow: false,
      phone: '',
      code: '',
      settime: null,
      rules: {},
      codenumber: 0
    }
  },
  // created () {
  //   this.Changenumber()
  // },
  beforeDestroy () {
    clearInterval(this.settime)
  },
  mounted () {
    this.setfromrules()
  },
  watch: {
    // 监听语言切换
    '$i18n.locale' () {
      this.setfromrules()
    }
  },
  methods: {
    // 校验规则
    setfromrules () {
      this.rules = {
        phone: [
          {
            required: true,
            message: this.$t('Phonenumber.Phonerules'),
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {
            required: true,
            message: this.$t('Phonenumber.coderules'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    onSubmit (values) {
      // console.log('submit', values)
      this.Changenumber()
    },
    // 获取验证吗
    async obtaincode () {
      const datas = {
        mobile: this.phone,
        event: 'changemobile',
        area: '+55'
      }
      const res = await obtaincodeapi(datas)
      // console.log('code', res)
      this.codenumber = res.code
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    },
    // 修改手机号
    async Changenumber () {
      const data = {
        mobile: this.phone,
        captcha: this.code,
        area: '+55'
      }
      const res = await Changenumberapi(data)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (this.codenumber === 0) {
        this.timeshow = true
        let times = 60
        this.settime = setInterval(() => {
          if (times <= 1) {
            clearInterval(this.settime)
            this.$refs.code.innerHTML = this.$t('Phonenumber.codes')
            this.timeshow = false
          } else {
            times = times - 1
            this.$refs.code.innerHTML = times + 's'
          }
        }, 1000)
      }
      if (res.code === 1) {
        setTimeout(() => {
          this.$router.push('/install')
        }, 1000)
      }
    },
    Changecode () {
      if (this.timeshow) return
      if (this.phone.trim() === '') {
        Toast({
          message: this.$t('Phonenumber.Please'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      } else {
        this.obtaincode()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Phonenumber {
  background-color: #060606;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Phonenumber-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: #fff;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  ::v-deep .van-cell {
    background-color: #060606;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 1rem;
    // margin-top: 2rem;
    padding-left: 2rem;
     padding-right: 2rem;
    font-size: 1.3rem;
    &::after {
      border-bottom: 0px solid #000;
    }
    &:nth-child(1) {
       padding-right: 7rem;
    }
  }
  ::v-deep .van-field__control {
    color: #fff;
  }
  ::v-deep .position {
    position: relative;
  }
  .code {
    color: #15b4fb;
    font-size: 1.4rem;
    position: absolute;
     top: 40%;
    transform: translateY(-50%);
    right: 2.2rem;
  }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #15b4fb;
    border: none;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .submit {
    margin-top: 11rem;
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #15b4fb;
    text-align: center;
    line-height: 4.4rem;
    // margin-top: 11rem;
    border-radius: 2.75rem;
    font-size: 1.6rem;
    color: #fff;
  }
}
</style>
