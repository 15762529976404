<template>
  <!-- 签到记录 -->
  <div class="CheckinRecord">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh"
     :pulling-text="$t('addpull.pullyi')" :loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="CheckinRecord-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"
      />
      <div class="title">{{$t('CheckinRecord.title')}}</div>
      <div></div>
    </div>
    <div class="info" v-for="(i, v) in jilulist" :key="v">
      <div class="left">
        <div class="left-title">
         {{i.sign_time}}
        </div>
      </div>
      <div class="right">
       {{i.sign_value}}
      </div>
    </div>
    </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getCheckinlog } from '@/api/index'
export default {
  name: 'CheckinRecord',
  data () {
    return {
      jilulist: [],
      isLoading: false
    }
  },
  created () {
    this.Checkinlog()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 签到记录
    async Checkinlog () {
      const res = await getCheckinlog()
      this.jilulist = res.data
    }
  }

}
</script>

<style lang="less" scoped>
.CheckinRecord {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
   width: 100%;
  min-height: 100vh;
  .CheckinRecord-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 8rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
     margin-top: .4rem;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #F6F6F6;
    .left {
      color: #333;
      font-size: 1.2rem;
    }
    .right {
      color: #02D79A;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}
</style>
