var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ChangePassword"},[_c('div',{staticClass:"ChangePassword-title"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"2rem","color":"#000"},on:{"click":function($event){return _vm.$router.push('/install')}}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('ChangePassword.title')))]),_c('div')],1),_c('van-form',{ref:"form",on:{"submit":_vm.onSubmit}},[_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('ChangePassword.Old')))]),_c('van-field',{attrs:{"rules":_vm.rules.oldpassword},model:{value:(_vm.oldpassword),callback:function ($$v) {_vm.oldpassword=$$v},expression:"oldpassword"}})],1),_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('ChangePassword.New')))]),_c('van-field',{attrs:{"rules":_vm.rules.newpassword},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})],1),_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('ChangePassword.Confirm')))]),_c('van-field',{attrs:{"rules":_vm.rules.Confirmationpassword},model:{value:(_vm.Confirmationpassword),callback:function ($$v) {_vm.Confirmationpassword=$$v},expression:"Confirmationpassword"}})],1),_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('mail.mailbox')))]),_c('van-field',{attrs:{"disabled":true,"rules":[
              {
                  required: true,
                  message: this.$t('mail.srmail'),
                  trigger: ['blur', 'onSubmit']
              }
          ]},model:{value:(_vm.emil),callback:function ($$v) {_vm.emil=$$v},expression:"emil"}})],1),_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('mail.yzm')))]),_c('van-field',{attrs:{"rules":[
              {
                  required: true,
                  message: this.$t('mail.sryzm'),
                  trigger: ['blur', 'onSubmit']
              }
          ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{staticClass:"codebutton",attrs:{"disabled":_vm.codestatus,"type":"button"},on:{"click":_vm.sendcode}},[_vm._v(_vm._s(_vm.codecontent))])]},proxy:true}]),model:{value:(_vm.emilcode),callback:function ($$v) {_vm.emilcode=$$v},expression:"emilcode"}})],1),_c('div',{staticClass:"message"},[_c('p',[_vm._v("*"+_vm._s(_vm.$t('addmy.xgpassts')))])]),_c('div',{staticClass:"submit"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('ChangePassword.Determine')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }