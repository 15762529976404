<template>
  <!-- 修改邮箱  废弃页面-->
  <div class="Modifyemail">
    <div class="Modifyemail-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#fff"
        @click="$router.back()"
      />
      <div class="title">Modify email</div>
    </div>
    <van-form @submit="onSubmit">
      <div>
        <div class="Email">Email</div>
        <van-field v-model="email" :rules="rules.email" />
      </div>
      <div>
        <div class="Email">Email verification code</div>
        <div class="position">
          <van-field v-model="code" :rules="rules.code" />
          <div class="code">code</div>
        </div>
      </div>
      <div class="submit">
        <van-button round block type="info" native-type="submit"
          >Determine</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'PhoneNumber',
  data () {
    return {
      email: '',
      code: '',
      rules: {
        email: [
          {
            required: true,
            message: 'Please enter your phone number',
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {
            required: true,
            message: 'Please enter the verification code',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    onSubmit (values) {
      // console.log('submit', values)
      // 提示
      Toast.success({
        message: 'Check success',
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    }
  }
}
</script>

<style lang="less" scoped>
.Modifyemail {
  background-color: #060606;
  background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Modifyemail-title {
     padding-top: 2.5rem;
    display: flex;
    margin-bottom: 3.5rem;
    .title {
      color: #fff;
      font-size: 1.8rem;
      margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  ::v-deep .van-cell {
    background-color: #060606;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    // margin-top: 2rem;
    padding-left: 2rem;
     padding-right: 2rem;
    font-size: 1.3rem;
    &::after {
      border-bottom: 0px solid #000;
    }
    &:nth-child(1) {
       padding-right: 7rem;
    }
  }
  ::v-deep .van-field__control {
    color: #fff;
  }
  ::v-deep .position {
    position: relative;
  }
  .code {
    color: #15b4fb;
    font-size: 1.4rem;
    position: absolute;
    top: .5rem;
    right: 2.2rem;
  }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #15b4fb;
    border: none;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .submit {
    margin-top: 11rem;
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #15b4fb;
    text-align: center;
    line-height: 4.4rem;
    // margin-top: 11rem;
    border-radius: 2.75rem;
    font-size: 1.6rem;
    color: #fff;
  }
}
</style>
