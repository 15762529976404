<template>
	<!-- 所有记录 -->
	<div class="Mypurse">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('addpull.pullyi')"
			:loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
			<div style="min-height: 100vh;">
				<div class="Mypurse-title">
					<van-icon @click="$router.back()" name="arrow-left" size="2rem" color="#000" />
					<div class="title">{{$t('jjbuy.title16')}}</div>
					<div class="img">
						<img src="" alt="" />
					</div>
				</div>

				<van-list v-model="loading" :finished="finished" :finished-text="$t('Mypurse.Theres')"
					:loading-text="$t('Mypurse.Loading')" @load="onLoad">
					<div class="Content" v-for="(item, index) in list" :key="index">
						<div class="top">
							<div class="img">
								<img :src="item.image" alt="" />
								<div class="newtitle">
									<div >{{ item.name }}</div>
									<div class="green">
										{{$t('jjbuy.title2')}}：{{item.buy_time}}
									</div>
						
								</div>
							</div>
							<div class="Content-title" style="background-color: #f8cdac;" v-if="item.status==1">
								{{$t('jjbuy.title17')}}
							</div>
							<div class="Content-title" style="background-color: #afabab;" v-if="item.status==2">
								{{$t('jjbuy.title18')}}
							</div>
							<div class="Content-title" v-if="item.status==3">
								{{$t('jjbuy.title19')}}
							</div>
							<div class="Content-title" style="background-color: #acd78e;" v-if="item.status==4">
								{{$t('jjbuy.title20')}}
							</div>


						</div>

						<div class="Content-info">
							<div class="left">
								<div style="color: #41D6A5;font-weight: 600;margin-bottom: 1rem;">{{item.interest}} USDT
								</div>
								<div>{{$t('jjbuy.title4')}}</div>
							</div>
							<div class="left">
								<div style="color: #000;font-weight: 600;margin-bottom: 1rem;">{{item.days}} {{$t('jjbuy.title5')}}</div>
								<div>{{$t('jjbuy.title6')}}</div>
							</div>
							<div class="left">
								<div style="color: #000;font-weight: 600;margin-bottom: 1rem;white-space:nowrap;">{{item.price}} USDT</div>
								<div>{{$t('jjbuy.title7')}}</div>
							</div>
							<div class="left">
								<div style="color: #EE822F;font-weight: 600;margin-bottom: 1rem;">{{item.left_point}}
								</div>
								<div>{{$t('jjbuy.title8')}}</div>
							</div>
						</div>
						<div class="continfobox" v-if="item.status==1||item.status==2">
							{{$t('jjbuy.title21')}} : {{item.time}}
						</div>
						<div class="continfobox" v-else>
							{{$t('jjbuy.newtitle21')}} : {{item.time}}
						</div>
					</div>
				</van-list>



			</div>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		getmyproducts
	} from '@/api/index'
	// import { Toast } from 'vant'
	export default {
		name: 'FUNDS',
		data() {
			return {
				tabcolor: 1,
				iconshow: true,
				show: false,
				type: this.$t('Mypurse.type'),
				page: '1', // 页码
				limit: '12', // 一页请求多少条
				list: [], // 数据列表
				total: 0,
				types: -1,
				loading: false,
				finished: false,
				isLoading: false
			}
		},
		created() {
			// this.onLoad(-1)
		},
		methods: {
			onRefresh() {
				setTimeout(() => {
					this.$router.go(0)
				}, 1000)
			},
			// 接口数据
			async getmyproducts() {
				const data = {
					limit: this.limit,
					page: this.page
				}
				const res = await getmyproducts(data)
				if (res.data.last_page <= this.page) {
					this.finished = true
				}
				this.list.push(...res.data.data)
				//   this.loading = false
			},
			async onLoad() {
				this.loading = true
				await this.getmyproducts()
				this.page++
				this.loading = false
				// this.total=
				//   console.log(111)
				// 异步更新数据
			},
			// 全部切换
			// 点击遮罩层
			overlay() {
				this.iconshow = true
			},
			// 打开选项触发
			open() {
				this.tabcolor = 3
			},
			// 关闭选项触发
			close() {
				this.tabcolor = 2
			},
			onCancel() {
				this.iconshow = true
			},
		}
	}
</script>

<style lang="less" scoped>
	.Mypurse {
		background-color: #fff;
		// background-image: url('../assets/suoyou.png');
		background-size: 100% 25rem;
		background-repeat: no-repeat;
		padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		width: 100%;
		min-height: 100vh;

		.Mypurse-title {
			padding-top: 2.5rem;
			margin-bottom: 3rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				color: #000;
				font-size: 1.6rem;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
			}
		}

		.jilu {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.4rem;
			font-weight: 600;
			border-bottom: 2px solid rgba(255, 255, 255, 0.2);
			padding-bottom: 2rem;

			.box {
				color: #fff;
				width: 50%;
				text-align: center;

				&:nth-child(1) {
					// border-right: 1px solid rgba(255, 255, 255, 0.2);
				}
			}

			.boxs {
				color: #15b4fb;
			}
		}

		.fundos {
			font-size: .8rem;
			color: #fa7d1f;
			margin-top: 1rem;
			margin-bottom: -.5rem;
		}

		.content {
			width: 100%;
			color: #333;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.5rem 0rem;
			border-bottom: 1px solid #f6f6f6;
			font-size: 1.3rem;

			.left {
				.time {
					font-size: 1.1rem;
					color: #333;
					margin-top: 0.6rem;
				}
			}

			.right {
				color: #02D79A;
				font-weight: 700;
			}
		}

		.Content {
			padding: 1.6rem;
			width: 100%;
			// height: 39.1rem;
			background-color: #fff;
			// margin-top: 2.7rem;
			border-radius: 1rem;
			// padding-bottom: 1.55rem;
			margin-bottom: 2rem;
			border: 1.5px solid #D9D9D9;
			box-shadow: 0px 2px 3px rgba(70, 64, 244, 0.16);

			.top {

				display: flex;
				justify-content: space-between;
				align-items: center;

				.img {
					display: flex;
					align-items: center;

					img {
						width: 4rem;
						height: 4rem;
						// background-color: red;
						border-radius: .8rem;
						vertical-align: middle;
						margin-right: 1rem;
					}

					.newtitle {
						font-size: 1.2rem;
						font-weight: 600;

						.green {
							margin-top: .5rem;
						}
					}
				}

				.Content-title {
					background-color: #5979FB;
					border-radius: .5rem;
					padding: .8rem 1rem;
					font-size: 1.1rem;
					color: #fff;
					white-space:nowrap;

				}


			}

			.Content-info {
				width: 100%;
				border-radius: 4px 4px 4px 4px;
				// border: 1.5px solid #D9D9D9;
				display: flex;
				// align-items: center;
				margin-top: 1.5rem;
				padding: 1rem 0rem;

				.left {
					width: 25%;
					text-align: center;
					font-size: 1.2rem;
					color: #A7A7A7;
				}
			}

			.continfobox {
				width: 100%;
				height: 3.5rem;
				line-height: 3.5rem;
				background: #5979FB;
				font-size: 1.2rem;
				border-radius: .5rem;
				margin-top: 1rem;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				// padding-left: 1.6rem;
			}

			.tips {
				color: #fb8815;
				font-size: 1.1rem;
				margin-left: 1.3rem;
				margin-top: 1.5rem;
			}

			.bottom {
				display: flex;
				justify-content: space-between;
				margin: 0rem 1.3rem;
				margin-top: 1.75rem;

				.left,
				.right {
					width: 14.9rem;
					height: 4.4rem;
					background-color: #15b4fb;
					border-radius: 0.7rem;
					text-align: center;
					line-height: 4.4rem;
					color: #fff;
					font-size: 1.3rem;
					font-weight: 600;
				}

				.right {
					background-color: #fb8815;
				}
			}
		}
	}
</style>