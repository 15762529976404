<template>
  <div class="HistoricalLottery">
      <!-- 自定义nav-->
      <div class="Phonenumber-title">
          <van-icon
              name="arrow-left"
              size="2rem"
              color="#000"
              @click="$router.push('/gift')" />
          <div class="title">{{$t('addgift.htitle')}}</div>
          <div></div>
      </div>
      <!-- 内容-->
      <div class="tab">
        <van-tabs v-model="active" background="rgb(247, 247, 247)" title-inactive-color="#000000"
						title-active-color="#6076B2" color="#6076B2" @change="minutechange">
          <van-tab :title="$t('addgift.tarbaryi')">
            <div v-if="periodlist">
              <div class="historical" v-for="(item,index) in periodlist" :key="index">
                <div class="minute">
                  <div class="minone">
                    <div style="color: rgb(255, 95, 86);font-size: 16px;">{{item.period}}</div>
                    <div style="opacity: 0.5;">{{item.stime}}</div>
                  </div>
                  <div class="mintwo">
                    <div>{{item.lottery}}</div>
                    <div>{{item.NumArr[0]}}+{{item.NumArr[1]}}+{{item.NumArr[2]}}={{Number(item.sum_lottery)}}</div>
                  </div>
                  <div class="minthree">
                    <div v-for="(value,i) in item.result" :key="i">
                      <div :class="{'Big': value.type == 1,'Small': value.type == 2,'Odd': value.type == 3,'Even': value.type == 4,'Big-Odd': value.type == 5,
'Small-Odd': value.type == 6,'Big-Even': value.type == 7,'Small-Even': value.type == 8}">
                        <p :class="{'Big-Evenfff': value.type == 7}">{{value.name}}</p>
                      </div>
                    </div>
                    <!-- <div style="background-color: #03C0FF;">Even</div>
                    <div style="background-color: #000000;color: #fff;">Big-Even</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="datanull">
              <img src="../assets/datanull.png" />
              <div>{{$t('addgift.datanull')}}</div>
            </div>
          </van-tab>
          <van-tab :title="$t('addgift.tarbarer')">
            <div v-if="periodlist">
              <div class="historical" v-for="(item,index) in periodlist" :key="index">
                <div class="minute">
                  <div class="minone">
                    <div style="color: rgb(255, 95, 86);font-size: 16px;">{{item.period}}</div>
                    <div style="opacity: 0.5;">{{item.stime}}</div>
                  </div>
                  <div class="mintwo">
                    <div>{{item.lottery}}</div>
                    <div>{{item.NumArr[0]}}+{{item.NumArr[1]}}+{{item.NumArr[2]}}={{Number(item.sum_lottery)}}</div>
                  </div>
                  <div class="minthree">
                    <div v-for="(value,i) in item.result" :key="i">
                      <div :class="{'Big': value.type == 1,'Small': value.type == 2,'Odd': value.type == 3,'Even': value.type == 4,'Big-Odd': value.type == 5,
'Small-Odd': value.type == 6,'Big-Even': value.type == 7,'Small-Even': value.type == 8}">
                        <p :class="{'Big-Evenfff': value.type == 7}">{{value.name}}</p>
                      </div>
                    </div>
                    <!-- <div style="background-color: #03C0FF;">Even</div>
                    <div style="background-color: #000000;color: #fff;">Big-Even</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="datanull">
              <img src="../assets/datanull.png" />
              <div>{{$t('addgift.datanull')}}</div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
  </div>
</template>

<script>
import { period_histroy } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'HistoricalLottery',
  data() {
    return {
      periodlist: [],
      periodtotal: '',
      perioddata: { // 历史记录
        category: 1, 
        limit: 15,
        page: 1
      },
      active: 0
    }
  },
  created() {
    this.getperiod()
  },
  mounted() {
    window.addEventListener("scroll", this.giftdrown)
  },
  methods: {
    getperiod() {
      console.log(111)
      const url = '/api/Btc/period_histroy'
      period_histroy(url,this.perioddata).then(res=>{
        console.log(res);
        if( res.code == 1 ){
          this.periodtotal = res.data.total
          this.periodlist = [...this.periodlist, ...res.data.data]
          for( var i=0;i<this.periodlist.length;i++ ){
            var sum = this.periodlist[i].lottery.replace(".", "");
            var lastThree = sum.substring(sum.length - 3);
            this.periodlist[i].NumArr = lastThree.split("");
          }
          console.log(this.periodlist)
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    minutechange(e) {
      if( e == 0 ){
        this.perioddata.category = 1
        this.periodlist = []
        this.perioddata.page = 1
        this.getperiod()
      }else{
        this.perioddata.category = 2
        this.periodlist = []
        this.perioddata.page = 1
        this.getperiod()
      }
    },
    giftdrown() { // 触底刷新
      const a = document.documentElement.clientHeight
      const b = document.documentElement.scrollTop
      const c = document.documentElement.scrollHeight
      const that = this
      if(a+b >= c){
        // console.log('到底了')
        if(that.active == 0){
          if( parseInt(that.periodtotal) / parseInt(that.perioddata.page) < parseInt(that.perioddata.page)){
            Toast({
              message: this.$t('addgift.htsyi'),
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
            return false
          }
          that.perioddata.page++
          that.getperiod()
        }else{
          if( parseInt(that.periodtotal) / parseInt(that.perioddata.page) < parseInt(that.perioddata.page)){
            Toast({
              message: this.$t('addgift.htsyi'),
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
            return false
          }
          that.perioddata.page++
          that.getperiod()
        }
      }
    }
  },
  destroyed() {
    // 离开页面取消监听
    window.removeEventListener("scroll", this.giftdrown, false);
  },
}
</script>

<style lang="less" scoped>
.HistoricalLottery{
  background-color: rgb(247, 247, 247);
  // min-height: 100vh;
  .Phonenumber-title {
      padding-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.5rem;
      .title {
        color: #000;
        font-size: 1.8rem;
        // margin-left: 9rem;
        font-weight: 700;
      }
      ::v-deep .van-icon-arrow-left:before {
        margin-top: 0.3rem;
      }
  }
  .tab{
    .historical{
      margin: 2rem 1rem;
      .minute{
        padding: 1rem;
        border-radius: .3rem;
        background: #fff;
        .minone,.mintwo{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 700;
        }
        .minone{
          margin-bottom: 1rem;
        }
        .minthree{
          display: flex;
          align-items: center;
          margin-top: 1rem;
          div{
            padding: 0.3rem 0.325rem;
            border-radius: 3px;
            font-weight: 700;
            // margin-right: .5rem;
          }
        }
      }
    }
  }
}
// 下注分类
.Big{
  background-color: #FF4300 !important;
}
.Small{
  background-color: rgb(49, 207, 52) !important;
}
.Odd{
  background-color: rgb(245, 162, 103) !important;
}
.Even{
  background-color: rgb(3, 192, 255) !important;
}
.Big-Odd{
  background-color: rgb(202, 150, 199) !important;
}
.Small-Odd{
  background-color: rgb(150, 157, 68) !important;
}
.Big-Even{
  background-color: rgb(0, 0, 0) !important;
}
.Small-Even{
  background-color: rgb(180, 49, 98) !important;
  margin-right: 0;
}
.Big-Evenfff{
  color: #fff !important;
}
// 暂无数据
.datanull{
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  img{
    width: 20rem;
  }
}
</style>
