<template>
  <div class="Trendanalysis" @scroll="handleScroll($event)">
    <!-- 自定义nav-->
    <div class="Phonenumber-title">
      <van-icon
          name="arrow-left"
          size="2rem"
          color="#000"
          @click="$router.push('/TrenDanalysis')" />
      <div class="title">{{title_min}}</div>
      <div></div>
    </div>
    <!-- 顶部 -->
    <!-- 内容-->
    <div id="main" class="body">
      <div style="display: flex;justify-content: center;" v-show="loading">
				<div>{{$t('addpull.pullsan')}}</div>
			</div>
      <div class="content" v-for="(item,index) in infolist" :key="index">
        <div class="time">{{robot.createtime}}</div>
        <div class="contone">
          <img src="../assets/trendanaimg.png" style="width: 2rem" />
          <div>{{robot.title}}</div>
          <div class="contonezi">{{$t('addgift.ycboyi')}}</div>
        </div>
        <div class="conter">
          <div class="one">
            <div v-if="item.category == 1">1min</div>
            <div v-if="item.category == 2">5min</div>
            <div style="margin-left: 13rem">></div>
          </div>
          <div class="two">
            <div>{{$t('addgift.ycboer')}}</div>
            <div>{{$t('addgift.ycbosan')}}</div>
            <div>{{$t('addgift.ycbosi')}}</div>
          </div>
          <div class="three">
            <div style="margin-right: 3rem;">{{item.period}}</div>
            <div v-if="item.type == 1">Big</div>
            <div v-if="item.type == 2">Small</div>
            <div v-if="item.type == 3">Odd</div>
            <div v-if="item.type == 4">Even</div>
            <div v-if="item.type == 5">Big-Odd</div>
            <div v-if="item.type == 6">Small-Odd</div>
            <div v-if="item.type == 7">Big-Even</div>
            <div v-if="item.type == 8">Small-Even</div>
            <div class="drowntime flexright" v-if="item.status == 1">
              <van-count-down :time="(item.etime - datetime) * 1000" />
            </div>
            <div class="drowntime flexright" v-if="item.is_win == 1" style="color: red;">{{$t('addgift.ycbowu')}}</div>
            <div class="drowntime flexright" v-if="item.is_win == 0">{{$t('addgift.ycboliu')}}</div>
          </div>
          <div class="four">
            <div>
              {{$t('addgift.ycboqi')}} {{item.hit}}
            </div>
            <div class="fourtwo" v-if="item.status == 1" @click="toshow(item.period,item.type,item.category)">
              {{$t('addgift.ycboba')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- bottom-->
    <div class="bottom" ref="back">
      <div class="bott"  @click="shuaxin()">
        <img src="../assets/shuaxin.png" style="width: 3rem;" ref="shuax" alt="" />
      </div>
    </div>
    <!-- 弹出层-->
    <van-popup style="width: 90%;border-radius: .3rem;padding: 1rem;font-weight: 700;font-size: 1.3rem;" v-model="show">
      <div style="display: flex;justify-content: space-between;align-items: center;height: 3rem;border-bottom: 1px #e5e5e5 solid;width: 92%;margin-left: 4%;">
				<div style="width: 30%;"></div>
				<div>{{$t('addgift.yctcyi')}}</div>
				<!-- 叉 -->
				<div style="width: 30%;text-align: right;">
					<img src="./pic/cha.png" style="width: .8rem;" alt="" @click="show = false">
				</div>
			</div>
      <div style="height: 2rem;text-align: center;display: flex;justify-content: space-between;margin-top: 1rem;padding: 0 1.6rem;align-items: center;">
				<div>{{$t('addgift.yctcer')}}</div>
				<div>{{betpredata.period}}</div>
			</div>
			<div style="height: 2rem;text-align: center;display: flex;justify-content: space-between;margin-top: 1rem;padding: 0 1.6rem;align-items: center;">
				<div>{{$t('addgift.yctcsan')}}</div>
				<!-- <div style="color: #fcd434;">111</div> -->
        <div v-if="betpredata.type == 1" style="color: #fcd434;" >Big</div>
        <div v-if="betpredata.type == 2" style="color: #fcd434;" >Small</div>
        <div v-if="betpredata.type == 3" style="color: #fcd434;" >Odd</div>
        <div v-if="betpredata.type == 4" style="color: #fcd434;" >Even</div>
        <div v-if="betpredata.type == 5" style="color: #fcd434;" >Big-Odd</div>
        <div v-if="betpredata.type == 6" style="color: #fcd434;" >Small-Odd</div>
        <div v-if="betpredata.type == 7" style="color: #fcd434;" >Big-Even</div>
        <div v-if="betpredata.type == 8" style="color: #fcd434;" >Small-Even</div>
			</div>
			<!-- 输入框 -->
			<div style="height: 2rem;text-align: center;display: flex;justify-content: space-between;margin-top: 1rem;padding: 0 1.6rem;align-items: center;">
				<div>{{$t('addgift.yctcsi')}}</div>
				<div style="display: flex;">
					<input type="text" v-model="betpredata.amount"
						style="padding: 0 .5rem;width: 7rem;background-color: #ccc;border: 0;margin-right: -0.1rem;text-align: right;"
						name="" id="">
					<div style="background-color: #ccc;">USDT</div>
				</div>
			</div>
			<div style="height: 2rem;text-align: center;display: flex;justify-content: space-between;margin-top: 1rem;padding: 0 1.6rem;align-items: center;">
				<div>{{$t('addgift.yctcwu')}}</div>
				<div>{{betpredata.amount}} USDT</div>
			</div>
			<div style="height: .1rem;width: 100%;background-color: #ececec;margin-top: 1rem;"></div>
			<div style="display: flex;height: 3rem;">
				<div @click="show = false"
					style="width:50%;text-align: center;border-right: 1px #ececec solid;height: 100%;line-height: 3rem;">
					{{$t('addgift.yctcliu')}}
				</div>
				<div @click="queding()"
					style="width:50%;text-align: center;height: 100%;line-height: 3rem;color: #EAC83E;">
					{{$t('addgift.yctcqi')}}
				</div>
			</div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { robotInfo,betpre } from '@/api/index'
export default {
  name: 'TrenDanalyxq',
  data() {
    return {
      title_min: '5min',
      time: 60*1000,
      show: false,
      infodata: {
        limit: 5,
        page: 1,
        robot_id: '',
        last_id: 0
      },
      infolist: [],
      robot: {},
      times:1,
      loading: false,
      betpredata: {
        type: '',
        amount: '',
        category: '',
        period: ''
      },
      datetime: 0
    }
  },
  created() {
    this.infodata.robot_id = this.$route.query.robot_id
    this.title_min = this.$route.query.title
    // console.log(this.$route.query.robot_id)
    this.getrobotinfo()

    const date = (Date.parse(new Date())/1000)-14*3600 // 墨西哥时间 --- 倒计时
    this.datetime = date
    console.log(date)
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.back.scrollIntoView({behavior: 'smooth'})
    })
  },
  methods: {
    getrobotinfo() {
      const that = this
      robotInfo(that.infodata).then(res=>{
        console.log(res)
        if(res.code == 1){
          if(res.data.list != ''){
            that.infolist = res.data.list
          }
          that.robot = res.data.robot
          setTimeout(() => {
            this.gundong()
          }, 10)
        }
      })
    },
    // 滚动事件到最底部
    gundong() {
      let scrollEl = document.querySelector('.Trendanalysis')
      // console.log(scrollEl.scrollTop,scrollEl.scrollHeight);
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    handleScroll(e) {
      // console.log(e)
      if (e.srcElement.scrollTop <= 0) {
        if(!this.loading){
          this.loading = true
          // this.infodata.page += 1
          robotInfo(this.infodata).then(res=>{
            if(res.code == 1){
              this.infolist = res.data.list
              this.robot = res.data.robot
              this.loading = false
              setTimeout(() => {
                this.gundong()
              }, 10)
            }
          })
        }
      }
    },
    toshow(period,type,category) { // 打开弹窗
      this.betpredata.period = period
      this.betpredata.type = type
      this.betpredata.category = category
      this.show = true
    },
    shuaxin() {
      console.log('刷新')
      const newbox = this.$refs.shuax
      newbox.style.transition = 'all 2s linear'
      newbox.style.transform = 'rotateZ(' + 720 * this.times + 'deg  )'
      this.times++
      setTimeout(() => {
					this.infodata.page = 1
					this.infolist = []
					this.getrobotinfo()
				}, 3000)
    },
    queding() {
      if(this.betpredata.amount == ''){
        Toast({
          message: this.$t('addgift.ycts'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      console.log(this.betpredata)
      betpre(this.betpredata).then(res=>{
        if(res.code == 1){
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          this.show = false
          this.getrobotinfo()
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.Trendanalysis{
  background-color: #f6f6f6;
  overflow-y: scroll;
  height: 100vh;
  .Phonenumber-title {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.3rem;
    }
  }
  .body{
    margin-bottom: 5rem;
    margin-top: 7rem;
    padding-bottom: 10rem;
    .content{
      padding: 0 3rem;
      margin-top: 2rem;
      .time{
        text-align: center;
        color: #333;
        font-size: 1.3rem;
        font-weight: 700;
      }
      .contone{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 1.3rem;
        margin-top: 1rem;
        img{
          margin-right: 1rem;
        }
        .contonezi{
          background-color: rgb(240, 185, 11);
          color: #FFF;
          padding: .2rem .5rem;
          margin-left: 1rem;
          border-radius: .5rem;
        }
      }
      .conter{
        background-color: #fff;
        font-size: 1.3rem;
        margin-top: 1rem;
        border-radius: 1rem;
        // padding: 1rem;
        font-weight: 700;
        .one{
          display: flex;
          justify-content: end;
          align-items: center;
          padding-bottom: 1rem;
          margin: 0 1rem;
          border-bottom: 3px solid rgb(240, 185, 11);
        }
        .two{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 1rem 1rem;
        }
        .three{
          display: flex;
          align-items: center;
          margin: 0 1rem;
          .flexright{
            display: flex;
            width: 100%;
            justify-content: end;
          }
        }
        .four{
          background-color: rgb(239, 239, 239);
          color: rgb(66, 185, 131);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          margin-top: 1rem;
          .fourtwo{
            border: 2px solid rgb(240, 185, 11);
            padding: .3rem .5rem;
            color: rgb(240, 185, 11);
          }
        }
      }
    }
  }
  .bottom{
    background: #Fff;
    padding: 1rem;
    position: fixed;
    width: 100%;
    bottom: 0;
    .bott{
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
}
</style>
  