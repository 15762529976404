// 2、创建英文语言包对象
export default {
	// 登录
	login: {
		language: 'Language',
		login: 'Log In / Register',
		usernameplaceholder: 'Please enter your phone number',
		passwordplaceholder: 'Please enter your login password',
		Forgotpassword: 'Forgot your password',
		Determine: 'Access',
		rulesusername: 'Phone number cannot be empty',
		rulespassword: 'Password cannot be empty',
		bottomtext: 'Please click to Access. Agree to AMPGPT’s Terms of Use and Privacy Statement.'
	},
	// Forgot Password
	Forgot: {
		title: 'Forgot your password',
		phone: 'Phone number',
		Newpassword: 'New password',
		Confirmationpassword: 'Confirmation password',
		code: 'Verification code',
		codes: 'code',
		Determine: 'Confirm',
		rulesemail: 'Phone number cannot be empty',
		emailplaceholder: 'Please enter your email address', // 请输入邮箱
		newpassword: 'Please enter a new password',
		rulespassword: 'Please enter a password',
		torulespassword: 'The two passwords must match.',
		rulescode: 'Verification code cannot be empty.',
		Changecode: 'Please enter your phone number first.'
	},
	// 注册
	register: {
		username: 'Username',
		phone: 'Phone number',
		password: 'Password',
		code: 'Verification code',
		Invitation: 'Invitation code',
		Determine: 'Confirm',
		phonecode: 'Mobile verification code',
		rulesusername: 'Username cannot be empty',
		rulesnumber: 'Phone number cannot be empty',
		rulespassword: 'Password cannot be empty',
		rulescode: 'Verification code cannot be empty.',
		rulesInvitation: 'Invitation code cannot be empty',
		Changecode: 'Please enter your phone number first',
		codes: 'Get code',
		newnum: 'Create an account',
		bottomyi: 'Please click Confirm. Agree to AMPGPT’s Terms of Use and Privacy Statement.',
		bottomer: '© 2021-2024',
		bottomerer: 'AMPGPT.site',
		bottomersan: ', Inc. or its affiliates'
	},
	// Home
	home: {
		top: 'Welcome back',
		platform: 'The platform operates securely.',
		timeday: 'DAY',
		timehour: 'HOUR',
		timemin: 'MINUTE',
		timesec: 'SECOND',
		currencies: 'Popular currencies',
		SignIn1: 'Day 1',
		SignIn2: 'Day 2',
		SignIn3: 'Day 3',
		SignIn4: 'Day 4',
		SignIn5: 'Day 5',
		SignIn6: 'Day 6',
		SignIn7: 'Day 7',
		gift: 'Mystery gift box',
		Sign: 'Sign in',
		chick: 'Signed in today'
	},
	// Announcement
	gonggao: {
		title: 'Announcement'
	},
	// Announcement Details
	gonggaodetails: {
		title: 'Announcement Details'
	},
	// Sign In
	SignIn: {
		title: 'Sign-in Center',
		profit: 'Earn rewards',
		SignIn1: 'Day 1',
		SignIn2: 'Day 2',
		SignIn3: 'Day 3',
		SignIn4: 'Day 4',
		SignIn5: 'Day 5',
		SignIn6: 'Day 6',
		SignIn7: 'Day 7',
		gift: 'Mystery gift box',
		Sign: 'Sign in',
		chick: 'Signed in today'
	},
	// Sign-in Record
	CheckinRecord: {
		title: 'Daily Check-in Record'
	},
	// k线图
	diagram: {
		height: 'Height',
		low: 'Low',
		quantity: '24H Quantity',
		Recharge: 'Recharge',
		purchase: 'Purchase',
		min1: '1 minute',
		min5: '5 minutes',
		min30: '30 minutes',
		hour1: '1 hour',
		dayk: 'Day k',
		Weekk: 'Week k'
	},
	// Mining Machine Operation
	Receive: {
		Remaining: 'Remaining start time',
		Start: 'Start',
		Click: 'Click to view',
		Help: 'Help Center',
		Team: 'Groups',
		Totalnumber: 'Total number of people',
		Todays: "Today's earnings",
		available: 'Benefits of the next level',
		Recharge: 'Recharge',
		Withdraw: 'Withdraw',
		friends: 'Invite friends to join for rewards.',
		qd: 'Machine log', // New -- Start
		yq: 'Invite', // Invitation
		lrtj: 'Earnings Statistics', // Profit statistics
		tjsj: 'Statistical Data', // Statistical data
		xj: 'Detailed report' // Details
	},
	// Help Center
	Helpcenter: {
		title: 'Help Center'
	},
	// Help Center Details
	Helpcenterdetails: {
		title: 'Help Center Details'
	},
	// Team
	party: {
		title: 'My Team',
		Totalnumber: 'Total number of people',
		Todays: 'Registration rewards',
		available: 'Recharge rewards',
		team1: 'Level 1 team',
		team2: 'Level 2 team',
		team3: 'Level 3 team',
		Referral: 'Referral code',
		Share: 'Share link',
		Success: 'Replicate success',
		Job: 'Professional title',
		Income: 'Income',
		Receive: 'Receive',
		zczrs: 'Total number of registrations', // Total registered users
		czzrs: 'Total number of recharges', // Total recharged users
		czyj: 'Recharge commission', // Recharge commission
		zczje: 'Total recharge amount' // Total recharge amount
	},
	// Team Levels
	Grade: {
		title1: 'Level 1',
		title2: 'Level 2',
		title3: 'Level 3',
		Username: 'Username',
		Phone: 'Phone',
		Recharge: 'Recharge',
		qxz: 'Please select', // Please select
		wcz: 'Not recharged', // Not recharged
		ycz: 'Recharged', // Recharged
		cpgq: 'Product expiration', // Product expiration
		zzyx: 'Running', // Running
		title6: 'Serial number',
	},
	// Recharge Selection
	Recharge: {
		title: 'Recharge Channel',
		recharge: 'Recharge',
		amount: 'Recharge amount',
		Please: 'Please enter the recharge amount',
		confirm: 'Confirm',
		success: 'Successfully selected',
		less: 'Recharge amount cannot be less than one'
	},
	// Recharge Details
	Charge: {
		Address: 'Copy address',
		reminder: 'Recharge reminder',
		assets: 'Do not add assets to accounts other than the official account; otherwise, assets will not be recoverable',
		amount: 'The transfer amount must match the actual order amount.',
		Upload: 'Upload recharge order',
		Please: 'Please ensure the image is selected.',
		Success: 'Successfully uploaded',
		Remark: 'Note: Remember to upload the screenshot after recharging'
	},
	// Recharge Record
	Rechargerecord: {
		title: 'Recharge Record',
		Date: 'Date',
		Amount: 'Amount',
		Channel: 'Channel',
		State: 'Status',
		recharged: 'To be recharged',
		successful: 'Recharge successful',
		Notrecharged: 'Not recharged',
		shzt: 'Audit status', // Audit status
		shz: 'Under review' // Under review
	},
	// Withdrawal
	Withdraw: {
		title: 'Withdraw',
		Amount: 'Amount',
		Available: 'Available',
		All: 'All',
		address: 'ADDRESS',
		Submit: 'Submit',
		Currently: 'Currently supports two withdrawal methods',
		Bank: "Bank withdrawal hours: Withdrawals can be made at any time from Monday to Friday.",
		Arrival: "Arrival Time: Prepare according to the bank's arrival time, generally between 30 and 48 hours.",
		within: "The arrival time is within 72 hours during the tax-free subsidy period.",
		withdrawal: "The withdrawal amount cannot be less than 15.",
		receiving: 'First link the receiving account before proceeding.',
		min: 'Min.',
		max: 'Max.',
		fee: 'Fee'
	},
	// Mining Page
	Mining: {
		title: 'Business Strategy', 
		Power: 'Power',
		progress: 'In progress',
		Computer: 'Computer power',
		Purchasing: 'Purchase price',
		Daily: 'Daily income',
		Valid: 'Valid period',
		Days: 'Days',
		Recharge: 'Recharge',
		purchase: 'Buy now', // purchase
		Miner: 'Miner',
		After: 'After expiration, the miner can give you at any time.',
		Experience: 'Business Strategy',
		Price: 'Price',
		profit: 'Daily profit',
		Start: 'Start date',
		End: 'End date',
		Agree: 'Agree',
		Escrow: 'Escrow Agreement',
		Artificial: 'Artificial Intelligence Mining Solution',
		Users: 'Users can mine by purchasing mining machines or renting cloud computing power',
		Hash: 'Hash rate: 1TH/S-100TH/S ',
		Benefit: 'Benefit: Get 80% of each produced coin, which will be automatically settled in the user’s account.',
		Withdrawal: 'Withdrawal: Withdrawal anytime, 24-hour withdrawal',
		experience: '(Experience miners are for user experience only)',
		Special: 'Special Note',
		calculation: 'The calculation of these data is based on the current coin price and estimated according to the mining group’s coin data, and the system automatically converts it to USD. For specific real coin production data, please refer to the actual situation for verified coin data.',
		machine: 'Mining machine energy income and cloud computing may decrease over time, and the specific annualized income may be subject to the actual annualized income at that time.',
		mining: 'Purchase a mining machine or rent a cloud computing power plan, and the maintenance fee for the escrow of coin production represents 20% of the early dynamic adjustment support equipment cost. The expected coin amount will not be less than 70% per day. The current calculation is based on the lowest production value to calculate income. The actual annualized expectation is over 300%. The actual produced coin amount will prevail.',
		Currency: 'Currency production time T+1 (that is, within one day after the completion of nuclear delivery and custody, and deposit of pledged currency), please refer to the official actual official currency production date for details',
		Confirm: 'Confirm',
		first: 'Confirm first to accept the escrow agreement',
		purchases: 'Number of purchases',
		gmsl: 'Purchase amount',
		yjsy: 'Projected income'
	},
	// 我的页面
	my: {
		Accumulated: 'Accumulated Rewards',
		Today: "Today's Earnings ",
		total: 'Total Earnings',
		available: 'Available Balance',
		wallet: 'My Wallet',
		GPT: 'My GPT',
		center: 'Help Center',
		address: 'Wallet Address',
		envelope: 'Coupon Code',
		Video: 'Video Feature',
		Download: 'App Download',
		company: 'About the Company',
		Sign: 'Sign Out',
		Exit: 'Successfully Exited',
		mykj: 'My Mining Machine' // My mining machine
	},
	// Settings Page
	install: {
		title: 'Install',
		switch: 'Change Language',
		name: 'Modify Name',
		Password: 'Change Password',
		Phone: 'Change Phone Number',
		binding: 'App Binding'
	},
	// Upload Avatar
	Headimage: {
		Avatar: 'Upload Avatar',
		Upload: 'Upload',
		image: 'The uploaded image cannot be empty.'
	},
	// Modify Name
	ModifyName: {
		name: 'Modify Name',
		Determine: 'Confirm',
		cannot: 'Name cannot be empty'
	},
	// Change Password
	ChangePassword: {
		title: 'Change Password',
		Old: 'Old Login Password',
		New: 'New Login Password',
		Confirm: 'Confirm Password',
		Determine: 'Confirm',
		oldpassword: 'Please enter the old password',
		newpassword: 'Please enter a new password',
		Confirmationpassword: 'Please enter password',
		twopasswords: 'Both passwords must be consistent.'
	},
	// Change Phone Number
	Phonenumber: {
		title: 'Phone Number',
		phone: 'Phone Number',
		code: 'Mobile Verification Code',
		codes: 'Code',
		Determine: 'Confirm',
		Phonerules: 'Phone number cannot be empty',
		coderules: 'Verification code cannot be empty.',
		Please: 'Please first enter your phone number.'
	},
	// All Records
	Mypurse: {
		title: 'My Purse',
		All: 'All',
		type: 'Type',
		Recharge: 'Recharge',
		withdraw: 'Withdraw',
		product: 'Product Purchase',
		daily: 'Daily Income',
		Direct: 'Direct Referral Reward',
		envelope: 'Red Envelope Collection',
		fixed: 'Fixed Income',
		check: 'Sign In',
		benefits: 'Team Benefits',
		Cancel: 'Cancel',
		Theres: 'No more',
		Loading: 'Loading',
		System: 'System Adjustment'
	},
	// Mining Machine Status
	product: {
		title: 'Buy Product',
		progress: 'In Progress',
		purchases: 'Number of Purchases',
		Computer: 'Computer Power',
		price: 'Purchase Price',
		Daily: 'Daily Production Income',
		running: 'Running',
		expired: 'Expired',
		Expired: 'Expired',
		start: 'Start Time',
		end: 'End Time'
	},
	// Purchase Record
	Purchaserecord: {
		title: 'Purchase History',
		types: 'Types of',
		product: 'Product Name',
		price: 'Product Price',
		Daily: 'Daily Return',
		start: 'Start Time',
		end: 'End Time'
	},
	// Address
	Address: {
		title: 'Wallet Address',
		already: 'You have already added an address. If you need to modify it, please contact the administrator to make changes.'
	},
	// Add Address
	Addressdetails: {
		title: 'Add Address',
		Address: 'ADDRESS',
		Determine: 'Confirm',
		empty: 'Address cannot be empty.',
		Successfully: 'Successfully Added'
	},
	// Redeem Coupon
	Redemption: {
		title: 'Redemption Code',
		Please: 'Please enter the redemption code',
		hbyi: 'Number of Inputs',
		hber: 'Enter Amount',
		Determine: 'Confirm',
		Determineer: 'Confirm Collection',
		Determinesan: 'I want to send USDT',
		Exchange: 'Redemption code cannot be empty'
	},
	// Video Introduction
	Video: {
		title: 'Video Introduction'
	},
	// Company Introduction
	company: {
		title: 'About the Company'
	},
	// Activity List
	activity: {
		title: 'Activity',
		hot: 'Hot Products',
		discounted: 'Discounted Products'
	},
	// Bind Email
	mail: {
		mailbox: 'Mailbox',
		yzm: 'Code',
		srmail: 'Please enter your email address', // Please enter your email address
		sryzm: 'Enter Code', // Please enter the verification code
		sendcode: 'Send', // Send verification code
		emptyemail: 'Email cannot be empty.' // Email cannot be empty
	},
	// 利润
	profit: {
		jrsy: "Today's Earnings", // 今日收益
		zcyqjl: 'Registration Invitation Rewards', // 注册邀请奖励
		jqsy: 'Machine Earnings', // 机器收益
		tdyjjl: 'Team Commission Rewards', // 团队佣金奖励
		yxsr: 'Monthly Salary Income', // 月薪收入
		lrze: 'Total Profit', // 利润总额
		zzcyqjl: 'Total Registration Invitation Rewards', // 总注册邀请奖励
		zjqsy: 'Total Machine Earnings', // 总机器收益
		ztdyjjl: 'Total Team Commission Rewards', // 总团队佣金奖励
		zyxsr: 'Total Monthly Salary Income', // 总月薪收入
		jrzlr: "Total Profit Today", // 今日总利润
		zlr: 'Total Profit' // 总利润
	},
	// Bottom Navigation
	foot: {
		home: 'Home', // 首页
		yx: 'Share', // 运行
		jy: 'Robot', // 交易
		hd: 'Transaction', // 活动
		my: 'My' // 我的
	},
	// Recharge Step Two
	rechargettwo: {
		name: 'Name',
		bank: 'Bank',
		account: 'Account',
		qtjczjt: 'Please add a recharge screenshot',
		czje: 'Recharge Amount'
	},
	add: {
		addemail: 'Email',
		addforbottom: 'If email verification fails, click Contact',
		addforbottomer: 'Official Customer Service',
		addregtitle: 'Registration',
		addregts: 'Registration successful, redirecting'
	},
	addhome: {
		inbodyyi: 'Deposit',
		inbodyer: 'Recharge',
		inbodysan: 'Withdraw',
		inbodysi: 'Share Friends',
		inbodywu: 'Groups',
		inbodyliu: 'Reward Center',
		rw: 'Reward',
		wallet: 'My Wallet',
		walletyi: 'Total Assets',
		walleter: 'Available Balance',
		walletsan: 'Earnings',
		walletsi: 'Today\'s Earnings',
		walletwu: 'Total AMP Tokens',
		walletliu: 'Total AMP Value',
		help: 'Help Center',
		bottomamp: 'AMPGPT',
		bottom: ' helps you master the secrets of making money in the age of artificial intelligence!',
		bottomer: ' automatically collects trading signals and performs automated buying and selling. It allows you to easily earn money 24/7 and get automatic trading profits. Independent data analysis, intelligent trading, and stable profit guarantees! Join us and start your journey to financial freedom!',
		bottomtwo: 'Secure Operation',
		bottomtwoday: 'Day',
		bottomtwoer: 'Accumulated Earnings',
		hezuotitle: 'Cooperation Platform',
		hezuoyi: 'Binance',
		hezuoer: 'Kraken',
		hezuosan: 'KuCoin',
		hezuosi: 'Bitmark',
		hezuowu: 'Gate.io',
		hezuoliu: 'Coinbase',
		hezuoqi: 'Bybit',
		hezuoba: 'OKX',
		hezuojiu: 'bitget',
		hezuoshi: 'Bitfinex',
		tcyi: 'Mystery Gift',
		tcer: 'Box',
		tcsan: 'Latest Published',
		gb: 'Close'
	},
	addrech: {
		maxusdt: 'Recharge amount cannot exceed',
		tsyi: 'Recharge value cannot exceed 5,000',
		tser: 'Minimum recharge value',
		tsmessyi: 'Use USDT to recharge and enjoy additional cashback. For example, if the bonus rate is 3%, if the user recharges 100USDT using USDT, they will receive 103USDT.',
		tsmesser: 'After completing the account recharge using any payment method, users can receive rewards in AMP tokens, lottery points, cash rewards, etc. Pay attention to the company announcement for specific rewards.'
	},
	addbank: {
		banktitle: 'Recharge',
		banktitlevalue: 'Enter the recharge amount',
		tsyi: 'Recharge amount cannot be zero',
		tser: 'Overcharge value cannot be less than',
		btn: 'Submit Recharge',
		tssan: 'Please upload the image first',
		bankbtn: 'Confirm Recharge'
	},
	addwith: {
		address: 'Wallet Address',
		addresser: 'Bank Account Address',
		addresssan: 'Withdrawal Type',
		addresssi: 'Bank',
		tsyi: 'Withdrawal amount cannot be less than',
		tser: 'Withdrawal amount cannot exceed',
		tssan: 'Withdrawal amount cannot be less than',
		jiluyi: 'Withdrawal Record',
		jiluer: 'Date',
		jilusan: 'Amount',
		jilusi: 'Channel',
		jiluwu: 'Status'
	},
	address: {
		Bitcoin: 'Revert',
		message: 'For the security of your account, please reissue the withdrawal 24 hours after modifying account information.',
		messageer: 'Please join first.',
		usdtyi: 'Please enter your wallet address',
		usdter: 'Only TRC20 addresses can be added',
		usdtsan: 'Contact Email',
		usdtsi: 'Email Verification Code',
		addbankyi: 'Account Number',
		addbanker: 'Enter Account',
		addbanksan: 'Bank Name',
		addbanksi: 'Enter Bank Name',
		addbankwu: 'Please enter your name',
		addbankliu: 'Please enter your name',
		addbankqi: 'Contact Email',
		addbankba: 'Contact Email',
		addbankjiu: 'Email Verification Code',
		addbankp: 'To ensure that your withdrawal funds reach your account smoothly, please link your real name and bank account. Otherwise, you may not receive the withdrawal.',
		tsyi: 'Bank account cannot be empty.',
		tser: 'Number cannot be empty',
		tssan: 'Email cannot be empty.',
		tssi: 'Verification code cannot be empty.',
		tswu: 'Name cannot be empty',
		tsliu: 'Email format is incorrect.'
	},
	addtask: {
		title: 'Reward Center',
		conright: 'Join',
		conrighter: 'Now',
		tsyi: 'Received',
		tser: 'Discarded',
		titleer: 'Share',
		shareyi: 'Upload and Send Photo',
		shareer: 'Please upload the sharing certificate',
		sharesan: 'Submit',
		sharets: 'The image has not been uploaded yet.'
	},
	addturn: {
		start: 'Remaining Time to End',
		problenyi: 'This round of the lottery is not open yet, so stay tuned.',
		problener: 'The spinning lottery will first consume points and automatically switch to balance when points are insufficient.',
		problensan: 'Consume ',
		newproblensan:' points to participate in the lottery.',
		problensi: 'Consume ',
		newproblensi:'balance to participate in the lottery.',
		topboxyi: 'Remaining Points',
		topboxer: 'Available Balance',
		topboxsan: 'Accumulated Points',
		btn: 'Lottery Records',
		tsyi: 'If points are less than ',
		newtsyi:' , you have been switched to balance to participate in the lottery',
		tser: 'Balance less than ',
		newtser:' cannot participate',
		tssan: 'Those with less than ',
		newtssan:'points cannot participate',
		tssi: 'Balance less than ',
		newtssi:'cannot participate',
		tswu: 'The lottery has not started yet, so stay tuned.',
		tsliu: 'You have not purchased a robot and cannot attend the event',
		tsqi: 'The lottery has not started yet, so stay tuned.',
		title: 'Spinning Disk',
		Bitcoin: 'Point Consumption',
		Bitcoiner: 'Paste'
	},
	addrece: {
		title: 'Groups',
		tcyi: 'Copy Link',
		tcer: 'Share to',
		tsyi: 'Please recharge and activate your account first'
	},
	addparty: {
		incomeyi: 'Refund Not Received',
		incomeer: 'Daily Income',
		incomesan: 'Promotional Rewards',
		lbodyyi: 'Total / Valid',
		lbodyer: 'Recharge/Commission',
		level: 'Level',
		sqyj: 'Collect Commission',
		levelteam: 'Level Team',
		jieshaotitle: 'Specific Rules:',
		jieshaoyi: 'Invitation Rules',
		jieshaoer: 'Invite more friends to join your AMP team. You can get more invitation rewards and team development bonuses.',
		jieshaosan: 'Invite each friend to get the AMPGPT robot and you will receive an invitation reward of 3USDT. The more friends you invite, the more rewards you will receive, with no upper limit!',
		jieshaosi: 'You can get permanent team commission. Get the recharge amount from level 1 members (4%), recharge amount from level 2 members (2%), and recharge amount from level 3 members (0.5%).',
		jieshaowu: 'When the members of your level 123 team reach different numbers, you can receive bonuses and company rewards at different stages each week and each month.',
		jieshaoliu: 'When the active users of your level 123 team reach different numbers, you can receive bonuses and rewards at different stages, such as weekly and monthly.',
		thiszhou: 'This Week',
		thismonth: 'This Month',
		lastmonth: 'Last Month',
		all: 'All',
		zdytime: 'Customize',
		tsyi: 'Currently, there are no commissions available for income',
		gradeyi: 'Level 1 Current Number of Active Members',
		gradeer: 'Level 2 Current Number of Active Members',
		gradesan: 'Level 3 Current Number of Active Members',
		gradetitle: 'Team Level',
		jintian: 'Today',
		kstime: 'Start Time',
		jstime: 'End Time',
		btnqd: 'Confirm',
		qd: 'Confirm',
		qx: 'Cancel',
		qxz: 'Please Select'
	},
	addpull: {
		pullyi: 'Pull down to refresh',
		puller: 'Release to update',
		pullsan: 'Loading...'
	},
	addprofit: {
		title: 'Profit',
		datayi: 'Registration Reward Ratio',
		dataer: 'Machine Income Ratio',
		datasan: 'Team Commission Ratio',
		datasi: 'Monthly Salary Income Ratio'
	},
	addmin: {
		content: 'Return Rate',
		tcyi: 'Calculate',
		tsyi: 'You currently do not have the number of machines purchased',
		tser: 'No more quantity available',
		tssan: 'You currently do not have the number of machines purchased',
		tssi: 'Purchase amount cannot be zero.'
	},
	addgift: {
		nav: 'BTC/USDT',
		normal: 'Prediction',
		navrightyi: 'History',
		navrighter: 'Rule',
		qhyi: 'Normal Mode',
		qher: 'Stable Mode',
		msgyi: 'High',
		msger: 'Low',
		msgsan: '24H Volume',
		fxk: 'Financial Privacy Policy',
		bonex: 'Ticket',
		btwoyi: 'Bet Amount:',
		datanull: 'Currently, no data available',
		tcyi: 'Amount',
		tcer: 'Expected to be available after profitability.',
		tcsan: 'Processing Fee:',
		tcsi: 'Amount in USDT',
		tcsitwo: 'Available Balance:',
		tcwu: 'Submit',
		tarbaryi: '1 Minute',
		tarbarer: '5 Minutes',
		tarbarsan: 'Trade',
		tabarsi: 'Historical Transactions',
		tabarwu: 'Pending Transaction',
		tabarliu: 'Transaction Record',
		tsyi: 'Please review the privacy policy first.',
		tser: 'Amount cannot be empty or zero',
		tssan: 'Cannot be less than the minimum of',
		htitle: 'Winning Numbers History',
		htsyi: 'What’s Going On',
		rtitle: 'Rule',
		Ttitle: 'Trend Analysis',
		tbodyyi: 'Prediction 28',
		tbodyer: '[Signal]',
		ycboyi: 'Planner',
		ycboer: 'Ticket',
		ycbosan: 'Predict',
		ycbosi: 'Result',
		ycbowu: 'Win',
		ycboliu: 'Lose',
		ycboki: 'Number of Buyers:',
		ycboba: 'Quick Selection',
		yctcyi: 'Confirmation Order',
		yctcer: 'Ticket',
		yctcsan: 'Bet Options',
		yctcsi: 'Single Bet Amount',
		yctcwu: 'Total Order',
		yctcliu: 'Cancel',
		yctcqi: 'Confirm',
		ycts: 'Entered amount cannot be empty'
	},
	addmy: {
		mywyi: 'Total Assets:',
		mywer: "Today's Earnings: ",
		mywsan: 'Total Income:',
		mywsi: 'Rewards:',
		mywwu: 'Available Balance:',
		mywliu: 'Recharge',
		mywqi: 'Withdraw',
		myba: 'Duration',
		sboyi: 'Mystery Gift',
		sboer: 'Box',
		iboyi: 'WhatsApp',
		iboer: 'Telegram',
		ibosan: 'Email',
		xgpassts: 'After changing the password, it takes 24 hours to initiate withdrawal',
		appyi: 'Link Account',
		apper: 'Successfully linked WhatsApp',
		tele: 'Successfully linked Telegram',
		yx: 'Successfully linked your email address',
		appsan: 'Account:',
		appsi: 'Revert',
		appts: 'You have already added an account; if you need to modify it, contact the administrator for operation.',
		yxts: 'After clicking submit, don’t forget to check your spam folder. If you still don’t receive the verification code, try again later.',
		mywts: 'Invoice Record Details for This Week',
		codeyi: 'USDT Exchange Rewards',
		codeer: 'AMP Redemption Rewards',
		duryi: 'Duration',
		durer: 'Online Duration',
		dursan: 'Online duration is 30 minutes.',
		mailts: 'Only lowercase letters can be entered' // Only lowercase letters are allowed
	},
	evaluate: {
		one: 'User Reviews',
		tabone: 'Latest Version',
		tabtwo: 'Want to Post',
		input: 'Please ensure to review the content of the review!',
		imgone: 'Review Content',
		imgtwo: 'Upload Images',
		kong: 'The uploaded content cannot be empty'
	},
	hongbao: {
		listtitle: 'Send Record',
		listyi: 'All',
		lister: 'Received',
		listsan: 'Sent',
		tsyi: 'The red envelope and amount cannot be empty',
		daodil: 'It’s the end'
	},
	mlog: {
		title: 'Machine Log',
		listtime: 'Start Time'
	},
	newkx: {
		title: 'Accumulated order amount',
		title1: 'Get commission amount',
	},
	jjbuy: {
	    title: 'Wealth Appreciation',
	    title1: 'History',
	    title2: 'Purchase Limit',
	    title3: 'Buy',
	    title4: 'Daily income',
	    title5: 'Day',
	    title6: 'The term',
	    title7: 'Subscription amount',
	    title8: 'Remaining quantity',
	    title9: 'Distance ends',
	    title10: 'Available balance',
	    title11: 'Recharge',
	    title12: 'Balance refund',
	    title13: 'Are you sure you want to buy the modified product?',
	    title14: 'Confirm',
	    title15: 'Cancel',
	    title16: 'Purchase history',
	    title17: 'Pending review',
	    title18: 'Did not pass',
	    title19: 'Income',
	    title20: 'Completed',
	    title21: 'Application Period',
		newtitle21: 'Passing time',
		title22: 'If the balance is insufficient, please complete the recharge first.',
	},
	newhome: {
		title:'The activity has ended',
		title1:'The event has not started.',
		title2:'Please wait for the countdown to end'
	}

}