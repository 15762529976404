<template>
  <!-- 地址 -->
  <div class="Address">
    <div class="Address-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#000"
      />
      <div class="title">{{ $t("Address.title") }}</div>
      <div class="img" @click="torecord">
        <img src="../assets/jia.png" alt="" />
      </div>
    </div>
    <van-tabs
      v-model="active"
      color="#15B4FB"
      title-active-color="#15B4FB"
      title-inactive-color="#333"
      background="#fff"
      @click='changeactive'
    >
      <van-tab title="Bank">
        <div v-if="bank">
          <div class="Bitcoin" >
            <div class="Bitcoin-left">
              <div class="Bitcoin-img">
                <img src="../assets/bank.png" alt="" />
              </div>
              <div class="Bitcoin-name">
                {{ bank }}
              </div>
              <div style="color: #02D79A" @click="bankRever">{{$t('address.Bitcoin')}}</div>
            </div>
          </div>
          <div class="message" style="margin-top: 5rem;">
            <p>{{$t('address.message')}}</p>
          </div>
        </div>
        <div class="message" v-else>
          <p>{{$t('address.messageer')}}</p>
        </div>
      </van-tab>
      <van-tab title="Wallet">
        <div v-if="address">
          <div class="Bitcoin">
            <div class="Bitcoin-left">
              <div class="Bitcoin-img">
                <img src="../assets/address.png" alt="" />
              </div>
              <div class="Bitcoin-name">
                {{ address }}
              </div>
              <div style="color: #02D79A" @click="CarteiraRever">Rever</div>
            </div>
          </div>
          <div class="message" style="margin-top: 5rem;">
            <p>{{$t('address.message')}}</p>
          </div>
        </div>
        <div class="message" v-else>
          <p>{{$t('address.messageer')}}</p>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { withdrawalapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      active: 0,
      address: '',
      // getnew: false,
      bank: '',
      revise: '', // 修改地址
      emali: ''
    }
  },
  created () {
    // this.emali = this.$route.query.emali
    if (localStorage.getItem('is_email') == 0) { // eslint-disable-line
      console.log(111)
      this.$router.push('/MailBox')
    } else {
      this.withdrawal()
    }
  },
  methods: {
    // 去添加地址
    torecord () {
      console.log(this.emali)
      if (parseFloat(this.active) === 1) {
        // 新增钱包
        if (!this.address) {
          this.$router.push({
            path: '/addressdetails',
            query: {
              id: this.active,
              emali: this.emali
            }
          })
        } else {
          Toast({
            message: this.$t('Address.already'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      } else {
        // 新增银行账户
        if (!this.bank) {
          this.$router.push({
            path: '/addressdetails',
            query: {
              id: this.active,
              emali: this.emali
            }
          })
        } else {
          Toast({
            message: this.$t('Address.already'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      }
    },
    // 修改地址
    async addRevise () {
      const res = await withdrawalapi()
      this.revise = res.data.unlock
    },
    // 获取地址
    async withdrawal () {
      const res = await withdrawalapi()
      console.log(res)
      this.address = res.data.wallet_address
      this.getnew = true
      this.emali = res.data.email
      this.bank = res.data.bank_account
    },
    // 钱包修改
    async CarteiraRever () {
      await this.addRevise()
      if (this.address) {
        if (parseFloat(this.re) === 2) {
          Toast({
            message: this.$t('Address.already'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        } else {
          this.$router.push({
            path: '/addressdetails',
            query: {
              id: 1,
              type: 1
            }
          })
        }
      }
    },
    // 银行账户修改
    async bankRever () {
      await this.addRevise()
      if (this.bank) {
        if (parseFloat(this.re) === 2) {
          Toast({
            message: this.$t('Address.already'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        } else {
          this.$router.push({
            path: '/addressdetails',
            query: {
              id: 0,
              type: 1
            }
          })
        }
      }
    },
    // 切换
    changeactive (name, title) {
      // console.log(name)
      // console.log(title)
      this.active = name
      // console.log(this.active)
    }
  }
}
</script>

<style lang="less" scoped>
.Address {
  background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  background-size: cover;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;

  .Address-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  ::v-deep .van-tab__text--ellipsis  {
   font-size: 1.4rem;
  }
  .Bitcoin {
    width: 100%;
    height: 5.8rem;
    background-color: #fff;
    border-radius: 1rem;
    margin-top: 1.5rem;
    // border: 2px solid #f6f6f6;
    background-color: #F6F6F6;
    color: #333;
    font-size: 1.2rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.5rem;
    margin-top: 2.5rem;
    .Bitcoin-left {
      display: flex;
      align-items: center;
      .Bitcoin-img {
        width: 2.84rem;
        height: 2.84rem;
        margin-right: 1rem;
        // margin-top: 0.5rem;
      }
      .Bitcoin-name {
        width: 24rem;
        margin-top: 0.3rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }
}
.message{
  margin-top: 1rem;
  color: red;
  font-weight: 700;
}
</style>
