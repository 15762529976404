<template>
	<!-- 注册页面-->
	<div class="forgot">
		<!-- <van-icon
      name="arrow-left"
      size="2rem"
      color="#000"
      @click="$router.push('/login')"
    /> -->
		<!-- <div class="title">{{$t('add.addregtitle')}}</div> -->
		<div class="logo">
			<img src="../assets/logo.png" alt="" />
		</div>
		<div class="title">AMP GPT</div>
		<div class="newnum">{{$t('register.newnum')}}</div>
		<van-form @submit="onSubmit" style="width: 25rem;margin: 0 auto;">
			<div>
				<div class="Email">{{$t('register.username')}}</div>
				<van-field v-model="username" name="username"
					onkeyup="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
					onpaste="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
					oncontextmenu="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')" :rules="rules.username" />
			</div>
			<div class="dingwei">
				<div class="Email">{{$t('register.phone')}}</div>
				<van-field v-model="number" type="number" name="number" :rules="rules.number" />
				<div class="quhao" @click=changequhao()>+{{areacode}}</div>
			</div>
			<div>
				<div class="Email">{{$t('register.password')}}</div>
				<van-field v-model="password" name="password" :rules="rules.password" />
			</div>
			<div>
				<div class="Email">{{$t('register.phonecode')}}</div>
				<div class="position codeinput">
					<van-field v-model="code" type="number" name="code" :rules="rules.code" />
					<div class="code" @click="Changecode" ref="code">{{$t('register.codes')}}</div>
				</div>
			</div>
			<!-- <div>
        <div class="Email">{{$t('register.code')}}</div>
        <van-field v-model="Invitation" name="Invitation" :disabled="true" />
      </div> -->
			<div class="submit">
				<van-button round block type="info" native-type="submit">{{$t('register.Determine')}}</van-button>
			</div>
		</van-form>
		<!-- 文字-->
		<div class="xiatext">
			<div class="text">{{$t('register.bottomyi')}}</div>
			<div class="texter">
				<span>{{$t('register.bottomer')}} &nbsp;</span>
				<a href="https://ampgpt.site/">{{$t('register.bottomerer')}}</a>
				<span>{{$t('register.bottomersan')}}</span>
			</div>
		</div>
		<!-- 底部色块-->
		<!--
    <div class="regbottom">
      <img src="../assets/regbottom.png" />
    </div> -->

		<van-popup v-model="show" position="bottom" :style="{ height: '30%' }" >
			
			<!-- <div v-for="(i,v) in codelist" :key="v">{{i.code}}</div> -->
			<van-picker
			  title=""
			  show-toolbar
			  :columns="columns"
			  @confirm="onConfirm"
			  @cancel="onCancel"
			  @change="onChange"
			  confirm-button-text='Confirmar'
			  cancel-button-text='Cancelar'
			/>
		</van-popup>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant'
	import {
		obtaincodeapi,
		registerapi,
		getlogin,
		getquhao
	} from '@/api/index'
	const phonezz = '^(00){0,1}(47){1}{6,12}$'
	export default {
		name: 'ForGot',
		data() {
			return {
				username: '',
				number: '',
				password: '',
				code: '',
				Invitation: '',
				settime: null,
				timeshow: false,
				codenumber: 0,
				rules: {},
				areacode: '52',
				show: false,
				columns:[]
			}
		},
		beforeDestroy() {
			clearInterval(this.settime)
		},
		created() {
			// const name = this.$route.query.invite_code
			// console.log(name)
			this.huoquInvitation()
		},
		mounted() {
			this.setfromrules()
		},
		watch: {
			// 监听语言切换
			'$i18n.locale'() {
				this.setfromrules()
			}
		},
		methods: {
			onConfirm(e) {
				// console.log(e);
				this.areacode = e
				this.show = false
			},
			onCancel() {
				this.show = false
			},
			onChange() {
				
			},
			async changequhao() {
				
				await this.getquhao()
				
				this.show = true 
			},
		   async getquhao() {
			   this.columns=[]
				const res = await getquhao()		
					console.log(res);
					 res.data.forEach((i)=>{
						 this.columns.push(i.code)
					 })
					
				},
			// 校验规则
			setfromrules() {
				this.rules = {
					username: [{
						required: true,
						message: this.$t('register.rulesusername'),
						trigger: ['blur', 'change']
					}],
					number: [{
							required: true,
							message: this.$t('register.rulesnumber'),
							trigger: ['blur', 'change']
						},
						{
							pattren: phonezz,
							message: 'Please enter the correct phone number'
						}
					],
					password: [{
						required: true,
						message: this.$t('register.rulespassword'),
						trigger: ['blur', 'change']
					}],
					code: [{
						required: true,
						message: this.$t('register.rulescode'),
						trigger: ['blur', 'change']
					}]
					// Invitation: [
					//   {
					//     required: true,
					//     message: this.$t('register.rulesInvitation'),
					//     trigger: ['blur', 'change']
					//   }
					// ]
				}
			},
			// 有邀请码直接赋值没有为空
			huoquInvitation() {
				this.Invitation = this.$route.query.invite_code || ''
			},
			async onSubmit() {
				// console.log('submit', values)
				const data = {
					username: this.username,
					password: this.password,
					mobile: this.number,
					code: this.code,
					invite_code: this.Invitation,
					area:this.areacode
				}
				const res = await registerapi(data)
				// console.log(res)
				// return false
				Toast({
					message: res.msg,
					duration: 1200,
					className: 'noticeWidth' // 所加样式名
				})

				if (res.code === 1) {
					// setTimeout(() => { // 注册成功跳转到登录页面
					//   this.$router.push('/login')
					// }, 2000)

					// 注册成功直接跳转首页
					const logindata = {
						account: this.number,
						password: this.password,
						area:this.areacode
					}
					const loginres = await getlogin(logindata)
					if (loginres.code == 1) {
						localStorage.setItem('token', loginres.data.userinfo.token)
						localStorage.setItem('is_email', loginres.data.is_email)
						if (localStorage.getItem('tink_var')) {
							// console.log(111)
						} else {
							localStorage.setItem('tink_var', 'en-us')
						}
						Toast({
							message: this.$t('add.addregts'),
							duration: 1200,
							className: 'noticeWidth' // 所加样式名
						})
						setTimeout(() => {
							this.$router.push('/home')
						}, 1500)
					} else {
						Toast({
							message: res.msg,
							duration: 1200,
							className: 'noticeWidth' // 所加样式名
						})
					}
				}
			},
			// 获取验证吗
			async obtaincode() {
				const datas = {
					mobile: this.number,
					event: 'register',
					area: '+'+ this.areacode
				}
				const res = await obtaincodeapi(datas)
				// console.log('code', res)
				this.codenumber = res.code
				Toast({
					message: res.msg,
					duration: 1200,
					className: 'noticeWidth' // 所加样式名
				})
				if (this.codenumber === 1) {
					this.timeshow = true
					let times = 180
					this.settime = setInterval(() => {
						if (times <= 1) {
							clearInterval(this.settime)
							this.$refs.code.innerHTML = 'code'
							this.timeshow = false
						} else {
							times = times - 1
							this.$refs.code.innerHTML = times + 's'
						}
					}, 1000)
				}
			},
			Changecode() {
				if (this.timeshow) return
				if (this.number.trim() === '') {
					Toast({
						message: this.$t('register.Changecode'),
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
				} else {
					this.obtaincode()
				}
			}
		}
	}
</script>

<style lang='less' scoped>
	.forgot {
		background: url('../assets/ampbk.png') no-repeat;
		// background-size: 100% 25rem;
		background-size: cover;
		// padding: 0rem 1.6rem;
		padding-top: 2.5rem;
		padding-bottom: 15rem;
		// background-color: #fff;
		width: 100%;
		min-height: 100vh;

		.logo {
			width: 13rem;
			height: 12rem;
			margin: 0 auto;
		}

		.title {
			padding-top: 1.5rem;
			color: #000;
			font-size: 1.8rem;
			text-align: center;
			font-weight: 700;
		}

		.newnum {
			display: flex;
			justify-content: center;
			font-weight: 700;
			font-size: 1.8rem;
			margin-top: 2rem;
			margin-bottom: 3rem;
			// width: 3rem;
		}

		::v-deep .van-cell {
			background-color: #fff;
			border: 1px solid #E5E5E5;
			border-radius: 2.2rem;
			// margin: 0 auto;
			margin-top: .5rem;
			padding: 1rem 0rem;
			padding-left: 2rem;
			padding-right: 2rem;
			font-size: 1.3rem;

			// width: 25rem;
			&:nth-child(1) {
				padding-right: 7rem;
			}

			&::after {
				border-bottom: 0px solid #000;
			}
		}

		::v-deep .van-field__control {
			color: #000;
		}

		::v-deep .position {
			position: relative;
		}

		.code {
			color: #15b4fb;
			font-size: 1.4rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 2.2rem;
		}

		::v-deep .van-button {
			border-radius: 2.2rem;
			height: 4.4rem;
		}

		::v-deep .van-button--info {
			background-color: #5979FB;
			margin-top: 5rem;
			border: none;
			font-size: 1.4rem;
		}

		::v-deep .van-field__error-message {
			font-size: 1.4rem;
		}

		.Email {
			color: #000;
			margin: .5rem 0rem;
			margin-top: 1.5rem;
			font-size: 1.4rem;
		}

		.dingwei {
			position: relative;

			::v-deep .van-cell {
				padding-left: 6rem !important;
			}
		}

		.quhao {
			width: 5rem;
			height: 4.4rem;
			position: absolute;
			color: #3997E6;
			top: 53%;
			font-size: 1.5rem;
			left: 2rem;
		}

		.submit {
			margin-top: 4rem;
		}

		.regbottom {
			position: fixed;
			width: 100%;
			bottom: 0;
		}

		.codeinput {
			::v-deep .van-field__control {
				width: 60%;
			}
		}

		// bottom文字
		.xiatext {
			margin: 0 6rem;
			margin-top: 5rem;

			.text {
				color: #000;
				// margin: 0.5rem 0rem;
				// margin-top: 1.5rem;
				font-size: 1.4rem;
			}

			.texter {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 1.5rem;
				font-size: 1rem;
			}
		}
	}
	
	::v-deep .van-popup {
		 text-align: center;
	
	  
	}
</style>