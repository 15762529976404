<template>
  <!-- 充值记录 -->
  <div class="record">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
        <div class="record-title">
          <van-icon
            name="arrow-left"
            size="2rem"
            color="#000"
            @click="$router.back()"/>
          <div class="title">{{$t("Rechargerecord.title")}}</div>
          <div></div>
        </div>
        <div class="top">
          <div>{{$t("Rechargerecord.Date")}}</div>
          <div>{{$t("Rechargerecord.Amount")}}</div>
          <div>{{$t("Rechargerecord.Channel")}}</div>
          <!-- <div>{{$t("Rechargerecord.State")}}</div> -->
          <div>{{$t("Rechargerecord.shzt")}}</div>
        </div>
        <!-- 分页 -->
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('Mypurse.Theres')"
          :loading-text="$t('Mypurse.Loading')"
          @load="onLoad">
          <div class="bottom" v-for="(i, v) in list" :key="v">
          <div>{{ i.createtime }}</div>
          <div class="green">{{ i.amount ? i.amount : '0'}}</div>
          <div>{{ i.way }}</div>
          <div v-if="i.status === '0'">{{$t("Rechargerecord.recharged")}}</div>
          <div v-else-if="i.status === '1'">{{$t("Rechargerecord.successful")}}</div>
          <div v-else-if="i.status === '2'">{{$t("Rechargerecord.Notrecharged")}}</div>
          <div v-else-if="i.status === '3'">{{$t("Rechargerecord.shz")}}</div>
        </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { RechargeRecordapi } from '@/api/index'
export default {
  name: 'reCord',
  data () {
    return {
      list: [],
      isLoading: false,
      loading: false,
      finished: false,
      total: 0,
      page: '1', // 页码
      limit: '12' // 一页请求多少条
    }
  },
  created () {
    // this.onLoad()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    async RechargeRecord () {
      const data = {
        limit: this.limit,
        page: this.page
      }
      const res = await RechargeRecordapi(data)
      console.log(res, 'record')
      if (res.data.last_page <= this.page) {
        this.finished = true
      }
      this.list.push(...res.data.data)

      this.list.forEach((i) => {
        if (i.amount === null || parseFloat(i.amount) === 0) {
          i.amount = 0
        } else {
          i.amount = parseFloat(i.amount).toFixed(2)
        }
      })
    },
    async onLoad () {
      this.loading = true
      await this.RechargeRecord()
      this.page++
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.record {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .record-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .top,
  .bottom {
    color: #333333;
    display: flex;
    justify-content: center;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 1.2rem;
    div {
      width: 25%;
      text-align: center;
    }
  }
  .bottom {
    font-size: 1.3rem;
    border-bottom: 0px solid rgba(255, 255, 255, 0.1);
    color: #333333
  }
 .green {
      color: #15FBB5;
    }
}
</style>
