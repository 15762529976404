import store from '@/store'
import router from '@/router'
import axios from './Axios'
import $Dialog from './Dialog'
import i18n from '@/i18n'

const model = {
	//首页数据
	GetBackData(callback) {
		const getData = ({
			data
		}) => {
			store.dispatch('UpdateInitData', data.data.info || '');
			callback && callback(data.data.info);
		}
		axios.post('api/base/backdata', {
			noLogin: true
		}).then(getData);
	},
	//添加地址 ----新地址
	Openaddress(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/User/do_wallet', json).then(getData);
	},
	//获取等级列表以及流水
	getgradeteam(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('/index/index/team_grade').then(getData);
	},


	//扫雷接口
	Getsweepuser(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/mine/getInfo').then(getData);
	},
	//获取倍率
	GetSettingsinfo(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/mine/getMineSet').then(getData);
	},
	//开始游戏
	Startthegame(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/mine/startMine', json).then(getData);
	},
	//扫雷中
	getmineclearance(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/mine/dragaminas', json).then(getData);
	},
	//游戏结束
	gameover(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/mine/endGame', json).then(getData);
	},

	//获取地址列表
	GetaddressList(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('/index/index/get_with_address').then(getData);
	},

	//获取地雷历史
	Getgamehistory(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('/index/mine/minesLog', json).then(getData);
	},
	//获取地雷历史
	Getruleslist(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/base/mineRule').then(getData);
	},


	//删除地址
	Deladdress(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('/index/index/del_with_address', json).then(getData);
	},
	// 获取充值地址  ----新
	// Getrechargeadd(callback) {
	// 	const getData = ({
	// 		data
	// 	}) => {
	// 		callback && callback(data);
	// 	}
	// 	axios.post('api/Recharge/do_deposit').then(getData);
	// },
	//提现 ----新
	Getwalletout(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Withdraw/do_withdraw', json).then(getData);
	},
	//k线数据
	GetkxList(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('/api/huobi/get_trade', json).then(getData);
	},
	// 获取btc实时行情数据
	Getbtc(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.get('/api/huobi/get_huobi_symbol?symbol=' + json.type).then(getData);
	},
	// 获取ethusdt实时数据
	Getethusdt(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.get('/api/huobi/get_huobi_symbol?symbol=ethusdt').then(getData);
	},
	// 获取聊天数据  ----新机器人预测
	Getrobot(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Robot/robotList').then(getData);
	},
	// 获取聊天详细数据
	Getbotlist(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Robot/robotInfo', json).then(getData);
	},
	// 跟投

	Getgt(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('/index/btc/betpre', json).then(getData);
	},
	// 获取home所有数据
	Getallusdt(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.get('/index/huobi/get_huobi').then(getData);
	},
	//登录
	Login(json, callback) {
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				localStorage['Token'] = data.data.userinfo.token;
				router.replace('/');
			}
			callback && callback(data);
			$Dialog.Toast(data.msg);
		}
		axios.post('api/User/login', json, {
			noLogin: true
		}).then(getData);
	},
	// 充值记录   -----新
	getrechargelog(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Recharge/record').then(getData);
	},
	// 消息推送
	getinfomation(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/get_push_msg').then(getData);
	},
	// 一键已读
	getyijian(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/push_msg_read', json).then(getData);
	},
	// 提值记录 -----新
	getwithdrawnlog(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Withdraw/record', json).then(getData);
	},
	//退出登录
	Logout(callback) {
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				localStorage.clear();
				router.replace('/');
			}
			$Dialog.Toast(data.code_dec);
		}
		axios.post('User/Logout').then(getData);
	},
	//注册
	UserRegister(json, callback) {
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				this.Login({
					account: json.username,
					password: json.password
				})
			}
			callback && callback(data)
		}
		axios.post('api/User/register', json, {
			noLogin: true
		}).then(getData);
	},
	//修改密码
	ResetPassword(json, callback) {
		const getData = ({
			data
		}) => {
			if (data.result == 1) {
				this.Login({
					username: json.username,
					password: json.password
				})
			}
			callback && callback(data);
		}
		axios.post('index/login/editPwd', json, {
			noLogin: true
		}).then(getData);
	},
	//废弃 --修改登录密码
	EditloginPassword(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/editLoginPassword', json).then(getData);
	},
	//修改资金密码 ----- 新
	EditfundPassword(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Index/resetfundpwd', json).then(getData);
	},
	//注册获取验证码 ---新增邮箱验证码
	GetSMSCode(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/ems/send', json, {
			noLogin: true
		}).then(getData);
	},
	//忘记密码获取验证码
	EditPwdCode(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/sem/send_edit_code', json, {
			noLogin: true
		}).then(getData);
	},
	//暂时不用-- 用户信息
	GetUserInfo(callback) {
		const getData = ({
			data
		}) => {
			if (data.type == 'ok') {
				store.dispatch('UpdateUserInfo', data.message);
			} else {
				router.replace('/login');
			}
			callback && callback(data);
		}
		axios.post('api/user/user_info').then(getData);
	},

	//充值获取二维码与usdt地址
	Recharge(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/get_recharge_add').then(getData);
	},
	//不用 -- 上传充值的图片
	UploadImg(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/upload', json, {
			fromData: true
		}).then(getResponse);
	},
	//提现
	walletOut(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/withpost', json).then(getResponse);
	},
	//转入转出记录
	History_record(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/history_record', json).then(getData);
	},
	//真实下注
	gobet(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/btc/bet', json).then(getData);
	},
	//虚拟下注
	VirtualBetting(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/btc/bet_cs', json).then(getData);
	},
	//获取期数
	get_period(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Btc/get_period', json).then(getData);
	},
	//虚拟下注记录
	bet_cs_records(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/btc/bet_cs_records', json).then(getData);
	},
	//下注记录
	bet_records(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Btc/bet_records', json).then(getData);
	},
	//下注
	 Normalerecords(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Btc/bet', json).then(getData);
	},
	//包赔下注模式
	Bagrecords(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Btc/bet_active', json).then(getData);
	},
	//历史开奖----新历史
	period_histroy(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Btc/period_histroy', json).then(getData);
	},
	//获取签到记录
	getSign(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/Sign/index').then(getData);
	},
	//签到
	DoSign(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/Sign/add').then(getData);
	},
	//用户转账
	UserTransfer(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/transfer', json).then(getData);
	},
	//邀请好友链接信息
	getInvite(callback) {
		const getData = ({
			data
		}) => {
			// if (data.result == 1) {
			callback && callback(data);
			// } else {
			// 	$Dialog.Toast(data.info);
			// }
		}
		axios.post('index/index/invite').then(getData);
	},
	// 清楚历史消息1-----新消息全部已读
	getdelinfo(callback) {
		const getData = ({
			data
		}) => {
			// if (data.result == 1) {
			callback && callback(data);
			// } else {
			// 	$Dialog.Toast(data.info);
			// }
		}
		axios.post('api/index/clear_msg').then(getData);
	},
	// 更改登陆密码    ------ 新
	GetnewPass(json, callback) {
		const getData = ({
			data
		}) => {
			// if (data.result == 1) {
			callback && callback(data);
			// } else {
			// 	$Dialog.Toast(data.info);
			// }
		}
		axios.post('api/Index/resetpwd', json).then(getData);
	},
	Getxiaji(json, callback) {
		const getData = ({
			data
		}) => {
			// if (data.result == 1) {
			callback && callback(data);
			// } else {
			// 	$Dialog.Toast(data.info);
			// }
		}
		axios.post('index/index/tadyTeam', json).then(getData);
	},
	//团队详情 -----新
	Getliebiao(json, callback) {
		const getData = ({
			data
		}) => {
			// if (data.result == 1) {
			callback && callback(data);
			// } else {
			// 	$Dialog.Toast(data.info);
			// }
		}
		axios.post('api/index/getTeamList', json).then(getData);
	},
	//我的矿机
	getMyMiner(callback) {
		const getData = ({
			data
		}) => {
			if (data.result == 1) {
				callback && callback(data);
			} else {
				$Dialog.Toast(data.info);
			}
		}
		axios.post('index/robot/index').then(getData);
	},
	//领取矿机收益
	ReceiveIncome(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/robot/receive').then(getData);
	},
	//购买矿机
	BuyMiner(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/robot/buy', json).then(getData);
	},
	//我的团队
	Myteam(json, callback) {
		const getData = ({
			data
		}) => {
			if (data.result == 1) {
				callback && callback(data);
			}
		}
		axios.post('index/team/index', json).then(getData);
	},
	//钱包地址
	getCard(callback) {
		const getData = ({
			data
		}) => {
			// if(data.result==1){
			callback && callback(data);
			// }
		}
		axios.post('index/index/card').then(getData);
	},
	//添加钱包地址
	AddCard(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/addcardpost', json).then(getData);
	},
	//转入转出记录总览
	getWallet(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/index').then(getData);
	},
	//转出
	withpost(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/withpost', json).then(getData);
	},

	//我的矿机-矿机详情
	order_detail(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/robot/order_detail', json).then(getData);
	},
	//我的矿机收益详情
	Profit_detail(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/robot/profit_detail', json).then(getData);
	},

	//提交邀请任务视频链接
	SubmitInviteTask(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/inviteTask', json).then(getData);
	},
	//个人信息修改
	EditInfo(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/editInfo', json).then(getData);
	},
	//充值
	Recharge(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/wallet/get_recharge_add').then(getData);
	},
	//海报
	getuserInviteInfo(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/getuserInviteInfo').then(getData);
	},

	//收益图
	getIncomes(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/income').then(getData);
	},

	//修改提现地址
	editcard(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/index/editcard', json).then(getData);
	},
	//理财列表
	getyuebaoList(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/yuebao/order', json).then(getData);
	},
	//理财列表
	Financial_management_list(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/yuebao/products').then(getData);
	},
	//购买理财产品
	Buyproduct(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/yuebao/buy', json).then(getData);
	},
	//领取理财收益
	receiveMoney(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/yuebao/receive', json).then(getData);
	},
	//领取理财收益记录
	receiveRecord(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/yuebao/records', json).then(getData);
	},
	//取消理财计划
	takeoff(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/yuebao/takeoff', json).then(getData);
	},
	SmsCode(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('sms/smsCode', '', {
			noLogin: true
		}).then(getData);

	},
	SignIn(callback) {
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				this.GetUserInfo()
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('user/signin').then(getData);
	},
	GetUserVip(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('user/getUserVip').then(getData);
	},
	SetUserInfo(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				this.GetUserInfo();
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('user/setuserinfo', json).then(getData);
	},
	CreateOrder(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				router.push('/user/invest');
				this.GetUserInfo();
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('Order/createOrder', json).then(getData);
	},
	OrderList(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Order/Orderlist').then(getData);
	},
	OrderRecordList(id, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Order/orderRecordList', {
			orderid: id
		}).then(getData);
	},
	FundDetails(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Transaction/FundDetails', json).then(getData);
	},
	GetDrawRecord(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Transaction/getDrawRecord', json).then(getData);
	},
	Draw(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				this.GetUserInfo();
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('Transaction/draw', json).then(getData);
	},
	Transfer(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				this.GetUserInfo();
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('Transaction/Transfer', json).then(getData);
	},
	AddBankCard(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				this.GetBankCardList()
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('Account/AddBankCard', json).then(getData);
	},
	GetBankCardList(callback) {
		const getData = ({
			data
		}) => {
			store.dispatch('UpdateBankCardList', data.data || []);
			callback && callback(data);
		}
		axios.post('Account/getBankCardList').then(getData);
	},
	GetRechargeRecord(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Transaction/getRechargeRecord', json).then(getData);
	},
	GetRechargeType(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Transaction/getRechargetype', {
			type: 'app'
		}).then(getData);
	},
	UpiRechargeOrder(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Recharge/upiRechargeOrder', json).then(getData);
	},
	RechargeOrder(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getData = ({
			data
		}) => {
			if (data.code == 1) {
				router.push('/user/recharge/' + data.orderNumber);
			}
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('Recharge/newRechargeOrder', json).then(getData);
	},
	GetRechargeInfo(orderId, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Recharge/getRechargeInfo', {
			orderNumber: orderId
		}).then(getData);
	},

	BuyVip(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('User/userBuyVip', json).then(getResponse);
	},
	PostTask(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('task/publishTask', json).then(getResponse);
	},
	GetTaskList(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/getTaskList', json).then(getResponse);
	},
	ReceiveTask(id, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
			if (data.code == 1) {
				this.GetUserInfo()
			}
		}
		axios.post('task/receiveTask', {
			id: id
		}).then(getResponse);
	},
	GetTaskinfo(id, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/getTaskinfo', {
			id: id
		}).then(getResponse);
	},
	GetTaskRecord(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/taskOrderlist', json).then(getResponse);
	},
	SubmitTask(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('task/taskOrderSubmit', json).then(getResponse);
	},
	RechargeImage(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('recharge/getRechargeImage', json).then(getResponse);
	},
	CancelTask(id, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('task/revokeTask', {
			id: id
		}).then(getResponse);
	},
	TaskOrderInfo(id, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/taskorderinfo', {
			order_id: id
		}).then(getResponse);
	},
	AuditTask(json, callback) {
		$Dialog.Loading(i18n.t('dialog[2]'));
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
			$Dialog.Toast(data.code_dec);
		}
		axios.post('task/taskOrderTrial', json).then(getResponse);
	},
	DailyReport(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('User/dailyReport').then(getResponse);
	},
	CreditList(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('user/getUserCreditList').then(getResponse);
	},
	TeamReport(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('user/teamReport', json).then(getResponse);
	},
	newLc(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebao/getYuebaoList').then(getResponse);
	},
	getIncome(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('user/profit').then(getResponse);
	},
	getPoster(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('user/getuserInviteInfo').then(getResponse);
	},

	reciveYuebao(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebao/reciveYuebao', json).then(getResponse);
	},
	getUserYuebaoList(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebao/getUserYuebaoList', json).then(getResponse);
	},
	payYuebao(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebao/payYuebao', json).then(getResponse);
	},
	newLcTj(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebao/payYuebao', json).then(getResponse);
	},
	newList(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebaojilu/getYuebaojiluList', json).then(getResponse);
	},
	yeb(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Yuebao/showMoney', json).then(getResponse);
	},
	getInvitInfo(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/getInvitInfo').then(getData);
	},
	getInviteTask(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/getInviteTask').then(getData);
	},

	getUsdtRecharge(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('recharge/getUsdtRecharge').then(getData);
	},
	getupiRechargeOrder(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('recharge/upiRechargeOrder', json).then(getData);
	},
	upadteTaskCheck(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('task/taskCheck', json).then(getData);
	},
	getDrawMessage(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Transaction/getDrawMessage', json).then(getData);
	},
	EditBankCard(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('Account/changeBankCardInfo', json).then(getData);
	},
	// 获取密保问题
	getquestion(callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/login/question', '', {
			noLogin: true
		}).then(getData);
	},
	getProblem(class_id, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('usermessage/getProblemList', {
			class_id: class_id
		}).then(getData);
	},
	getAnswer(pid, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('usermessage/getProblemDetail', {
			pid: pid
		}).then(getData);
	},
	getNewMessage(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('usermessage/getNewMessage', json).then(getData);
	},
	haveNewMessage(json, callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('usermessage/haveNewMessage', json).then(getData);
	},
	//获取团队列表
	getTeam(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/team/index').then(getResponse);
	},
	getTeamDetail(json, callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/team/team_detail', json).then(getResponse);
	},
	getlevelup(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('index/team/levelup_detail').then(getResponse);
	},
	//投资理财
    getfinancial(callback) {
    	const getResponse = ({
    		data
    	}) => {
    		callback && callback(data);
    	}
    	axios.post('api/Management/index').then(getResponse);
    },
	//购买理财产品
    getmanagement(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Management/buy',json).then(getResponse);
	},
	//购买理财订单
	getpurchaseorder(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Management/record',json).then(getResponse);
	},
	//邀请和团队
	getinviteradnteam(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/index/invite').then(getResponse);
	},
	//资金划转
	Fundtransferorder(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/User/exchange',json).then(getResponse);
	},
	//资金划转记录
	getFundingrecords(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/User/exchangeLog',json).then(getResponse);
	},
	//所有的资金记录
	getallzijin(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/User/fund_records',json).then(getResponse);
	},
	//红包兑换
	getRedenvelope(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/User/receive_packet',json).then(getResponse);
	},
	//团队佣金
	getTeamcommission(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/index/getTeamDetail',json).then(getResponse);
	},
	//转账
	gettransfer(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/user/transfer',json).then(getResponse);
	},
	//转账记录
	getTransferrecord(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/user/transfer_records',json).then(getResponse);
	},
	//实名认证
	getverified(json,callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/User/verified',json).then(getResponse);
	},
	//实名认证
	getxinchange(callback) {
		const getResponse = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post('api/Recharge/index').then(getResponse);
	},
	// 获取充值地址  ----新
	Getrechargeadd(url,callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post(url).then(getData);
	},
	// 获取银行卡充值 ----新
	Getbankrecharge(url,json,callback) {
		const getData = ({
			data
		}) => {
			callback && callback(data);
		}
		axios.post(url,json).then(getData);
	},
}

export default model