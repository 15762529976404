<template>
  <!-- 所有记录 -->
  <div class="Mypurse">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh"
     :pulling-text="$t('addpull.pullyi')" :loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="Mypurse-title">
      <van-icon
        @click="$router.push('/my')"
        name="arrow-left"
        size="2rem"
        color="#000"/>
      <div class="title">{{$t('Mypurse.title')}}</div>
      <div class="img">
        <img src="" alt="" />
      </div>
    </div>
    <div class="jilu">
      <div
        class="box"
        :class="{ boxs: tabcolor === 1 }"
        @click="qiehuancontent(1)">
        {{$t('Mypurse.All')}}
      </div>
      <!-- <div class="xian"></div> -->
      <!-- <div
        v-if="iconshow"
        class="box"
        :class="{ boxs: tabcolor === 2 }"
        @click="qiehuancontent(2)"
      >
        {{ type }} <van-icon name="arrow-down" />
      </div> -->
      <!-- <div
        v-else
        class="box"
        :class="{ boxs: tabcolor === 3 }"
        @click="qiehuancontent(3)"
      >
        {{ type }} <van-icon name="arrow-up" />
      </div> -->
    </div>
  <div class="fundos"> * {{$t('addmy.mywts')}}</div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('Mypurse.Theres')"
      :loading-text="$t('Mypurse.Loading')"
      @load="onLoad">
      <div class="content" v-for="(i, v) in list" :key="v">
        <div class="left">
          <div>{{ i.category }}</div>
          <div class="time">{{ i.createtime }}</div>
        </div>
        <div class="right">
          {{
            parseFloat(i.price) >= 0
              ? "+" + parseFloat(i.price).toFixed(2)+' '+i.unit
              : parseFloat(i.price).toFixed(2)+' '+i.unit
          }}
        </div>
      </div>
    </van-list>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      @select="onSelect"
      @click-overlay="overlay"
      @open="open"
      @close="close"
      :cancel-text="$t('Mypurse.Cancel')"
      close-on-click-action
      @cancel="onCancel"
    />
    </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { allrecordsapi } from '@/api/index'
// import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      tabcolor: 1,
      iconshow: true,
      show: false,
      actions: [
        { name: this.$t('Mypurse.Recharge') }, // 充值
        { name: this.$t('Mypurse.withdraw') }, // 提现
        { name: this.$t('Mypurse.product') }, // 产品购买
        { name: this.$t('Mypurse.daily') }, // 每日收入
        { name: this.$t('Mypurse.Direct') }, // 直推奖励
        { name: this.$t('Mypurse.envelope') }, // 红包领取
        { name: this.$t('Mypurse.fixed') }, // 固定收入
        { name: this.$t('Mypurse.check') }, // 系统调账
        { name: this.$t('Mypurse.benefits') }, // 余额宝购买
        { name: this.$t('Mypurse.System') }, // 余额宝收益
        { name: this.$t('Mypurse.yuebao') }, // 下级余额宝购买奖励
        { name: this.$t('Mypurse.shouyi') }, // 余额宝申购失败
        { name: this.$t('Mypurse.amount') }, // 奖励金额转入
        // { name: this.$t('Mypurse.jiangli') }, // 签到现金奖励
        { name: this.$t('Mypurse.qiandao') }, // 签到
        { name: this.$t('Mypurse.tuandui') }, // 团队收益
        { name: this.$t('Mypurse.zhuce') }, // 注册赠送
        { name: this.$t('Mypurse.keyong') }, // 自动转入可用余额
        { name: this.$t('Mypurse.jiangli') } // 大转盘奖励
      ],
      type: this.$t('Mypurse.type'),
      page: '1', // 页码
      limit: '12', // 一页请求多少条
      list: [], // 数据列表
      total: 0,
      types: -1,
      loading: false,
      finished: false,
      isLoading: false
    }
  },
  created () {
    // this.onLoad(-1)
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 接口数据
    async allrecordsapi () {
      const data = {
        limit: this.limit,
        page: this.page
      }
      const res = await allrecordsapi(data)
      if (res.data.last_page <= this.page) {
        this.finished = true
      }
      this.list.push(...res.data.data)
      //   this.loading = false
    },
    async onLoad () {
      this.loading = true
      await this.allrecordsapi()
      this.page++
      this.loading = false
      // this.total=
    //   console.log(111)
      // 异步更新数据
    },
    async onSelect (item) {
    //   console.log('111', item)
    // 默认情况下点击选项时不会自动收起
    // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false
      this.iconshow = true
      this.type = item.name
      if (item.name === 'Reload') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 1
        this.onLoad()
      } else if (item.name === 'Remove') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 2
        this.onLoad()
      } else if (item.name === 'Purchase of products') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 3
        this.onLoad()
      } else if (item.name === 'Daily income') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 4
        this.onLoad()
      } else if (item.name === 'Direct push awards') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 5
        this.onLoad()
      } else if (item.name === 'Red envelope collection') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 6
        this.onLoad()
      } else if (item.name === 'Stable income') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 7
        this.onLoad()
      } else if (item.name === 'System adjustment') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 8
        this.onLoad()
      } else if (item.name === 'Purchase Yu E Bao') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 9
        this.onLoad()
      } else if (item.name === 'Profits of Yu E Bao') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 10
        this.onLoad()
      } else if (item.name === 'Buying rewards of Yu E Bao') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 11
        this.onLoad()
      } else if (item.name === 'Failed to sign Yu E Bao') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 12
        this.onLoad()
      } else if (item.name === 'Value of reward transferred to') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 13
        this.onLoad()
      } else if (item.name === 'Check-in') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 21
        this.onLoad()
      } else if (item.name === 'Benefits of the team') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 22
        this.onLoad()
      } else if (item.name === 'Entry draw') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 23
        this.onLoad()
      } else if (item.name === 'Transfer available balance') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 24
        this.onLoad()
      } else if (item.name === 'Junior disco award') {
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = 25
        this.onLoad()
      }
    },
    // 全部切换
    // 点击遮罩层
    overlay () {
      this.iconshow = true
    },
    // 打开选项触发
    open () {
      this.tabcolor = 3
    },
    // 关闭选项触发
    close () {
      this.tabcolor = 2
    },
    onCancel () {
      this.iconshow = true
    },
    qiehuancontent (index) {
    //   console.log(index)
      if (index === 2) {
        this.tabcolor = 2
        this.iconshow = false
        this.show = true
      } else if (index === 3) {
        this.tabcolor = 3
        this.iconshow = true
        this.show = false
      } else {
        this.tabcolor = 1
        this.type = this.$t('Mypurse.type')
        this.page = 1
        this.loading = false
        this.finished = false
        this.list = []
        this.types = -1
        this.onLoad()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Mypurse {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Mypurse-title {
     padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .jilu {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 2rem;
    .box {
      color: #fff;
      width: 50%;
      text-align: center;
      &:nth-child(1) {
        // border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .boxs {
      color: #15b4fb;
    }
  }
  .fundos {
    font-size: .8rem;
    color: #fa7d1f;
    margin-top: 1rem;
    margin-bottom: -.5rem;
  }
  .content {
    width: 100%;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid #f6f6f6;
    font-size: 1.3rem;
    .left {
      .time {
        font-size: 1.1rem;
        color: #333;
        margin-top: 0.6rem;
      }
    }
    .right {
      color: #02D79A;
      font-weight: 700;
    }
  }
}
</style>
