<template>
  <!-- 修改密码 -->
  <div class="ChangePassword">
    <div class="ChangePassword-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
         @click="$router.push('/install')"
      />
      <div class="title">{{$t('ChangePassword.title')}}</div>
      <div></div>
    </div>
    <van-form @submit="onSubmit" ref="form">
      <div>
        <div class="Email">{{$t('ChangePassword.Old')}}</div>
        <van-field v-model="oldpassword" :rules="rules.oldpassword"  />
      </div>
      <div>
        <div class="Email">{{$t('ChangePassword.New')}}</div>
        <van-field v-model="newpassword" :rules="rules.newpassword" />
      </div>
      <div>
        <div class="Email">{{$t('ChangePassword.Confirm')}}</div>
        <van-field
          v-model="Confirmationpassword"
          :rules="rules.Confirmationpassword"
        />
      </div>
      <div>
        <div class="Email">{{$t('mail.mailbox')}}</div>
        <van-field v-model="emil" :disabled="true" :rules="[
                {
                    required: true,
                    message: this.$t('mail.srmail'),
                    trigger: ['blur', 'onSubmit']
                }
            ]"/>
      </div>
      <div>
        <div class="Email">{{$t('mail.yzm')}}</div>
        <van-field v-model="emilcode" :rules="[
                {
                    required: true,
                    message: this.$t('mail.sryzm'),
                    trigger: ['blur', 'onSubmit']
                }
            ]" >
            <template #button>
                <button class="codebutton" @click="sendcode" :disabled="codestatus" type="button">{{codecontent}}</button>
            </template>
        </van-field>
      </div>
      <!-- 提示-->
      <div class="message">
        <p>*{{$t('addmy.xgpassts')}}</p>
      </div>
      <div class="submit">
        <van-button round block type="info" native-type="submit">{{$t('ChangePassword.Determine')}}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Changepasswordapi, scode } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      oldpassword: '',
      newpassword: '',
      emil: '',
      emilcode: '',
      Confirmationpassword: '',
      timer: null,
      codestatus: false,
      codecontent: this.$t('mail.sendcode'),
      rules: {}
    }
  },
  mounted () {
    this.setfromrules()
  },
  watch: {
    '$i18n.locale' () {
      this.setfromrules()
    }
  },
  created (){
    // console.log(this.$route.query.emali)
    if (localStorage.getItem('is_email') == 0) { // eslint-disable-line
      console.log(111)
      this.$router.push('/MailBox')
    } else {
      // this.withdrawal()
      this.emil = this.$route.query.emali
    }
  },
  methods: {
    sendcode () { // 发送验证码
      const that = this
      if (that.emil == '') { // eslint-disable-line
        Toast({
          message: that.$t('mail.emptyemail'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      that.codecontent = 300
      that.SendCode() // 发送请求
      that.codestatus = true
      that.timer = setInterval(() => {
        if (that.codecontent > 0) {
          that.codecontent--
        }
        if (that.codecontent == 0) { // eslint-disable-line
          that.codecontent = that.$t('mail.sendcode')
          that.codestatus = false
        }
      }, 1000)
    },
    async SendCode () { // 发送验证码请求
      const that = this
      const data = {
        email: that.emil,
        event: 'changepwd'
      }
      const res = await scode(data)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      console.log(res)
    },
    setfromrules () {
      this.rules = {
        oldpassword: [
          {
            required: true,
            message: this.$t('ChangePassword.oldpassword'),
            trigger: ['blur', 'change']
          },
          {
            validator: (value) => {
              return value.trim() !== ''
            },
            message: this.$t('ChangePassword.oldpassword'),
            trigger: ['blur', 'change']
          }
        ],
        newpassword: [
          {
            required: true,
            message: this.$t('ChangePassword.newpassword'),
            trigger: ['blur', 'change']
          },
          {
            validator: (value) => {
              return value.trim() !== ''
            },
            message: this.$t('ChangePassword.newpassword'),
            trigger: ['blur', 'change']
          }
        ],
        Confirmationpassword: [
          {
            required: true,
            message: this.$t('ChangePassword.Confirmationpassword'),
            trigger: ['blur', 'change']
          },
          {
            validator: (value) => {
              return value === this.newpassword
            },
            message: this.$t('ChangePassword.twopasswords'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    onSubmit (values) {
      // console.log('submit', values)
      this.Changepassword()
    },
    async Changepassword () {
      const data = {
        oldpwd: this.oldpassword.trim(),
        newpwd: this.newpassword.trim(),
        conpwd: this.Confirmationpassword.trim(),
        captcha: this.emilcode.trim()
      }
      const res = await Changepasswordapi(data)
      // console.log(res)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (res.code === 1) {
        setTimeout(() => {
          this.$router.push('/install')
        }, 1000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ChangePassword {
  background-color: #fff;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
   width: 100%;
  min-height: 100vh;
  .ChangePassword-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  ::v-deep .van-cell {
    background-color: #fff;
    border-radius: 20px 20px 20px 20px;
    border: 4px solid #f6f6f6;
    border-radius: 1rem;
    // margin-top: 2rem;
    padding-left: 2rem;
    font-size: 1.3rem;
    &::after {
      border-bottom: 0px solid #000;
    }
  }
  ::v-deep .van-field__control {
    color: #333;
  }
  ::v-deep .position {
    position: relative;
  }
  .code {
    color: #15b4fb;
    font-size: 1.4rem;
    position: absolute;
    top: 1.2rem;
    right: 2.2rem;
  }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #5979FB;
    border: none;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #333;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .message{
    margin-top: 1rem;
    color: red;
    font-weight: 700;
  }
  .submit {
    margin-top: 4rem;
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #15b4fb;
    text-align: center;
    line-height: 4.4rem;
    // margin-top: 11rem;
    border-radius: 2.75rem;
    font-size: 1.6rem;
    color: #fff;
  }
}

.codebutton{
  padding: 0 0.4rem;
  background-color: #15b4fb;
  border: none;
  border-radius: 0.2rem;
  color: #fff;
}
</style>
