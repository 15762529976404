<template>
  <!-- 我的 -->
  <div class="my">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
    <div style="min-height: 100vh;">
    <!-- <div class="mybj">
      <img src="../assets/mybj.png" alt="" />
    </div> -->
    <div class="mybk">
      <!-- 设置和客服-->
      <div class="mytop">
        <!-- <img src="../assets/mytop1.png" /> -->
        <img src="../assets/indexkefu.png" @click="tosignin" />
        <img src="../assets/mytop2.png" @click="toinstall" />
      </div>
      <div class="pos">
        <div class="userinfoone">
          <div class="avatar">
            <img :src="imgsrc" alt="" />
          </div>
          <div style="margin-left: 2rem;">
            <div style="font-size: 2rem;">
              {{ user.nickname }}
              <!-- <span class="ids">ID:{{ user.id }}</span> -->
            </div>
            <div style="display: flex;align-items: center;margin-top: .5rem;">
              <div class="id_vip">ID: {{user.id}}</div>
              <div :class="[vip?'vipbox':'vipboxs']">
                <div class="vipleft">
                  <div>LV{{user.level}}</div>
                </div>
                <div class="vipright" v-show="vip">
                  2022-10-10
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="service">
            <img src="../assets/service.png" alt=""/>
          </div> -->
        </div>
        
      </div>
    </div>
    <div style="background: #fff;padding-top: 2rem;padding-bottom: 8rem;border-top-left-radius: 2.5rem;border-top-right-radius: 2.5rem;" class="toppos">
      <!-- <div style="display: flex;flex-wrap: wrap;justify-content: center;">
        <div class="userinfo">
          <div class="Income">
            <div class="box">
              <div class="title">{{ $t("my.Accumulated") }}</div>
              <div class="green color">{{ parseFloat(user.award_profit) }}</div>
            </div>
            <div class="border"></div>
            <div class="box">
              <div class="title">{{ $t("my.Today") }}</div>
              <div class="green color">{{ parseFloat(user.today_earn) }}</div>
            </div>
            <div class="border"></div>
            <div class="box">
              <div class="title">Total <br />revenue</div>
              <div class="title">Income</div>
              <div class="green color">{{ parseFloat(user.profit_return) }}</div>
            </div>
            <div class="border"></div>
            <div class="box">
              <div class="title">{{ $t("my.available") }}</div>
              <div class="green color">{{ parseFloat(user.money) }}</div>
            </div>
          </div>
        </div> 
      </div> -->
      <div class="mywallet">
        <div class="qbtitle">
          <span>{{$t('addmy.mywyi')}}</span><span class="two">{{parseFloat(user.total_balance).toFixed(2)}}</span><span>USDT</span>
        </div>
        <!-- <div class="qbcon">
          <div class="conone">
            <div>Today's Earnings：<br />{{parseFloat(user.today_earn)}}</div>
            <div>Total Rewards：<br />{{parseFloat(user.award_profit)}}</div>
          </div>
          <div class="conone" style="margin-left: 2rem;">
            <div>Total Revenue：<br />{{parseFloat(user.profit_return)}}</div>
            <div>Available Balance：<br />{{parseFloat(user.money)}}</div>
          </div>
        </div> -->
        <div class="qbconer">
          <div class="cononeer">
            <div>{{$t('addmy.mywer')}}{{parseFloat(user.today_earn).toFixed(2)}}</div>
            <div>{{$t('addmy.mywsan')}}{{parseFloat(user.profit_return).toFixed(2)}}</div>
          </div>
          <div class="cononeer">
            <div>{{$t('addmy.mywsi')}}{{parseFloat(user.award_profit).toFixed(2)}}</div>
            <div>{{$t('addmy.mywwu')}}{{parseFloat(user.money).toFixed(2)}}</div>
          </div>
        </div>
        <div class="qbbtn">
          <div class="btn" @click="$router.push('/recharges')">
            <img src="../assets/wallet1.png" />
            {{$t('addmy.mywliu')}}
          </div>
          <div class="btn" @click="towithdraw">
            <img src="../assets/wallet2.png" />
            {{$t('addmy.mywqi')}}
          </div>
        </div>
      </div>
      <div class="Positioning">
        <!-- <div class="install" @click="toinstall">
          <img src="../assets/install.png" alt="" />
        </div> -->
        <div class="Notice" style="height: 2rem;">
          <div class="img">
            <img src="../assets/notice.png" alt="" />
          </div>
          <van-swipe
            class="my-swipe"
            :autoplay="3000"
            indicator-color="white"
            :show-indicators="false">
            <van-swipe-item v-for="(i, v) in announcement" :key="v">{{
              i
            }}</van-swipe-item>
          </van-swipe>
        </div>
        <div class="content">
          <div class="content-box" @click="tomypurse">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/purse.png" alt="" /></div>
              <div class="huang">{{ $t("my.wallet") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="toproduct">
            <div class="myflex">
              <div class="img imgmargin" style="padding-top: 0.5rem;"><img src="../assets/gpt.png" alt="" /></div>
              <div class="huang">{{ $t("my.mykj") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="tobangzhu">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/mark.png" alt="" /></div>
              <div class="huang">{{ $t("my.center") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="toaddress">
            <div class="myflex">
              <div class="img imgmargin" style="margin-top: 2.5rem;"><img src="../assets/address.png" alt="" /></div>
              <div class="huang" style="margin-top: 0.5rem;">{{ $t("my.address") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="toredemption">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/envelope.png" alt="" style="height: 2.9rem;" /></div>
              <div class="huang">{{ $t("my.envelope") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="tovideo">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/Video.png" alt="" /></div>
              <div class="huang">{{ $t("my.Video") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="appxiazai">
            <div class="myflex">
              <div class="img imgmargin" style="padding-top: 0.5rem;"><img src="../assets/app.png" alt="" /></div>
              <div class="huang">{{ $t("my.Download") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="tocompanycetails">
            <div class="myflex">
              <div class="img" style="margin-top: 2.5rem;width: 4rem;height: 4rem;margin-left: 3rem;"><img src="../assets/company.png" alt="" /></div>
              <div class="huang">{{ $t("my.company") }}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="$router.push('/Activity')">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/Task.png" alt="" /></div>
              <div class="huang">{{$t('addhome.rw')}}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
          <div class="content-box" @click="$router.push('/Duration')">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/timedrown.png" alt="" /></div>
              <div class="huang">{{$t('addmy.myba')}}</div>
            </div>
            <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
          </div>
        </div>
        <div class="bottom" @click="tologin">{{ $t("my.Sign") }}</div>
      </div>
    </div>
    </div>
    </van-pull-refresh>
    <TabBar></TabBar>
  </div>
</template>

<script>
import { withdrawalapi, myuserapi, loginoutapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'MY',
  data () {
    return {
      address: '',
      user: {},
      imgsrc: '',
      emali: '',
      announcement: [],
      isLoading: false,
      vip: 0,
      dwstatus: 0,
      download: {}
    }
  },
  created () {
    this.withdrawal()
    this.myuser()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 去帮助中心
    tobangzhu () {
      this.$router.push('/helpcenter')
    },
    // 去产品状态
    toproduct () {
      this.$router.push('/product')
    },
    // 去设置详情页面
    toinstall () {
      this.$router.push('/install')
    },
    // 去视频介绍
    tovideo () {
      this.$router.push('/video')
    },
    // 去我的钱包
    tomypurse () {
      this.$router.push('/mypurse')
    },
    // 去设置地址
    toaddress () {
      this.$router.push({
        path: '/address',
        query: {
          emali: this.emali
        }
      })
    },
    // 去红包页面
    toredemption () {
      this.$router.push('/redemption')
    },
    // 去公司介绍
    tocompanycetails () {
      this.$router.push('/companycetails')
    },
    // 下载app
    appxiazai () {
      console.log(this.$imgUrl+this.download.IOS.url)
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        window.open(this.$imgUrl+this.download.IOS.url)
      } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
        window.open(this.$imgUrl+this.download.Android.url)
      } else {
        // client = 'PC'
      }
      // return client
    },
    // 退出登录
    async tologin () {
      const res = await loginoutapi()
      // console.log('111', res)
      localStorage.removeItem('token')
      sessionStorage.removeItem('show')
      sessionStorage.removeItem('gonggaoshow')
      localStorage.removeItem('lang')
      localStorage.removeItem('tink_var')
      localStorage.removeItem('is_email');
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      setTimeout(() => {
        this.$router.push('/login')
      }, 1500)
    },
    // 获取地址
    async withdrawal () {
      const res = await withdrawalapi()
      this.address = res.data.wallet_address
    },
    async myuser () {
      const res = await myuserapi()
      console.log(res, 'my')
      this.user = res.data.user
      this.download = res.data.download
      this.user.award_profit = parseFloat(this.user.award_profit).toFixed(2)
      this.user.today_earn = parseFloat(this.user.today_earn).toFixed(2)
      this.user.profit_return = parseFloat(this.user.profit_return).toFixed(2)
      this.user.money = parseFloat(this.user.money).toFixed(2)
      this.imgsrc = res.data.user.avatar
      this.emali = res.data.user.email
      this.announcement = res.data.notice
      this.dwstatus = res.data.dw_status
    },
    // 去签到页面
    tosignin () {
      this.$router.push('/signin')
    },
    // 去体现
    towithdraw (){
      this.$router.push('/withdraw')
      // if(this.dwstatus == 1){
      //   this.$router.push('/withdraw')
      // }else{
      //   Toast({
      //     message: 'Please activate the machine first',
      //     duration: 2000,
      //     className: 'noticeWidth' // 所加样式名
      //   })
      //   this.$router.push('/mining')
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.my {
  // background-color: #060606;
  // background-image: url("../assets/indexback.png");
  background-size: 100% 90rem;
  background-repeat: no-repeat;
  width: 100%;
  // min-height: 100vh;
  .mybj {
    width: 100%;
  }
  .Positioning {
    padding: 0rem 1.6rem;
    // padding-top: 5rem;
    position: relative;
    .install {
      z-index: 99;
      width: 2rem;
      height: 1.9rem;
      position: absolute;
      top: -15rem;
      right: 1.6rem;
    }
    .Notice {
      display: flex;
      color: #333;
      // margin-top: 5.5rem;
      .img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1.3rem;
        margin-top: 0.4rem;
      }
      ::v-deep .my-swipe {
        width: 30rem;
      }
      ::v-deep .van-swipe-item {
        color: #333;
        font-weight: 700;
        font-size: 1.3rem;
        margin-top: 0.5rem;
        width: 30rem;
        height: 2rem;
        // line-height: 2rem;
        text-align: center;
        // background-color: #39a9ed;
      }
    }
    .content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      // flex-wrap: wrap;
      // justify-content: space-between;
      .content-box {
        width: 10.2rem;
        height: 10.2rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1);
        font-size: 1.2rem;
        color: #fff;
        margin-top: 1.8rem;
        .img {
          width: 2.91rem;
          height: 3.27rem;
          margin-left: 3.7rem;
          margin-top: 2rem;
          // margin: 0 auto;
          // margin-top: 1.8rem;
          // margin-bottom: 1.5rem;
        }
        .imgmargin{
          margin-top: 3rem;
        }
        // &:last-child {
        //   background-color: #060606;
        // }
      }
      .huang {
        color: #F6AD66;
        // margin-left: 1rem;
      }
    }
    .bottom {
      width: 100%;
      height: 4.4rem;
      background-color: #5979FB;
      text-align: center;
      line-height: 4.4rem;
      margin-top: 5rem;
      margin-bottom: 2rem;
      border-radius: 2.75rem;
      font-size: 1.6rem;
      color: #fff;
    }
  }
}

/* 修改的*/
.mytop{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
  // margin: 2.3rem 1.5rem;
}
.mytop img{
  width: 2.5rem;
  height: 2.5rem;
}
.mytop img:nth-child(2){
  height: 2.5rem;
  margin-left: 2.5rem;
}
.userinfoone{
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  align-items: center;
  // position: absolute;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  .avatar {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    // background-color: #fff;
    // margin: 0 auto;
    // margin-top: -3.5rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
.color{
  color: #02D79A !important;
}
.mybk{
  background-image: url('../assets/mybj.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2.3rem 1.5rem;
  padding-bottom: 5.3rem;
}
.userinfo {
      // position: absolute;
      // top: -7rem;
      // left: 1.6rem;
      width: 34.3rem;
      // // height: 15.85rem;
      background-color: #01061A;
      color: #fff;
      border-radius: 1rem;
      margin-bottom: 1.5rem;
      padding: 1rem 0;
      .name {
        // display: flex;
        // justify-content: center;
        color: #fff;
        margin-top: 1.55rem;
        // margin-left: 1.5rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 600;
        // span {
        //   font-size: 0.7rem;
        //   margin-left: 0.5rem;
        //   color: #ccc;
        // }
      }
      .avatar {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        // background-color: #fff;
        // margin: 0 auto;
        // margin-top: -3.5rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .service {
        position: absolute;
        top: 1.3rem;
        right: 1.3rem;
        width: 1.9rem;
        height: 1.88rem;
      }
      .name {
        color: #000;
        margin-top: 1.55rem;
        // margin-left: 1.5rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 600;
        
        // span {
        //   font-size: 0.7rem;
        //   margin-left: 0.5rem;
        //   color: #ccc;
        // }
      }
      .vipbox {
        width: 18rem;
        padding: .6rem 1.5rem;
        border-radius: 2rem;
        margin: 1rem auto;
        background: linear-gradient(130deg, #D1AE7A 0%, #FCE8CA 34%, #E2C49B 72%, #EDD9BC 100%);
        box-shadow: 0px 1px 3px 1px rgba(248,226,194,0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        .vipleft {
          display: flex;
          align-items: center;
          .vipimg {
          width: 2.4rem;
          margin-top: -.3rem;
          margin-right: .5rem;
        }
        }
      }
      
      .Income {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: #26272E;
        // margin-top: 2.5rem;
        padding: 1rem 1rem;
        padding-bottom: .5rem;
        border-radius: 2.5rem;
        .box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // width: 25%;
          // height: 7rem;
          text-align: center;
          color: #333;
          font-size: 1.6rem;
          padding: 0 0.5rem;
          padding-top: .5rem;
          // margin-top: 1rem;
          &:nth-child(3) {
            border-right: 0px solid #fff;
          }
          .title {
            color: #fff;
            font-size: 1rem;
            margin-bottom: 0.9rem;
          }
        }
        .green {
          color: #15FBB5;
        }
        .border {
          width: 1px;
          height: 6.5rem;
          background-color: rgba(255, 255, 255, 0.1);
          // margin-top: 2.4rem;
        }
      }
    }
    .daibi{
      display: flex;
      // justify-content: center;
      align-items: center;
      font-weight: bold;
      margin: .5rem 2rem;
      font-size: 1.5rem;
      border-top: 2px solid #f6f6f6;
      border-bottom: 2px solid #f6f6f6;
      // font-weight: 700;
      color: #333;
      padding: 1rem 0;
      .dbleft{
        margin-right: 10rem;
      } 
    }
    // 11.14 修改
    .id_vip{
      display: flex;
      justify-content: space-between;
      // margin: 0 5rem;
    }
    .vipboxs {
        width: 10.4rem;
        // padding: .6rem 1.5rem;
        padding-left: 3.5rem;
        padding-bottom: .2rem;
        height: 2.3rem;
        margin-left: 1rem;
        border-radius: 2rem;
        // margin: 1rem auto;
        // background: linear-gradient(130deg, #D1AE7A 0%, #FCE8CA 34%, #E2C49B 72%, #EDD9BC 100%);
        background-image: url('../assets/myvip.png');
        background-size: contain;
        background-repeat: no-repeat;
        // color: #fff;
        // box-shadow: 0px 1px 3px 1px  #939393;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        .vipleft {
          display: flex;
          align-items: center;
          .vipimg {
          width: 2.4rem;
          margin-top: -.3rem;
          margin-right: .3rem;
        }
        }
      }
      .toppos{
        position: relative;
        bottom: 2rem;
      }
/* 11.17修改我的钱包*/
.mywallet{
  margin: 2rem;
  margin-top: 0;
  background-image: url('../assets/chartbk.png');
  background-size: cover;
  background-repeat: no-repeat;
  // color: #fff;
  // box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
  border-radius: 10px 10px 10px 10px;
  // opacity: 1;
  padding: 1.5rem;
  color: #fff;
  font-size: 1.3rem;
  .qbtitle{
    .two{
      font-size: 1.5rem;
      font-weight: 700;
      color: #FFB31C;
      margin-right: .5rem;
    }
  }
  .qbcon{
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .conone{
      
      // padding-right: 4rem;
      margin-top: .7rem;
    }
  }
  .qbbtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    .btn{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #02D7C1;
      img{
        width: 2rem;
        margin-right: .5rem;
      }
    }
  }
}

.qbconer{
  font-size: 10px;
  .cononeer{
    display: flex;
    margin-top: .5rem;
    justify-content: space-between;
    div{
      width: 100%;
    }
  }
}
</style>
