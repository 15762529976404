<template>
  <!-- 设置页面 -->
  <div class="install">
    <div class="install-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
         @click="$router.push('/my')"
      />
      <div class="title">{{$t('install.title')}}</div>
      <div></div>
    </div>
    <!-- 修改头像-->
    <!-- <div class="info" @click="tohandimg">
      <div class="left">
        <div class="userimg">
          <img src="../assets/user.png" alt="" />
        </div>
        <div>{{name}}</div>
      </div>
      <div class="right">
        <div class="avatar">
          <img :src="imgsrc" alt="">
        </div>
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="info" @click="tomodifyname">
      <div class="left">
        <div class="userimg">
          <img src="../assets/card.png" alt="" />
        </div>
        <div>{{$t('install.name')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div>
    <div class="info" @click="toChangePassword">
      <div class="left">
        <div class="userimg">
          <img src="../assets/Lock.png" alt="" />
        </div>
        <div>{{$t('install.Password')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 更换手机号目前不需要了 -->
    <!-- <div class="info" @click="tophonenumber">
      <div class="left">
        <div class="userimg">
          <img src="../assets/Phone.png" alt="" />
        </div>
        <div>{{$t('install.Phone')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div> -->
    <!-- <div class="info" @click="tomodifyemail">
      <div class="left">
        <div class="userimg">
          <img src="../assets/email.png" alt="" />
        </div>
        <div>Modify email</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div> -->
    <!-- 绑定电报 -->
    <div class="info" @click="$router.push('/airplane')">
      <div class="left">
        <div class="userimg">
          <img src="../assets/binding.png" alt="" />
        </div>
        <div>{{$t('addmy.iboyi')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 绑定飞机 -->
    <div class="info" @click="$router.push('/telegraph')">
      <div class="left">
        <div class="userimg">
          <img src="../assets/xiaofeiji.png" alt="" />
        </div>
        <div>{{$t('addmy.iboer')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 绑定邮箱 -->
    <div class="info" @click="$router.push('/MailBox')">
      <div class="left">
        <div class="userimg">
          <img src="../assets/youjian.png" alt="" />
        </div>
        <div>{{$t('addmy.ibosan')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 切换语言 -->
     <!-- <div class="info" @click="switchlanguage">
      <div class="left">
        <div class="userimg">
          <img src="../assets/binding.png" alt="" />
        </div>
        <div>{{$t('install.switch')}}</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div> -->
    <!-- <div class="info">
      <div class="left">
        <div class="userimg">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div>Xiao-i suporte oficial</div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { myuserapi } from '@/api/index'
export default {
  name: 'insTall',
  data () {
    return {
      name: '',
      Language: localStorage.getItem('lang') || 'pt-br',
      imgsrc: '',
      emali: ''
    }
  },
  created () {
    this.myuser()
  },
  methods: {
    tohandimg () {
      this.$router.push('/Headimage')
    },
    // 切换语言
    switchlanguage () {
      if (this.Language === 'zh-cn') {
        this.Language = 'en-us'
        this.$i18n.locale = 'en-us'
        localStorage.setItem('tink_var', 'pt-br')
      } else if (this.Language === 'en-us') {
        this.$i18n.locale = 'zh-cn'
        this.Language = 'zh-cn'
        localStorage.setItem('tink_var', 'pt-br')
      }
    },
    // 去修改姓名
    tomodifyname () {
      this.$router.push('/modifyname')
    },
    // 去修改密码
    toChangePassword () {
      console.log(this.emali)
      this.$router.push({
        path: '/changepassword',
        query: {
          emali: this.emali
        }
      })
    },
    // 去修改手机号
    tophonenumber () {
      this.$router.push('/phonenumber')
    },
    // 去修改邮箱
    tomodifyemail () {
      this.$router.push('/modifyemail')
    },
    async myuser () {
      const res = await myuserapi()
      console.log(res)
      this.name = res.data.user.nickname
      this.imgsrc = res.data.user.avatar
      this.emali = res.data.user.email
    }
  }
}
</script>

<style lang="less" scoped>
.install {
  background-color: #fff;
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .install-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 11rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f6f6f6;
    padding: 1.55rem 0rem;
    .left {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 1.4rem;
      .userimg {
        width: 2.42rem;
        height: 2.42rem;
        margin-right: 1.35rem;
      }
    }
    .right {
      display: flex;
      .avatar {
        width: 2.2rem;
        height: 2.2rem;
        background-color: #fff;
        border-radius: 50%;
        margin-right: 1.35rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .RightArrow {
        width: 0.5rem;
        height: 0.83rem;
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
