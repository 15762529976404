<template>
  <div class="error" ref="err"></div>
</template>

<script>
export default {
  name: 'ERROR',
  data () {
    return {
      html: ''
    }
  },
  mounted () {
    this.html = this.$route.query.info
    // console.log(this.html)
    const div = document.querySelector('.error')
    div.innerHTML = this.html
  }
}

</script>

<style lang="less" scoped>

.error {
  padding: 0rem 6rem;
//   padding-top: 2.5rem;
  background-color: #000;
   width: 100%;
  min-height: 100vh;
  color: #fff;
  text-align: center;
  padding-top: 45%;
}
</style>
