<template>
	<div class="assist">
		<div class="asstitle">
			<van-icon name="arrow-left" size="2rem" color="#fff" @click="$router.push('/home')" />
		</div>
		<div class="title">Haz clic en "Ayuda ahora" para ayudar a tus amigos a recolectar fragmentos de IA y obtener
			robots gratis.</div>
		<!-- 轮播图-->
		<div class="minning">
			<img :src="robotlist.img" />
			<div class="mincon">
				<div class="minconyi">
					<p>Numero de serie: {{robotlist.sn}}</p>
					<p class="minconyip">{{robotlist.day}} Días</p>
				</div>
				<div class="minconyi" style="margin-top: 1rem">
					<p>Nombre: {{robotlist.name}}</p>
				</div>
				<div class="minconyi" style="margin-top: 1rem">
					<p>Precio: {{robotlist.price}}</p>
				</div>
				<div class="minconyi" style="margin-top: 1rem">
					<p>Ingreso: {{robotlist.income}}</p>
				</div>
			</div>
		</div>
		<!-- 轮播图-->
		<div class="body">
			<div class="btitle" @click="toshive">
				<span>Ayuda inmediata</span>
			</div>
			<div class="bodyp">
				<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
					<van-swipe-item v-for="(item,index) in wzlist" :key="index">*{{item}}</van-swipe-item>
					<!-- <van-swipe-item>*Ayuda a un amigo de inmediato y obtendrá fragmentos</van-swipe-item>
          <van-swipe-item>*Ayuda a un amigo de inmediato y obtendrá fragmentos</van-swipe-item> -->
				</van-swipe>
			</div>
			<!-- body1-->
			<!-- <div class="btitletwo">Ayudar al progreso</div>
      <div class="bone">
        <ProGress :width="300" :progress="40" :strokeWidth="15" :showInfo="true"></ProGress>
        <div class="bonediv">Fragmentos asistidos <span>50 / 100</span></div>
      </div> -->
			<!-- body2-->
			<div class="btitletwo">Registro de ayuda</div>
			<div class="btwo">
				<div class="btwodiv">
					<div class="er" v-for="(item,index) in list.list" :key="index">
						<div class="erleft">
							<div class="erleftimg">
								<img :src="item.head" />
							</div>
							<div class="erspan">{{item.username}}</div>
						</div>
						<div class="erright">
							<div class="errightyi">{{item.createtime}}</div>
							<div class="errighter">
								Este amigo contribuyó con <span class="errighterspan">{{item.num}}</span> piezas.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <ProGress :width="600" :progress="36" :strokeWidth="10" :showInfo="true"></ProGress> -->
	</div>
</template>

<script>
	import {
		shiveindex,
		do_support,
		robot_list
	} from '@/api/index'
	// import ProGress from '@/components/Progress.vue';
	import {
		Toast
	} from 'vant'
	export default {
		components: {
			// ProGress
		},
		data() {
			return {
				shiveid: '',
				sn: '',
				list: '',
				wzlist: [],
				robotlist: ''
			}
		},
		created() {
			// console.log(this.$route.query.shive_id, this.$route.query.sn, '刚进入')
			localStorage.removeItem('type')
			localStorage.removeItem('shiveid')
			localStorage.removeItem('sn')
			// console.log(this.$route.query, '111');
			
			// let url = window.location.href
			// let uid = url.split('?')[1]
			// let uid1 = url.split('=')[1]
			// let css = uid.split('=')[0]
			
			// console.log(css,'312');
			
			// let Base64 = require('js-base64').Base64
			// let shive_id = Base64.decode(css)
			// let sn = Base64.decode(uid1)
			// console.log(sn,'2312');
			// // this.getrobot_list()
			// let zsshive_id = shive_id.split('+')[1]
			// // console.log(zsshive_id, '111333');
   //           return
			if (this.$route.query.shive_id != undefined) {
				// let Base64 = require('js-base64').Base64
				// // console.log(this.$route.query.shive_id)
				// let shive_id = Base64.decode(this.$route.query.shive_id)
				// let sn = Base64.decode(this.$route.query.sn)
				// console.log(shive_id)
				this.shiveid = this.$route.query.shive_id
				this.sn = this.$route.query.sn
				this.getdata()
				this.getrobot_list()
			} else {
				this.getdata()

			}
		},
		methods: {
			async getdata() {
				const data = {
					shive_id: this.shiveid
				}
				const res = await shiveindex(data)
				// if (res.data.is_self == 1) {
				//  this.$router.push('/AssiSt')
				// }
				this.list = res.data
				for (var i = 0; i < this.list.show_list.length; i++) {
					this.wzlist.push('Felicitaciones a ' + this.list.show_list[i].id +
						' por ganar un robot gratis de ' + this.list.show_list[i].price +
						' USDT (precio del producto)')
				}
				// console.log(this.wzlist)
			},
			async toshive() {
				const data = {
					shive_id: this.shiveid,
					sn: this.sn
				}
				let Base64 = require('js-base64').Base64
				localStorage.setItem('type', 'shive')
				localStorage.setItem('shiveid', Base64.encode(this.shiveid))
				localStorage.setItem('sn', Base64.encode(this.sn))
				const res = await do_support(data)
				console.log(res)
				if (res.code == 1) {
					Toast({
						message: res.msg,
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
					localStorage.removeItem('type')
					localStorage.removeItem('shiveid')
					localStorage.removeItem('sn')
					this.getdata()
				} else {
					Toast({
						message: res.msg,
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
				}
			},
			async getrobot_list() {
				const data = {
					sn: this.sn
				}
				const res = await robot_list(data)
				console.log(res)
				this.robotlist = res.data
			}
		}
	}
</script>

<style lang="less" scoped>
	.assist {
		background-image: url('../assets/assbk.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 100vh;

		.asstitle {
			padding: 2rem;
		}

		.title {
			font-size: 2rem;
			color: #ADDAFA;
			font-weight: normal;
			text-align: center;
			margin: 0 2rem;
		}

		/* 轮播图*/
		.minning {
			text-align: center;
			margin-top: 3rem;
			padding-bottom: 3rem;

			img {
				width: 36.25rem;
				height: 22rem;
			}

			.mincon {
				margin: 0 auto;
				margin-top: 3rem;
				width: 33rem;
				background: rgba(8, 51, 121, 0.9);
				opacity: 1;
				border-radius: 1.67rem;
				border: 0px solid #7AF0FF;
				padding: 1rem 1.5rem;
				font-weight: bold;
				font-size: 1.2rem;
				color: #FFFFFF;

				.minconyi {
					display: flex;
					justify-content: space-between;
					align-content: center;

					.minconyip {
						color: rgba(124, 146, 191, 1);
					}
				}
			}
		}

		.body {
			margin-top: 5rem;

			.btitle {
				background: linear-gradient(#F7FBFD 0%, #C2FAFE 38%, #8CF8FF 99%, #8CF8FF 100%);
				transform: skewX(-20deg);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.3rem;
				font-weight: normal;
				border-radius: 5px;
				padding: 1.5rem 0;
				margin: 0 5rem;
			}

			.btitletwo {
				background-image: url('../assets/assimg.png');
				background-repeat: no-repeat;
				background-size: cover;
				color: #ADDAFA;
				font-size: 1.3rem;
				font-weight: bold;
				width: 31rem;
				padding-left: 3rem;
				margin-top: 4rem;
			}

			.bodyp {
				font-size: 1.2rem;
				color: #575A61;
				font-weight: bold;
				margin: 0 2.5rem;
				margin-top: 2.5rem;
			}

			.bone {
				background-image: url('../assets/assbk2.png');
				background-repeat: no-repeat;
				background-size: cover;
				margin: 0 1.5rem;
				margin-top: 2rem;
				height: 8.2rem;
				padding-top: 2.5rem;

				.bonediv {
					text-align: center;
					font-size: 1.2rem;
					color: #fff;

					span {
						font-size: 1.3rem;
						font-weight: 800;
						color: #8DF8FF;
					}
				}
			}

			.btwo {
				background-image: url('../assets/assbk3.png');
				background-repeat: no-repeat;
				background-size: cover;
				margin: 0 1.5rem;
				margin-top: 2rem;
				height: 50rem;
				padding: 2rem 2.5rem;

				.btwodiv {
					height: 35rem;
					overflow-y: scroll;
				}

				.er {
					display: flex;
					justify-content: space-between;
					color: #fff;
					font-size: 1.2rem;
					margin-top: 3rem;

					.erleft {
						display: flex;

						.erleftimg {
							width: 4rem;
							height: 4rem;
							background-color: #fff;
							border-radius: 10px;
							margin-right: .5rem;

							img {
								width: 100%;
								height: 100%;
								border-radius: 10px;
							}
						}
					}

					.erright {
						text-align: right;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						justify-content: end;

						.errighter {
							.errighterspan {
								font-size: 1.3rem;
								font-weight: 800;
								color: #8CF8FF;
							}
						}
					}
				}
			}
		}
	}
</style>