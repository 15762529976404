<template>
	<div class="assist">
		<div class="asstitle">
			<van-icon name="arrow-left" size="2rem" color="#fff" @click="$router.push('/receive')" />
			<van-icon name="wap-nav" color="#fff" size="2rem" @click="$router.push('/AssiStminning')" />
		</div>
		<div class="title">Haz clic en "Invitar ahora" y tus amigos te ayudarán a recolectar fragmentos de IA y podrás
			obtener robots gratis.</div>
		<!-- 轮播图-->
		<div class="lunbody">
			<div @click="changeSwiper(1)" class="lunleft">
				<van-icon name="arrow-left" color="#fff" size="30px" />
			</div>
			<van-swipe class="my-swipe" ref="ticketSwiper" :autoplay="0" indicator-color="white" @change="change">
				<van-swipe-item v-for="(item,index) in robotlist" :key="index">
					<div class="minning">
						<img :src="item.img" />
						<div class="mincon">
							<div class="minconyi">
								<p>Numero de serie: {{item.sn}}</p>
								<p class="minconyip">{{item.day}} Días</p>
							</div>
							<div class="minconyi" style="margin-top: 1rem">
								<p>Nombre: {{item.name}}</p>
							</div>
							<div class="minconyi" style="margin-top: 1rem">
								<p>Precio: {{item.price}}</p>
							</div>
							<div class="minconyi" style="margin-top: 1rem">
								<p>Ingreso: {{item.income}}</p>
							</div>

						</div>
					</div>
				</van-swipe-item>
			</van-swipe>
			<div @click="changeSwiper(2)" class="lunyou">
				<van-icon name="arrow" color="#fff" size="30px" />
			</div>
		</div>
		<!-- 轮播图-->
		<div class="body">
			<div class="btitle" @click="assistgo">
				<span class="copy" @click="tozl">Sigue invitando a tus amigos a ayudar</span>
			</div>
			<!-- <p class="bodyp" v-if="assist == 1">*Ayuda a un amigo de inmediato y obtendrá fragmentos</p> -->
			<!-- body1-->
			<div>
				<div class="btitletwo">Ayudar al progreso</div>
				<div class="bone" v-show="list">
					<ProGress :width="300" :progress="pgnumber" :strokeWidth="15" :showInfo="true"></ProGress>
					<div class="bonediv">Fragmentos asistidos <span>{{list.already}} / {{list.number}}</span></div>
				</div>
			</div>
			<!-- body2-->
			<div class="btitletwo">Registro de ayuda</div>
			<div class="btwo">
				<div class="btwodiv">
					<div class="er" v-for="(item,index) in list.list" :key="index">
						<div class="erleft">
							<div class="erleftimg">
								<img :src="item.head" />
							</div>
							<div class="erspan">{{item.username}}</div>
						</div>
						<div class="erright">
							<div class="errightyi">{{item.createtime}}</div>
							<div class="errighter">
								Este amigo contribuyó con <span class="errighterspan">{{item.num}}</span> piezas.
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="er">
          <div class="erleft">
            <div class="erleftimg"></div>
            <div class="erspan">1**5</div>
          </div>
          <div class="erright">
            <div class="errightyi">2024-02-01 12:00:00</div>
            <div class="errighter">
              Te he ayudado con <span class="errighterspan">50</span> piezas.
            </div>
          </div>
        </div> -->
			</div>
		</div>
		<!-- <ProGress :width="600" :progress="36" :strokeWidth="10" :showInfo="true"></ProGress> -->
	</div>
</template>

<script>

	import {
		shiveindex,
		robot_list,
		qqshare
	} from '@/api/index'
	import ProGress from '@/components/Progress.vue';
	import {
		Toast
	} from 'vant'
	import clipboard from 'clipboard';
	export default {
		components: {
			ProGress
		},
		data() {
			return {
				list: '',
				robotlist: [],
				sn: '',
				shiveid: '',
				pgnumber: 0,
				zlcs:0,
				qbzlcs:0
			}
		},
		created() {
			this.getrobot_list()
		},
		methods: {
			assistgo() {
				// this.$router.push('/AssiSter')
				console.log(11)
			},
			async getdata() {
				const data = {
					shive_id: this.shiveid
				}
				const res = await shiveindex(data)
				this.list = res.data
				this.zlcs = res.data.already
				this.qbzlcs = res.data.number
				console.log(res, '1111')
				if (parseInt(res.data.already) == 0) {
					this.pgnumber = 0
				} else {

					// console.log(res.data.number);
					this.pgnumber = parseInt((res.data.already / res.data.number) * 100)
				}
				console.log(this.pgnumber, '12313');
				if (res.code == 1) {
					if (res.data.is_self == 0) {
						this.$router.push('/AssiSter')
					}
				}
				// res.data.is_self = 0
			},
			async tozl() {
				console.log(this.sn, '32423');
				const data = {
					sn: this.sn
				}
				const res = await qqshare(data)
				console.log(res);

				if (res.code == 1) {
					if(this.zlcs<this.qbzlcs) {
						let url = 'https://ampgpt.io/#/AssiSter?sn=' + this.sn + '&shive_id=' + this.shiveid
						// let Base64 = require('js-base64').Base64
						// Base64.encode(password);//加密
						// Base64.decode(password);//解密
						// console.log(Base64.encode('123'))
						// console.log(this.shiveid)
						
						// let shive_id = Base64.encode(`s= + ${this.shiveid}`)
						// let sn = Base64.encode(`sn= + ${this.sn}`)
						// let url = window.location.origin +'/#/AssiSter?' + shive_id + sn
						// console.log('编译后',url);
						
						// let shive_id = Base64.encode(this.shiveid)
						// let sn = Base64.encode(this.sn)
						// let url = window.location.origin + '/#/AssiSter?sn=' + sn + '&shive_id=' + shive_id
						
						// console.log(url)
						 
						
						      // clipboard.writeText(url);
						      
							  
						
						setTimeout(()=>{
							this.$copyText(url).then(()=>{
								Toast({
									message: this.$t('party.Success'),
									duration: 1200,
									className: 'noticeWidth' // 所加样式名
								})
							},500)
						})
						
					}else{
					   Toast({
					   	message: 'La asistencia actual ha sido completada y usted ha obtenido la máquina.',
					   	duration: 1200,
					   	className: 'noticeWidth' // 所加样式名
					   })
					}
				} else {
					Toast({
						message: res.msg,
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
				}


			},
			async getrobot_list() {
				const res = await robot_list()
				// console.log(res)
				if (res.code == 1) {
					this.robotlist = res.data
					this.sn = this.robotlist[0].sn
					this.shiveid = this.robotlist[0].shive_id
					this.getdata()
				}
				// console.log(this.sn)
			},
			changeSwiper(e) {
				if (e == 1) {
					this.$refs.ticketSwiper.prev();
				} else {
					this.$refs.ticketSwiper.next();
				}
			},
			async change(index) {
				this.sn = this.robotlist[index].sn
				this.shiveid = this.robotlist[index].shive_id
				if (this.shiveid == 0) {
					const data = {
						sn: this.sn
					}
					const res = await qqshare(data)
					this.shiveid = res.data.shive_id
					// console.log(res)
				}
				this.getdata()
				// console.log(index, this.sn, 'sn')
			}
		}
	}
</script>

<style lang="less" scoped>
	.assist {
		background-image: url('../assets/assbk.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		min-height: 100vh;

		.asstitle {
			padding: 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.title {
			font-size: 2rem;
			color: #ADDAFA;
			font-weight: normal;
			text-align: center;
			margin: 0 2rem;
		}

		/* 轮播图*/
		.lunbody {
			position: relative;

			.lunleft {
				position: absolute;
				left: 2rem;
				top: 35%;
				z-index: 9999;
			}

			.lunyou {
				position: absolute;
				right: 2rem;
				top: 35%;
				z-index: 9999;
			}
		}

		.minning {
			text-align: center;
			margin-top: 3rem;
			padding-bottom: 3rem;

			img {
				width: 36.25rem;
				height: 22rem;
			}

			.mincon {
				margin: 0 auto;
				margin-top: 3rem;
				width: 33rem;
				background: rgba(8, 51, 121, 0.9);
				opacity: 1;
				border-radius: 1.67rem;
				border: 0px solid #7AF0FF;
				padding: 1rem 1.5rem;
				font-weight: bold;
				font-size: 1.2rem;
				color: #FFFFFF;

				.minconyi {
					display: flex;
					justify-content: space-between;
					align-content: center;

					.minconyip {
						color: rgba(124, 146, 191, 1);
					}
				}
			}
		}

		.body {
			margin-top: 5rem;

			.btitle {
				background: linear-gradient(#F7FBFD 0%, #C2FAFE 38%, #8CF8FF 99%, #8CF8FF 100%);
				transform: skewX(-20deg);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.3rem;
				font-weight: normal;
				border-radius: 5px;
				padding: 1.5rem 0;
				margin: 0 5rem;
			}

			.btitletwo {
				background-image: url('../assets/assimg.png');
				background-repeat: no-repeat;
				background-size: cover;
				color: #ADDAFA;
				font-size: 1.3rem;
				font-weight: bold;
				width: 31rem;
				padding-left: 3rem;
				margin-top: 4rem;
			}

			.bone {
				background-image: url('../assets/assbk2.png');
				background-repeat: no-repeat;
				background-size: cover;
				margin: 0 1.5rem;
				margin-top: 2rem;
				height: 8.2rem;
				padding-top: 2.5rem;

				.bonediv {
					text-align: center;
					font-size: 1.2rem;
					color: #fff;

					span {
						font-size: 1.3rem;
						font-weight: 800;
						color: #8DF8FF;
					}
				}
			}

			.btwo {
				background-image: url('../assets/assbk3.png');
				background-repeat: no-repeat;
				background-size: cover;
				margin: 0 1.5rem;
				margin-top: 2rem;
				height: 50rem;
				padding: 2rem 2.5rem;

				.btwodiv {
					height: 35rem;
					overflow-y: scroll;
				}

				.er {
					display: flex;
					justify-content: space-between;
					color: #fff;
					font-size: 1.2rem;
					margin-top: 3rem;

					.erleft {
						display: flex;

						.erleftimg {
							width: 4rem;
							height: 4rem;
							background-color: #fff;
							border-radius: 10px;
							margin-right: .5rem;

							img {
								width: 100%;
								height: 100%;
								border-radius: 10px;
							}
						}
					}

					.erright {
						text-align: right;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						justify-content: end;

						.errighter {
							.errighterspan {
								font-size: 1.3rem;
								font-weight: 800;
								color: #8CF8FF;
							}
						}
					}
				}
			}
		}
	}
</style>