<template>
  <div class="AssiStminningxq">
    <div class="party-title">
			<van-icon
				name="arrow-left"
				size="2rem"
				color="#fff"
				@click="$router.back()"/>
			<!-- <div class="title">Robot details</div> -->
    </div>
		<!-- body-->
		<div class="body">
			<img src="../assets/Aminning.png" class="yi"/>
			<div class="con">
				<div class="conyi">
					<p>Numero de serie: {{list.sn}}</p>
					<p class="cononep">{{list.day}}</p>
				</div>
				<div class="conyi" style="margin-top: 1.5rem">
					<p>Precio: {{list.price}}</p>
					<p>Ingreso: {{list.income}}</p>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
		list: ''
	}
  },
  created () {
	console.log(this.$route.query.item)
	this.list = this.$route.query.item
  },
  methods: {}
}
</script>

<style scoped lang="less">
.AssiStminningxq{
	background-image: linear-gradient(to bottom,#DFA3FF,#5687FD);
  min-height: 100vh;
	.party-title {
		padding-top: 2.5rem;
		display: flex;
		margin-bottom: 4rem;
		.title {
			color: #fff;
			font-size: 1.8rem;
			margin-left: 11rem;
			font-weight: 700;
		}
		::v-deep .van-icon-arrow-left:before {
			margin-top: 0.4rem;
		}
	}
	.body{
		margin-top: 5rem;
		text-align: center;
		.yi{
			width: 11rem;
			height: 17rem;
			margin-bottom: 3rem;
		}
		.con{
			margin: 0 auto;
			width: 35rem;
			background: #DAB9FF;
			border-radius: 1rem;
			padding: 1rem 1.5rem;
			.conyi{
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 1.3rem;
				font-weight: bold;
				color: #fff;
				.cononep{
					font-size: 1.2rem;
					color: #857199;
				}
			}
		}
	}
}
</style>
