<template>
  <!-- 帮助中心 -->
  <div class="Helpcenter">
    <div class="Helpcenter-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"
      />
      <div class="title">{{$t('Helpcenter.title')}}</div>
      <div></div>
    </div>
    <div class="info" v-for="(i,v) in bangzhulist" :key="v" @click="todetail(i.id,i.title)">
      <div class="left">
        <div class="left-title">{{i.title}}</div>
      </div>
      <div class="right">
        <img src="../assets/RightArrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { assistdetailsapi } from '@/api/index'
export default {
  name: 'HelpCenter',
  data () {
    return {
      bangzhulist: []
    }
  },
  created () {
    this.assistdetails()
  },
  methods: {
    async assistdetails () {
      const res = await assistdetailsapi()
      // console.log(res)
      this.bangzhulist = res.data
    },
    todetail (id,title) {
      this.$router.push({
        path: '/bangzhudetails',
        query: {
          id,
          title
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.Helpcenter {
  background: url('../assets/ampbk.png') no-repeat;
  background-size: cover;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Helpcenter-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 11rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    font-size: 1.3rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #f6f6f6;
    .left {
      color: #333;
      font-weight: 700;
    }
    .right {
   width: .8rem;
    }
  }
}
</style>
