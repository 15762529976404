<template>
  <!-- 充值页面 -->
  <div class="Charge">
    <div class="Charge-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#fff"
        @click="$router.back()"
      />
      <div class="title">{{ title }}</div>
    </div>
    <div class="content">
      <div class="box">
        <img :src="Chargelist.img" alt="" />
      </div>
      <div class="title">{{ Chargelist.address }}</div>
      <div class="button" @click="share">{{ $t("Charge.Address") }}</div>
    </div>
    <div class="bottom-title">* {{ $t("Charge.reminder") }}</div>
    <p>1.{{ $t("Charge.assets") }}.</p>
    <p>2.{{ $t("Charge.amount") }}.</p>
    <!-- 上传图片 -->
    <van-uploader
      v-model="fileList"
      :max-count="1"
      :after-read="afterRead"
      preview-size="12rem"
      :before-delete="afetdelete"/>
    <div class="beizhu">{{$t('Charge.Remark')}}</div>
    <div class="btn" @click="btnclick">{{ $t("Charge.Upload") }}</div>
  </div>
</template>

<script>
import { rechargeapi, Uploadpicturesapi, Uploadimgapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'ChArge',
  data () {
    return {
      title: 'USDT (TRC20)',
      Chargelist: '',
      fileList: [],
      url: '',
      files: '',
      money: '',
      entrance: '', // 那个充值的类型
      loading: 0,
      id: ''
    }
  },
  async created () {
    this.money = this.$route.query.money
    this.entrance = this.$route.query.entrance
    this.id = this.$route.query.id
    // console.log(this.id)
    // console.log('777', this.entrance)
    await this.recharge()
  },
  methods: {
    // 文件删除
    afetdelete (detail) {
      this.fileList = []
      this.url = ''
      // console.log('3232', this.fileList)
    },
    // 上传服务器
    async btnclick () {
      if (this.url && this.loading === 1) {
        this.loading = 0
        const data = {
          url: this.url,
          order_no: this.Chargelist.order_no
        }
        const res = await Uploadimgapi(data)
        // console.log(res)
        this.loading = 1
        if (res.code == 1) {
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          setTimeout(() => {
            this.$router.push('/record')
          }, 1000)
        } else {
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      } else {
        Toast({
          message: this.$t('Charge.Please'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    // 图片事件
    async afterRead (file) {
      // console.log('66666', file)
      // console.log(file)
      // 文件读取完成后的回调函数
      const formData = new FormData()
      formData.append('file', file.file)
      // console.log(formData.get('file'))
      this.files = file.file
      // // 自定义加载图标
      Toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        duration: 1500
      })
      const res = await Uploadpicturesapi(formData)
      // console.log(res)
      this.url = res.data.url
      this.loading = 1
    },
    async recharge () {
      const data = {
        money: this.money,
        entrance: this.entrance,
        pay_id: parseFloat(this.id)
      }
      const res = await rechargeapi(data)
      console.log('111', res)
      this.Chargelist = res.data
    },
    // 复制事件
    share () {
     setTimeout(()=>{
			 this.$copyText(this.Chargelist.address).then()
			 Toast({
			   message: this.$t('Charge.Success'),
			   duration: 1200,
			   className: 'noticeWidth' // 所加样式名
			 })
		 })
    }
  }
}
</script>

<style lang="less" scoped>
.Charge {
  background-color: #060606;
  background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
   width: 100%;
  min-height: 100vh;
  .Charge-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 2.7rem;
    .title {
      color: #fff;
      font-size: 1.8rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
      position: fixed;
      top: 4, 3rem;
      left: 1.6rem;
    }
  }
  .content {
    padding: 2.7rem 0rem;
    border: 1px solid #262626;
    border-radius: 1rem;

    .input {
      width: 100%;
      height: 4rem;
      // margin-left: 50%;
      // transform: translateX(-50%);
      // margin: 0 auto;
      margin: 1rem 0rem;
      text-align: center;
      color: #fff;
      background-color: #060606;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 0.5rem;
    }
    .box {
      width: 12rem;
      height: 12rem;
      background-color: #3a3a3a;
      border-radius: 0.5rem;
      margin: 0 auto;
      margin-top: 2.7rem;
    }
    .title {
      margin-top: 2rem;
      color: #fff;
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 2.5rem;
    }
    .button {
      width: 21rem;
      height: 4.4rem;
      background-color: #15b4fb;
      margin: 0 auto;
      border-radius: 2.2rem;
      text-align: center;
      line-height: 4.4rem;
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
    }
  }
  .beizhu {
   color: #FFF;
   text-align: center;
   margin-top: 1rem;
  }
  .btn {
    width: 21rem;
    height: 4.4rem;
    background-color: #15b4fb;
    margin: 0 auto;
    border-radius: 2.2rem;
    text-align: center;
    line-height: 4.4rem;
    font-size: 1.4rem;
    color: #fff;
    font-weight: 600;
    margin-top: 2rem;
  }
  .bottom-title {
    font-size: 1.4rem;
    color: #fff;
    margin-top: 2rem;
  }
  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
  ::v-deep .van-uploader__upload {
    margin-left: 11rem !important;
    margin-top: 2rem;
    border-radius: 1rem;
  }
  ::v-deep .van-uploader__preview {
    margin-left: 11rem !important;
    margin-top: 2rem;
    border-radius: 1rem !important;
  }
  ::v-deep .van-image__img {
    border-radius: 1rem !important;
  }
  ::v-deep .van-uploader__preview-delete-icon {
    font-size: 3rem;
  }
}
</style>
