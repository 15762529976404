<template>
<!-- 废弃页面 -->
  <div></div>
</template>

<script>
export default {
  name: 'AllreCords'

}
</script>

<style>

</style>
