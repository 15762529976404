var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mailbox"},[_c('div',{staticClass:"Phonenumber-title"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"2rem","color":"#000"},on:{"click":function($event){return _vm.$router.push('/install')}}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('addmy.ibosan')))]),_c('div')],1),(_vm.isemail === false)?_c('div',{staticClass:"ChangePassword"},[_c('van-form',[_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('mail.mailbox')))]),_c('van-field',{attrs:{"rules":[
                        {
                            required: true,
                            message: this.$t('mail.srmail'),
                            trigger: ['blur', 'onSubmit']
                        }
                    ]},model:{value:(_vm.oldpassword),callback:function ($$v) {_vm.oldpassword=$$v},expression:"oldpassword"}})],1),_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('mail.yzm')))]),_c('van-field',{attrs:{"rules":[
                        {
                            required: true,
                            message: this.$t('mail.sryzm'),
                            trigger: ['blur', 'onSubmit']
                        }
                    ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{staticClass:"codebutton",attrs:{"disabled":_vm.codestatus,"type":"button"},on:{"click":_vm.sendcode}},[_vm._v(_vm._s(_vm.codecontent))])]},proxy:true}],null,false,406357361),model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})],1),_c('div',{staticClass:"mailts",staticStyle:{"color":"red","margin":"1rem 0"}},[_vm._v(" * "+_vm._s(_vm.$t('addmy.yxts'))+" ")]),_c('div',{staticClass:"submit"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('ChangePassword.Determine')))])],1)])],1):_vm._e(),(_vm.isemail === true)?_c('div',[_vm._m(0),_c('div',{staticClass:"successfully"},[_vm._v(_vm._s(_vm.$t('addmy.yx')))]),_c('div',{staticClass:"info"},[_c('div',[_vm._v(_vm._s(_vm.$t('addmy.appsan'))+" ")]),_c('div',[_vm._v(_vm._s(_vm.conta))])]),_c('div',{staticClass:"btn",on:{"click":_vm.tishi}},[_vm._v("Cambiar e-mail")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chneggong"},[_c('img',{attrs:{"src":require("../assets/chengong.png"),"alt":""}})])}]

export { render, staticRenderFns }