<template>
  <div class="TaskRecord">
    <!-- 自定义nav-->
    <div class="Phonenumber-title">
        <van-icon
            name="arrow-left"
            size="2rem"
            color="#000"
            @click="$router.push('/Activity')" />
        <div class="title">Task Record</div>
        <div></div>
    </div>
    <!-- 内容-->
    <div class="body">
      <div class="content">
        <div class="left">
          <div class="one">invitation bonus</div>
          <div class="two">2023-05-29 15：00：00</div>
        </div>
        <div class="right">+0.01</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TaskRecord',
  data (){
    return {
      hbarr: []
    }
  },
  created (){},
  methods: {}
}
</script>
<style lang="less" scoped>
.TaskRecord{
  background-color: #fff;
  .Phonenumber-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .body{
    margin: 3rem 1.5rem;
    .content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #F6F6F6;
      padding-bottom: 1rem;
      .left{
        .one{
          color: #333333;
          font-size: 2rem;
          margin-bottom: 1rem;
          font-weight: 700;
        }
        .two{
          color: #858585;
        }
      }
      .right{
        color: #02D79A;
        font-size: 2rem;
        font-weight: 700;
      }
    }
  }
}
</style>
