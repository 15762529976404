<template>
  <!-- 转盘记录 -->
  <div class="Recharge">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="Recharge-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"/>
      <div class="title">{{$t('addturn.title')}}</div>
      <div></div>
    </div>
   <!-- 分页 -->
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('Mypurse.Theres')"
      :loading-text="$t('Mypurse.Loading')"
      @load="onLoad">
      <div class="Bitcoin" v-for="(i,v) in yeblist" :key="v">
      <div class="Bitcoin-left">
        <div class="Bitcoin-name">
          <div style=" color:#e8a622;">{{$t('addturn.Bitcoin')}}{{i.cost}}</div>
          <div class="btc">{{i.createtime}}</div>
        </div>
      </div>
      <div class="Bitcoin-right">
       <div>{{$t('addturn.Bitcoiner')}}: {{i.name}}</div>
      </div>
    </div>
    </van-list>

    </div>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { Toast } from 'vant'
import { turntablerecordapi } from '@/api/index'
export default {
  name: 'RecHarge',
  data () {
    return {
      yeblist: [],
      isLoading: false,
      loading: false,
      finished: false,
      page: '1', // 页码
      limit: '12' // 一页请求多少条
    }
  },
  created () {
    // this.onLoad()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    async turntablelist () {
      const data = {
        limit: this.limit,
        page: this.page
      }
      const res = await turntablerecordapi(data)
      // console.log(res)
      if (res.data.last_page <= this.page) {
        this.finished = true
      }
      this.yeblist.push(...res.data.data)
    },
    async onLoad () {
      this.loading = true
      await this.turntablelist()
      this.page++
      this.loading = false
      // this.list.push(...res.data.data)
    }
  }
}
</script>

<style lang="less" scoped>
.Recharge {
  background-color: #060606;
  background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
//   padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Recharge-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .Bitcoin {
    width: 100%;
    background-color: #2b2b2b;
    border-radius: 1rem;
    margin-top: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    padding: 1.5rem;
    margin-top: 2.5rem;
    .Bitcoin-left {
      display: flex;
      .Bitcoin-img {
        width: 2.84rem;
        height: 2.84rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
      .Bitcoin-name {
        .btc {
          color: rgba(255, 255, 255, 0.4);
          margin-top: 1rem;
          font-size: .8rem;
        }
      }
    }
    .Bitcoin-right {
    //   height: 1rem;
    //   margin-bottom: 1rem;
    width: 40%;
    text-align: right;
      color: #15FBB5;
    }
  }
  }
</style>
