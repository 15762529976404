import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
// import zh from './zh'
import English  from './en' // 英文
import Español from './es'
// import pt from './pt' // 葡萄牙语

Vue.use(VueI18n)

const messages = {
  // pt
  English ,
  Español
}
const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem('lang') || 'Español',
  // fallbackLocale: localStorage.getItem('lang') ||'Español', // 如果没有找到要显示的语言，则默认显示 ‘en’
  silentFallbackWarn: true // 控制台上不打印警告
})
export default i18n
