<template>
  <div class="EnvElope">
    <div class="Redemption-title">
      <van-icon
        @click="$router.push('/redemption')"
        name="arrow-left"
        size="2rem"
        color="#000"/>
      <!-- <div class="title">{{$t('Redemption.title')}}</div>
      <div class="img">
        <img src="" alt="" />
      </div> -->
    </div>
    <div class="body">
      <div class="one">
        <img src="../assets/hongbao3.png" />
        <div class="onediv">
          Enviar <span class="onedivspan">{{money}}<span>USDT</span></span>
        </div>
        <p>Enviado correctamente</p>
      </div>
      <div class="two">
        <div>{{content}}</div>
        <img src="../assets/hbfz.png" @click="share" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      content: '65D4564SD65a4s65a4S56A4s65SA56456s...',
      money: 0
    }
  },
  created () {
    this.content = this.$route.query.code
    this.money = this.$route.query.money
    setTimeout(()=>{
			this.$copyText(this.content).then() // 一进来先复制
		},500)
  },
  methods: {
    share () {
      
			setTimeout(()=>{
				this.$copyText(this.content).then()// 一进来先复制
				Toast({
				  message: this.$t('party.Success'),
				  duration: 1200,
				  className: 'noticeWidth' // 所加样式名
				})
			},500)
      
    }
  }
}
</script>

<style scoped lang="less">
.EnvElope{
  background-image: url('../assets/hongbao2.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  .Redemption-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .body{
    margin-top: 5rem;
    .one{
      text-align: center;
      img{
        width: 10rem;
        margin-bottom: 2rem;
      }
      .onediv{
        background: #FFEFBE;
        border-radius: 30px 30px 30px 30px;
        padding: .5rem 1rem;
        color: #333333;
        margin: 0 auto;
        width: 15rem;
        font-size: 14px;
        .onedivspan{
          color: #9E0B00;
          font-size: 18px;
          span{
            font-size: 16px;
          }
        }
      }
      p{
        margin-top: 2rem;
        font-size: 18px;
        color: #9E0B00;
        font-weight: bold;
      }
    }
    .two{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      border-radius: 20px 20px 20px 20px;
      border: 2px solid #878787;
      padding: 1.5rem;
      margin: 0 2rem;
      margin-top: 10rem;
      div{
        width: 80%;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap
      }
      img{
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
</style>
