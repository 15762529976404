<template>
  <!-- 领取红包 -->
  <div class="Redemption">
    <div class="Redemption-title">
      <van-icon
        @click="$router.push('/my')"
        name="arrow-left"
        size="2rem"
        color="#000"/>
      <div class="title">{{$t('Redemption.title')}}</div>
      <div class="img" @click="toredlist">
        <img src="../assets/hbjl.png" alt="" />
      </div>
    </div>
    <!-- 选择红包或代币-->
    <div class="tarcode">
      <div :class="{'tarcodeact':tarindex == 1}" @click="tabqh(1)">{{$t('addmy.codeyi')}}</div>
      <div :class="{'tarcodeact':tarindex == 2}" @click="tabqh(2)">{{$t('addmy.codeer')}}</div>
    </div>
    <div class="name">{{$t('Redemption.Please')}}</div>
    <div class="money">
      <input type="text" v-model="code"/>
    </div>
    <div class="bottom" @click="Receiveredpackets">{{$t('Redemption.Determineer')}}</div>
    <!-- 红包图片-->
    <img src="../assets/hongbao1.png" class="bootomimg" @click="tcshow = true"/> 
    <!-- 红包弹出层-->
    <van-overlay :show="tcshow">
      <div class="wrapper">
        <div class="wrabody">
          <div class="name">{{$t('Redemption.hbyi')}}</div>
          <div class="money" style="margin-bottom: 2rem;">
            <input type="text" v-model="redcode.count"/>
          </div>
          <div class="name">{{$t('Redemption.hber')}}</div>
          <div class="money" style="margin-bottom: 2rem;">
            <input type="text" v-model="redcode.money"/>
          </div>
          <div class="bottom" @click="toenvelope" style="margin-bottom: 1rem;">{{$t('Redemption.Determinesan')}}</div>
          <div class="bottom" @click="tcshow=false" style="margin-bottom: 3rem;">{{$t('addhome.gb')}}</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Receiveredpacketsapi, send_red } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      address: '',
      getnew: false,
      code: '',
      show: false,
      tarindex: 1,
      redcode: {
        count: '',
        money: ''
      },
      url: '/api/active/receive_packet',
      tcshow: false
    }
  },
  methods: {
    tabqh (index) {
      if(index == 1){
        this.url = '/api/active/receive_packet'
      }else{
        this.url = '/api/active/receive_coin_packet'
      }
      this.tarindex = index
    },
    // 领取红包
    async Receiveredpackets () {
      if (this.code !== '') {
        const data = {
          code: this.code
        }
        const res = await Receiveredpacketsapi(this.url,data)
        // console.log(res)
        if (res.code === 1) {
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        } else {
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      } else {
        Toast({
          message: this.$t('Redemption.Exchange'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    toredlist () {
      // Toast({
      //   message: 'Aún no abierto',
      //   duration: 1200,
      //   className: 'noticeWidth' // 所加样式名
      // })
      // return false
      this.$router.push('/RedList')
    },
    // 跳转红包
    async toenvelope () {
      if (this.redcode.count == '' || this.redcode.money == '') {
        Toast({
          message: this.$t('hongbao.tsyi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      const res = await send_red(this.redcode)
      console.log(res)
      if (res.code == 1) {
        this.$router.push({
          path: '/EnvElope',
          query:{
            code: res.data.code,
            money: this.redcode.money
          }
        })
      } else {
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Redemption {
  background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  background-size: cover;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Redemption-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .name {
    color: #333;
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: -1.5rem;
  }
  .money {
    height: 5.5rem;
    border: 3px solid #f6f6f6;
    border-radius: 1rem;
    // border-radius: 1rem;
    color: #333;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    margin-top: 3rem;
    margin-bottom: 15rem;
    input {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: none;
      padding: 0rem 2.5rem;
    }
    .all {
      color: #15b4fb;
      margin-left: 0.8rem;
    }
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #5979FB;
    text-align: center;
    line-height: 4.4rem;
    margin-top: 2rem;
    border-radius: 2.75rem;
    font-size: 1.6rem;
    color: #fff;
  }
  .bootomimg{
    width: 100%;
    margin-top: 4rem;
  }
  ::v-deep .van-popup {
    z-index: 999;
    width: 80%;
    border-radius: 1rem;
    padding: 0rem 1.5rem;
    padding-bottom: 3rem;
    padding-top: 5rem;
    background-color: #2c2c2c;
    text-align: center;
    color: #fff;
    font-size: 1.3rem;
    .successimg {
     width: 7.9rem;
     height: 7.9rem;
     margin: 0 auto;
     margin-bottom: 3rem;
    }
    .redeemed {
    margin-top: 3rem;
    font-size: 1.8rem;
    font-weight: 600;
    span {
        color: #15b4fb;
    }
    }
  }
  //
  .tarcode{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
    div{
      padding: 1rem;
      width: 100%;
      border: 2px solid #f6f6f6;
      text-align: center;
      font-size: 1.3rem;
    }
  }
  .tarcodeact{
    background-color: #15b4fb;
    color: #fff;
  }

  /* 弹出层*/
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* 红包弹出层*/
    .wrabody{
      width: 80%;
      background: #fff;
      padding: 1rem;
      border-radius: 10px;
    } 
  }
}
</style>
