<template>
  <!-- 矿机运行 -->
  <div class="Receive">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div>
        <div class="receivetitle">{{$t('addrece.title')}}</div>
        <div class="topbutton">
          <div class="btn" @click="$router.push('/machineLog')">
            <img src="../assets/yx2.png" />
            <div>{{$t('Receive.qd')}}</div>
          </div>
          <div class="btn" @click="$router.push('/party')">
            <img src="../assets/yx1.png"/>
            <div>{{$t('Receive.yq')}}</div>
          </div>
          <div class="btn" @click="$router.push('/ProfitStatistics')" style="position: relative;top: 0.5rem;">
            <img src="../assets/yx3.png" />
            <div>{{$t('Receive.lrtj')}}</div>
          </div>
          <!-- <div class="btn">
            <img src="../assets/yx4.png" />
            <div>{{$t('Receive.tjsj')}}</div>
          </div>
          <div class="btn">
            <img src="../assets/yx5.png" />
            <div>{{$t('Receive.xj')}}</div>
          </div> -->
        </div>
        <div class="timepiece">
          <div class="box">
            <div v-if="start === 1" class="start" @click="startdevice">
              <img src="../assets/start.png" alt="" />
            </div>
            <div v-if="start === 0" class="start">
              <img src="../assets/end.png" alt="" />
            </div>
            <div class="time">
              <div>{{ $t("Receive.Remaining") }}:</div>
              <!-- <div class="hms">{{hms}}</div> -->
              <van-count-down :time="time" />
            </div>
          </div>
        </div>
        <!-- <div class="button" @click="startdevice">{{ $t("Receive.Start") }}</div> -->
        <div class="view">
          *{{ $t("Receive.Click")
          }}<span class="cente" @click="toHelpcenter"
            >《{{ $t("Receive.Help") }}》</span
          >
        </div>
        <!-- 充值/提款-->
        <!-- <div class="operate">
          <div class="toreceive" @click="torecharge" style="margin-right: 6rem;">
            <div class="Recharge">
              <div  class="rechdiv">
                <div class="wallet">
                  <img src="../assets/blue.png" alt="" />
                </div>
              </div>
            </div>
            <div class="rechargeright">
              <div>{{ $t("Receive.Recharge") }}</div>
              <div style="color: #C8C8C8;margin-top: 1rem;">Click to view</div>
            </div>
          </div>

          <div class="toreceive"  v-if="withstatus == 0" @click="torecharge">
            <div class="Withdraw">
              <div class="rechdiv">
                <div class="wallet">
                  <img src="../assets/zise.png" alt="" />
                </div>
              </div>
            </div>
            <div class="rechargeright">
              <div>{{ $t("Receive.Withdraw") }}</div>
              <div style="color: #C8C8C8;margin-top: 1rem;">Click to view</div>
            </div>
          </div>

          <div class="toreceive"  v-if="withstatus == 1" @click="towithdraw">
            <div class="Withdrawktx">
              <div class="rechdiv">
                <div class="wallet">
                  <img src="../assets/zise.png" alt="" />
                </div>
              </div>
            </div>
            <div class="rechargeright">
              <div>{{ $t("Receive.Withdraw") }}</div>
              <div style="color: #C8C8C8;margin-top: 1rem;">Click to view</div>
            </div>
          </div>
        </div> -->
        <!-- 邀请链接-->
        <div class="Share" @click="yqshow = true"> 
          <div>{{ $t("party.Share") }}</div>
          <div class="right" >
            <div class="title">{{ invite_url }}</div>
            <div class="copyimg">
              <img src="../assets/copy.png" alt="" />
            </div>
          </div>
        </div>
        <div class="Team">{{ $t("Receive.Team") }}</div>
        <div class="revenueinfo" @click="toteam">
          <div class="reone">
            <div class="income">
              <div>
                <img src="../assets/teamone.png" style="width: 3rem;margin-right: 1rem;" />
                {{ $t("Receive.Totalnumber") }}
              </div>
              <div class="green">
                {{ teamobj.total_number ? teamobj.total_number : "0" }}
              </div>
            </div>
            <div class="income" >
              <div>
                <img src="../assets/teamtwo.png" style="width: 3rem;margin-right: 1rem;" />
                {{ $t("Receive.Todays") }}
              </div>
              <div class="green">
                {{ teamobj.daily_income ? teamobj.daily_income : "0" }}
              </div>
            </div>
          </div>
          <!-- <div class="income">
            <div>
              <img src="../assets/teamsan.png" style="width: 3rem;margin-right: 1rem;" />
              {{ $t("Receive.available") }}
            </div>
            <div class="green">
              {{ teamobj.next_income ? teamobj.next_income : "0" }}
            </div>
          </div> -->
        </div>
        <div class="bodyimg" @click="$router.push('/party')">
          <img src="../assets/receiveimg.png" />
        </div>
		
		<div v-if="jjshow==1" class="bodyimg" @click="tofund()" style="margin-top: 2rem;" >
		  <img src="../assets/newjijin.png" />
		</div>
		
        <div class="bodyimg" @click="$router.push('/EvaLuate')" style="margin-top: 2rem;">
          <img src="../assets/Eone.png" />
        </div>
        <div v-show="help_status" class="bodyimg" @click="toAssiSt" style="margin-top: 2rem;" >
          <img src="../assets/toassist.png" />
        </div>
        <!-- <div class="Income">
          <div class="box">
            <div class="title">{{ $t("my.Accumulated") }}</div>
            <div class="green color">{{ parseFloat(user.award_profit) }}</div>
          </div>
          <div class="border"></div>
          <div class="box">
            <div class="title">{{ $t("my.Today") }}</div>
            <div class="green color">{{ parseFloat(user.today_earn) }}</div>
          </div>
          <div class="border"></div>
          <div class="box">
            <div class="title">Total <br />revenue</div>
            <div class="green color">{{ parseFloat(user.profit_return) }}</div>
          </div>
          <div class="border"></div>
          <div class="box">
            <div class="title">{{ $t("my.available") }}</div>
            <div class="green color">{{ parseFloat(user.money) }}</div>
          </div>
        </div> -->
        <!-- <div class="bottom" @click="toteam">
          <div class="Invite">{{ $t("Receive.friends") }}</div>
          <div class="img">
            <img src="../assets/Invitation.png" alt="" />
          </div>
        </div> -->
    </div>
    </van-pull-refresh>
    <!-- 邀请链接弹窗-->
    <van-popup v-model="yqshow" position="bottom" :style="{ height: '40%' }">
      <div class="yqtc">
        <div class="yqlj">
          {{invite_url}}
        </div>
        <div class="yqbtn" @click="share()">{{$t('addrece.tcyi')}}</div>
        <div class="yqbottom">
          <div class="one">{{$t('addrece.tcer')}}</div>
          <div class="two">
            <div class="border"></div>
            <img src="../assets/yaoq1.png" class="img" @click="shareto(1)"/>
            <img src="../assets/yaoq2.png" class="img" @click="shareto(2)"/>
            <img src="../assets/yaoq3.png" class="img" @click="shareto(3)"/>
            <img src="../assets/yaoq4.png" class="img" @click="shareto(4)"/>
            <img src="../assets/yaoq5.png" class="img" @click="shareto(5)"/>
            <img src="../assets/yaoq6.png" class="img" @click="shareto(6)"/>
            <div class="border"></div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 总收益弹窗-->
    <van-popup v-model="syshow" class-name="tcc" z-index="10000" v-if="parseFloat(income).toFixed(2) > 0">
      <div class="czbody">
        <div class="one">
          <div class="oneyi">Mi informacion</div>
          <img src="../assets/appcz.png" @click="syshow = false" />
        </div>
        <div class="two">
          <div class="twobody">
            <img src="../assets/appczer.png" />
            <span>Ingresos de robots: {{income}} USDT</span>
          </div>
          <!-- <div class="twobody">
            <img src="./assets/appczer.png" />
            <span style="color: #596379;">Llegan las ganancias</span>
          </div> -->
        </div>
        <!-- <div class="san">
          <p>Monto de ingresos:15.000000USDT，Tiempo:2024-03-1111:18:06</p>
        </div> -->
      </div>
    </van-popup>
    <!-- /邀请链接弹窗-->
    <TabBar></TabBar>
  </div>
</template>

<script>
import { startdeviceapi, startCountdownapi, myuserapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'ReceIve',
  data () {
    return {
      time: 0,
      teamobj: '',
      start: 1,
      isLoading: false,
      withstatus: 0,
      user: {},
      timer: null,
      invite_url: '',
      yqshow: false,
      syshow: false,
      income: 0,
      help_status: '', // 控制助力功能的显示隐藏
	  jjshow:'',
	  yebshow:''
    }
  },
  created () {
    this.startCountdown()
    this.myuser()
  },
  mounted () {
    console.log(2)
  },
  methods: {  
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
	tofund() {
		if(this.yebshow==1) {
			this.$router.push('/Fund')
		}else {
			Toast({
			  message: this.$t('newhome.title1'),
			  duration: 1200,
			  className: 'noticeWidth' // 所加样式名
			})
		}
	},
	toAssiSt() {
		// if(this.help_status) {
			if(this.help_status==1) {
				this.$router.push('/AssiSt')
			}else {
				Toast({
				  message: this.$t('newhome.title1'),
				  duration: 1200,
				  className: 'noticeWidth' // 所加样式名
				})
			}
		// }
	},
    // 开启设备
    async startdevice () {
      console.log(this.time)
      if (this.time <= 1) {
        const res = await startdeviceapi()
        if (res.code == 1) {
          this.income = res.data.income
          this.syshow = true
        }
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        // 开始倒计时
        this.startCountdown()
      }
    },
    // 设备倒计时
    async startCountdown () {
      const res = await startCountdownapi()
      // console.log(res, 'receive')
      this.teamobj = res.data.team
      this.withstatus = res.data.with_status
      this.help_status = res.data.help_status
      // this.help_status = 0
      // this.teamobj.forEach(i => {
      //   i.total_number = parseFloat(i.total_number).toFixed(2)
      //   i.daily_income = parseFloat(i.daily_income).toFixed(2)
      //   i.next_income = parseFloat(i.next_income).toFixed(2)
      // })
      this.time = res.data.countdown_sec * 1000
      console.log(this.time, 'time')
      // this.teamobj.total_number = parseFloat(this.teamobj.total_number).toFixed(
      //   2
      // )
      this.teamobj.daily_income = parseFloat(this.teamobj.daily_income).toFixed(2)
      this.teamobj.next_income = parseFloat(this.teamobj.next_income).toFixed(2)
      if (this.time >= 1) {
        this.start = 0
      } else {
        this.start = 1
      }
    },
    // 去帮助中心
    toHelpcenter () {
      this.$router.push('/helpcenter')
    },
    // 没有矿机的提现按钮
    nowithdraw () {
      Toast({
        message: this.$t('addrece.tsyi'),
        duration: 2000,
        className: 'noticeWidth' // 所加样式名
      })
      this.$router.push('/mining')
    },
    // 去提现
    towithdraw () {
      this.$router.push('/withdraw')
    },
    torecharge () {
      // 去充值页面
      this.$router.push('/recharges')
    },
    // 去团队页面
    toteam () {
      this.$router.push('/party')
    },
    async myuser () {
      const res = await myuserapi()
      // console.log(res, 'my')
      this.user = res.data.user
	  this.yebshow =res.data.yuebao_status
      this.user.award_profit = parseFloat(this.user.award_profit).toFixed(2)
      this.user.today_earn = parseFloat(this.user.today_earn).toFixed(2)
      this.user.profit_return = parseFloat(this.user.profit_return).toFixed(2)
      this.user.money = parseFloat(this.user.money).toFixed(2)
      this.imgsrc = res.data.user.avatar
      this.announcement = res.data.notice
      this.invite_url = res.data.user.invite_url
	  this.jjshow = res.data.yuebao_status
    },
    share (){
     setTimeout(()=>{
			 this.$copyText(this.invite_url).then()
			 Toast({
			   message: this.$t('party.Success'),
			   duration: 1200,
			   className: 'noticeWidth' // 所加样式名
			 },500)
		 })
    },
    //跳转分享
    shareto(e){
      if(e == 1){
        window.location.href = "https://www.facebook.com/"
      }
      if(e == 2){
        window.location.href = "https://twitter.com/"
      }
      if(e == 3){
        // const encodemessage = encodeURIComponent(this.invite_url)
        // alert('1111')
        const teleurl = `https://t.me/share/url?url=${encodeURIComponent(this.invite_url)}`
        window.open(teleurl)
      }
      if(e == 4){
        // alert('222')
        const whatsappurl = `https://wa.me/message/`
        window.open(whatsappurl)
        // window.location.href = "https://www.whatsapp.com/"
      }
      if(e == 5){
        window.location.href = "https://www.youtube.com/"
      }
      if(e == 6){
        window.location.href = "https://www.instagram.com/"
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Receive {
  background-color: #fff;
  // background-image: url("../assets/suoyou.png");
  // background-size: 100% 25rem;
  // background-repeat: no-repeat;
  // background-image: url("../assets/indexback.png");
  padding: 0rem 1.6rem;
  padding-bottom: 10rem;
  min-height: 100vh;
  width: 100%;
  // padding-top: 2.5rem;
  .receivetitle{
    font-size: 2rem;
    color: #333;
    font-weight: 700;
    margin-top: 2rem;
  }
  .top {
     padding-top: 2.5rem;
    position: relative;
    width: 100%;
    .info {
      display: flex;
      justify-content: center;

      .logo {
        width: 1.9rem;
        height: 2.1rem;
        margin-right: 0.5rem;
      }
      .title {
        color: #fff;
        font-size: 1.6rem;
        font-weight: 800;
      }
    }
    .customer {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.9rem;
      height: 1.9rem;
    }
  }
  .timepiece {
    width: 100%;
    // background: #15B4FB;
    background-image: url('../assets/startbk.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    height: 9.35rem;
    // border: 1px solid #50515C;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    .box {
      height: 6.05rem;
      margin-left: 3.4rem;
      display: flex;
      align-items: center;
      .start {
        width: 6.05rem;
      }
      .time {
        color: #fff;
        margin-left: 3.8rem;
        font-size: 1.3rem;
        ::v-deep .van-count-down {
          margin-top: 0.6rem;
          color: #fff;
          font-size: 2rem;
          font-weight: 800;
        }
      }
    }
  }
  .button {
    width: 100%;
    height: 4.4rem;
    background-color: #18C994;
    border-radius: 2.2rem;
    text-align: center;
    line-height: 4.4rem;
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 2rem;
  }
  .view {
    color: #333;
    margin-top: 1.5rem;
    font-weight: 700;
    .cente {
      color: #02D79A;
    }
  }
  .Team {
    font-size: 1.4rem;
    color: #333;
    font-weight: 700;
    margin-top: 1rem;
  }
  .revenueinfo {
    width: 100%;
    // background-color: #1f1f1f;
    border-radius: 1rem;
    color: #fff;
    // justify-content: space-between;
    padding: 1rem;
    // align-items: center;
    box-sizing: border-box;
    .revenue,
    .income {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem;
      // width: 33.33%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 12px 1px rgba(0,0,0,0.08);
      border-radius: 10px 10px 10px 10px;
      color: #333333;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      .green {
        font-size: 1.6rem;
        color: #15fbb5;
        font-weight: 600;
        // margin-top: 1.5rem;
      }
    }
  }
  .operate {
    width: 100%;
    // height: 9.85rem;
    // border: 1px solid #262626;
    // border-radius: 1rem;
    margin-top: 2rem;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    .Recharge {
      color: #034863;
      // width: 70%;
      // display: flex;
      text-align: center;
      font-size: 1.4rem;
      font-weight: bold;
      // margin-bottom: 1.575rem;
      // border-right: 1px solid rgba(255, 255, 255, 0.2);
      // background: linear-gradient(133deg, #51FDE0 0%, #15B4FB 100%);
      background-image: linear-gradient(to left,#51FDE0,#15B4FB);
      padding: 1rem 1rem;
      border-radius: 0.6875rem;
      // width: 16.5625rem;
      .wallet {
        // margin: 0 auto;
        width: 3.25rem;
        height: 2.85rem;
        // margin-right: 1rem;
        // margin-bottom: 1.2rem;
      }
    }
    .Withdraw {
      color: #570464;
      text-align: center;
      font-size: 1.4rem;
      font-weight: bold;
      // border-right: 0px solid rgba(255, 255, 255, 0.2);
      border-radius: 0.6875rem;
      padding: 1rem 1rem;
      // background: linear-gradient(133deg, #fdbe51 0%, #bf15fb 100%);
      background-color: #ccc;
      .wallet {
        // margin: 0 auto;
        width: 3.25rem;
        height: 2.85rem;
        // margin-bottom: 1.2rem;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 8.6rem;
    background: linear-gradient(90deg, #7450fd 0%, #40a9fe 100%);
    display: flex;
    justify-content: space-between;
    border-radius: 0.8rem;
    margin-top: 3.5rem;
    align-items: center;
    color: #fff;
    .Invite {
      width: 13rem;
      height: 4.5rem;
      margin: 0 auto;
      margin-left: 2rem;
    }
    .img {
      width: 14rem;
      height: 100%;
    }
  }
}

/* 修改的*/
.income div:nth-child(1){
  color: #333;
}
.income div:nth-child(2){
  color: #02D79A !important;
}
.rechdiv{
  display: flex;
  justify-content: center;
  align-items: center;
}
.toreceive{
  display: flex;
  align-items: center;
  .rechargeright{
    color: #034863;
    font-weight: 700;
    margin-left: 1rem;
  }
}
/* top按钮*/
.topbutton{
  margin-top: 2rem;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn{
    text-align: center;
    width: 9rem;
  }
}
.btn div{
  margin-top: 0.5rem;
}
.btn img{
  width: 3rem;
  height: 3rem;
}
.Withdrawktx{
  color: #570464;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  // border-right: 0px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.6875rem;
  padding: 1rem 1rem;
  // background: linear-gradient(133deg, #fdbe51 0%, #bf15fb 100%);
  background: linear-gradient(90deg, #FDBE51 0%, #BF15FB 100%);
  .wallet {
        // margin: 0 auto;
    width: 3.25rem;
    height: 2.85rem;
    // margin-bottom: 1.2rem;
  }
}
.Income {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F4F6F8;
  margin-top: 9rem;
  padding: 2.5rem 1rem;
  border-radius: 2.5rem;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 25%;
    // height: 7rem;
    text-align: center;
    color: #333;
    font-size: 1.6rem;
    padding: 0 0.5rem;
    padding-top: .5rem;
    // margin-top: 1rem;
    &:nth-child(3) {
      border-right: 0px solid #fff;
    }
    .title {
      color: #333;
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 0.9rem;
    }
  }
  .green {
    color: #02D79A;
    font-weight: 700;
  }
  .border {
    width: 1px;
    height: 6.5rem;
    background-color: #E1E3E5;
    // margin-top: 2.4rem;
  }
}
// 11.15修改邀请链接
.Share,
.Referral {
  display: flex;
  justify-content: space-between;
  background-color: #F4F6F8;
  color: #333;
  font-size: 1.4rem;
  padding: 1.5rem 1rem;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: .8rem;
  margin-top: 1rem;
  .right {
    display: flex;
    justify-content: space-between;
    // font-size: .8rem;
    text-align: right;
    .copyimg {
      width: 1.42rem;
      height: 1.53rem;
      margin-left: 0.5rem;
    }
    .title {
      width: 13.7rem;
      height: 2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
}
// 11.21 邀请弹窗
.yqtc{
  margin: 1.5rem;
  padding-bottom: 4rem;
  .yqlj{
    background-color: #F0F2F5;
    width: 100%;
    height: 8.5rem;
    color: #333;
    font-size: 1.2rem;
    padding: 1rem;
    font-weight: 700;
    border-radius: 1rem;
  }
  .yqbtn{
    background-color: #282522;
    text-align: center;
    color: #fff;
    font-weight: 700;
    padding: 1rem 0;
    font-size: 1.3rem;
    border-radius: 3rem;
    margin-top: 2rem;
  }
  .yqbottom{
    margin-top: 1rem;
    .one{
      text-align: center;
      color: #AEB5C4;
      font-size: 1.2rem;
    }
    .two{
      display: flex;
      align-items: center;
      margin-top: 1rem;
      .border{
        width: 100%;
        border-bottom: 2px solid #4E6694;
      }
      .img{
        width: 2.5rem;
        margin: 0 .7rem;
      }
    }
  }
}
</style>
