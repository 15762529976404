<template>
  <!-- 修改头像 -->
  <div class="Headimage">
    <div class="Headimage-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.push('/install')"
      />
      <div class="title">{{$t('Headimage.Avatar')}}</div>
      <div></div>
    </div>
       <!-- 上传图片 -->
    <van-uploader
      v-model="fileList"
      :max-count="1"
      :after-read="afterRead"
      preview-size="9rem"
      :before-delete="afetdelete"
    />
    <div class="btn" @click="btnclick">{{$t('Headimage.Upload')}}</div>
  </div>
</template>

<script>
import { Uploadpicturesapi, headimgapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'HeadImage',
  data () {
    return {
      headimg: '',
      fileList: []
    }
  },
  methods: {
    // 文件删除
    afetdelete (detail) {
      this.fileList = []
      this.url = ''
      // console.log('3232', this.fileList)
    },
    // 上传服务器
    async btnclick () {
      if (this.url) {
        const data = {
          url: this.url
        }
        const res = await headimgapi(data)
        // console.log(res)
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        setTimeout(() => {
          this.$router.push('/my')
        }, 1500)
      } else {
        Toast({
          message: this.$t('Headimage.image'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    // 图片事件
    async afterRead (file) {
      // console.log('66666', file)
      // 文件读取完成后的回调函数
      const formData = new FormData()
      formData.append('file', file.file)
      // console.log(formData.get('file'))
      this.files = file.file
      const res = await Uploadpicturesapi(formData)
      // console.log(res)
      this.url = res.data.fullurl
    }
  }
}
</script>

<style lang="less" scoped>
.Headimage {
  background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  background-size: cover;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Headimage-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9.5rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
   .btn {
    width: 21rem;
    height: 4.4rem;
    background-color: #5979FB;
    margin: 0 auto;
    border-radius: 2.2rem;
    text-align: center;
    line-height: 4.4rem;
    font-size: 1.4rem;
    color: #fff;
    font-weight: 600;
    margin-top: 6rem;
  }
    ::v-deep .van-uploader__upload {
    margin-left: 13rem !important;
    margin-top: 2rem;
    border-radius: 50%;
  }
  ::v-deep .van-uploader__preview {
    margin-left: 13rem !important;
    margin-top: 2rem;
    border-radius: 50%;
  }
  ::v-deep .van-image__img {
    border-radius: 50%;
  }
  ::v-deep .van-uploader__preview-delete-icon {
  font-size: 3rem;
  }
}
</style>
