<template>
  <!-- 购买矿机 -->
  <div class="Mining">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="title">{{ $t("Mining.title") }}</div> 
    <van-tabs
      v-model="active"
      color="#111F5A"
      title-active-color="#111F5A"
      title-inactive-color="#fff"
      background="#CA91FF"
      @change="changetab"
    >
      <van-tab :title="Advanced">
        <div class="Content" v-for="(item, index) in typelist2" :key="index">
          <div class="top">
            <div class="img">
              <img :src="item.pic" alt="" />
            </div>
            <div class="Content-title">
              <div>{{ item.title }}</div>
              <div class="green">{{ item.status_text }}</div>
            </div>
            <div class="number">
              {{ $t("Mining.purchases") }} : {{ item.member_can_buy }}
            </div>
          </div>
          <!-- <div class="Content-info">
            <div class="left">{{ $t("Mining.Computer") }}</div>
            <div class="right">{{ item.power_range }}</div>
          </div> -->
          <div class="Content-info">
            <div class="left">{{ $t("Mining.Purchasing") }}</div>
            <div class="right">{{ item.show_price }}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("Mining.Daily") }}</div>
            <div class="right">{{ item.show_daily_income }}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("Mining.Valid") }}</div>
            <div class="right">{{ item.days }} {{ $t("Mining.Days") }}</div>
          </div>
          <div class="Content-info" >
            <div class="left">{{$t('addmin.content')}}</div>
            <div class="right">{{ item.rate }}</div>
          </div>
          <div class="tips">{{ item.remark }}</div>
          <div class="bottom">
            <div class="left" @click="torecharge(item.id)">
              {{ $t("Mining.Recharge") }}
            </div>
            <div class="right" @click="showcell(item.id)">
              {{ $t("Mining.purchase") }}
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="Experience">
        <div class="Content" v-for="(item, index) in typelist1" :key="index">
          <div class="top">
            <div class="img">
              <img :src="item.pic" alt="" />
            </div>
            <div class="Content-title">
              <div>{{ item.title }}</div>
              <div class="green">{{ item.status_text }}</div>
            </div>
            <div class="number">
              {{ $t("Mining.purchases") }} : {{ item.member_can_buy }}
            </div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("Mining.Purchasing") }}</div>
            <div class="right">{{ item.show_price }}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("Mining.Daily") }}</div>
            <div class="right">{{ item.show_daily_income }}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("Mining.Valid") }}</div>
            <div class="right">{{ item.days }} {{ $t("Mining.Days") }}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{$t('addmin.content')}}</div>
            <div class="right">{{ item.rate }}</div>
          </div>
          <div class="tips">{{ item.remark }}</div>
          <div class="bottom">
            <div class="left" @click="torecharge(item.id)">
              {{ $t("Mining.Recharge") }}
            </div>
            <div class="right" @click="showcell(item.id)">
              {{ $t("Mining.purchase") }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model="show" @select="onSelect">
      <div class="boxcontent">
        <div class="title">{{ $t("Mining.Experience") }}</div>
        <div class="power">
          <div class="Price">{{ $t("Mining.Price") }}</div>
          <div>{{ list.show_price }}</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.profit") }}</div>
          <div>{{ list.daily_output }} USDT</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.Start") }}</div>
          <div>{{ list.start }}</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.End") }}</div>
          <div>{{ list.end }}</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.gmsl") }}</div>
          <div style="display: flex;align-items: center;">
            <span class="addnum" @click="jiannum">-</span>
            {{add_num}}
            <span class="addnum" @click="addnum" style="margin-right: 0;" >+</span>
          </div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.yjsy") }}</div>
          <div>
            {{parseFloat(list.daily_output).toFixed(2)*list.days*add_num+parseFloat(list.price).toFixed(2)*add_num}} USDT
          </div>
        </div>
        <div class="tishi">
          {{$t('addmin.tcyi')}}: {{list.daily_output}} * {{list.days}} * {{add_num}} + {{list.price}}*{{add_num}}
        </div>
        <div class="agreement">
          <van-checkbox
            icon-size="2rem"
            v-model="checked"
            shape="square"
          ></van-checkbox>
          <div class="Agree">
            {{ $t("Mining.Agree") }} 《{{ $t("Mining.Escrow") }}》
          </div>
        </div>
        <div class="boxinfo" ref="boxinfo">
        </div>
        <div class="btn" @click="Purchase">{{ $t("Mining.Confirm") }}</div>
      </div>
    </van-action-sheet>
    </div>
    </van-pull-refresh>
    <TabBar></TabBar>
  </div>
</template>

<script>
import { Miningmachineapi, Productpurchaseapi, DeviceHitsapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'MinIng',
  data () {
    return {
      active: 0,
      show: false,
      Miningmachinelist: [],
      checked: true,
      list: {},
      id: 0,
      typelist1: [],
      typelist2: [],
      Experience: '',
      Advanced: '',
      isLoading: false,
      add_num: 1
    }
  },
  created () {
    this.Miningmachine()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 购买矿机
    async Miningmachine () {
      const res = await Miningmachineapi()
      // console.log('666', res)
      this.Experience = res.data.product_type[0].category
      this.Advanced = res.data.product_type[1].category
      this.Miningmachinelist = res.data.product_list

      this.Miningmachinelist.forEach((i) => {
        if (i.type === 1) {
          this.typelist1.push(i)
        }
      })
      this.Miningmachinelist.forEach((i) => {
        if (i.type === 2) {
          this.typelist2.push(i)
        }
      })
    },
    async showcell (id) {
      this.checked = true
      this.id = id
      this.show = true
      this.Miningmachinelist.forEach(async (i) => {
        if (i.id === id) {
          this.list = await i
          // console.log('6666', this.Miningmachinelist[v])
          this.$refs.boxinfo.innerHTML = await this.list.detail
        }
      })
      const data = {
        pro_id: id
      }
      await DeviceHitsapi(data)
    },
    // 购买数量
    addnum (){ // +
      // console.log(this.list.can_buy)
      if(this.list.can_buy == 0 || this.add_num >= this.list.can_buy){
        Toast({
          message: this.$t('addmin.tsyi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      this.add_num++
    },
    jiannum() { // -
      if(this.add_num == 0){
        Toast({
          message: this.$t('addmin.tser'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      this.add_num--
    },
    changetab (){
      this.add_num = 1
    },
    // 产品购买
    async Purchase () {
      if(this.list.can_buy == 0){
        Toast({
          message: this.$t('addmin.tssan'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      if(this.add_num == 0){
        Toast({
          message: this.$t('addmin.tssi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      if (this.checked) {
        const data = {
          pro_id: this.id,
          buy_num: this.add_num
        }
        const res = await Productpurchaseapi(data)
        // console.log('0123', res)
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.show = false
        this.checked = false
        this.typelist1 = []
        this.typelist2 = []
        // 购买之后重新获取数据
        this.Miningmachine()
      } else {
        Toast({
          message: this.$t('Mining.first'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    onSelect (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false
      this.checked = true
      //   Toast(item.name)
    },
   async torecharge (id) {
		const data = {
		  pro_id: id
		}
		await DeviceHitsapi(data)
      // 去充值页面
      this.$router.push('/recharges')
    }
  }
}
</script>

<style lang="less" scoped>
.Mining {
  background-image: linear-gradient(to bottom,#DFA3FF,#5687FD);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  padding-bottom: 8.8rem;
  width: 100%;
  min-height: 100vh;
  .title {
     padding-top: 2.5rem;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 2.5rem;
  }
  ::v-deep .van-tabs__wrap{
    border-radius: 3rem;
  }
  ::v-deep .van-tab {
    line-height: 2rem;
  }
  ::v-deep .van-tab__text--ellipsis {
    font-size: 1.6rem;
  }
  ::v-deep .van-tabs__line {
    width: 4rem;
    height: 0.2rem;
  }
  .Content {
    width: 100%;
    // height: 39.1rem;
    background-color: #fff;
    margin-top: 2rem;
    border-radius: 1rem;
    padding-bottom: 1.55rem;
    .top {
      width: 100%;
      position: relative;
      height: 13.25rem;
      border-radius: 1rem 1rem 0rem 0rem;
      .img {
        width: 100%;
        height: 13.25rem;
        img {
          width: 100%;
          height: 13.25rem;
        }
      }
      .Content-title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        color: #fff;
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        font-size: 1.3rem;
        padding: 0rem 1.3rem;
        background-color: #364997;
        opacity: 0.7;
        // border-top-left-radius: 1rem;
        // border-top-right-radius: 1rem;
        .green {
          right: 0;
          color: #15fbb5;
        }
      }
      .number {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        color: #fc9900;
        //  font-weight: 600;
        font-size: 1.3rem;
      }
    }
    .Content-info {
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding: .5rem 0rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0rem 1.3rem;
      font-size: 1.2rem;
      .left {
        color: #818182;
      }
      .right{
        color: #333;
        font-weight: 700;
      }
    }
    .tips {
      color: #fb8815;
      font-size: 1.1rem;
      margin-left: 1.3rem;
      // margin-top: 1rem;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin: 0rem 1.3rem;
      margin-top: .75rem;
      .left,
      .right {
        width: 14.9rem;
        height: 4.4rem;
        background-color: #5979FB;
        border-radius: 0.7rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .right {
        background-color: #fb8815;
      }
    }
  }
  ::v-deep .van-action-sheet {
    background-color: #fff;
    // padding: 0rem 1.6rem;
    .boxcontent {
      width: 34.3rem;
      margin: 0 auto;
      .title {
        margin-top: 2rem;
        color: #333;
      }
      .power {
        padding: .7rem 0rem;
        display: flex;
        justify-content: space-between;
        color: #333;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 1.2rem;
        .Price {
          font-size: 1.4rem;
          color: #333;
        }
      }
      .agreement {
        display: flex;
        color: #333;
        font-size: 1.4rem;
        margin-top: 1.9rem;
        .Agree {
          margin-left: 1.3rem;
        }
      }
      .boxinfo {
        width: 90%;
        color: #333;
        font-size: 1.4rem;
        margin-top: 3rem;
        margin-bottom: 7rem;
        p {
          // margin: 1.5rem 0rem;
          span {
            background-color: #1f1f1f !important;
          }
        }
      }

      .btn {
        position: fixed;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 34.3rem;
        height: 4.4rem;
        background-color: #5979FB;
        margin: 0 auto;
        // margin-top: 5rem;
        border-radius: 2.75rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        // margin-bottom: 5rem;
      }
    }
  }
}
.addnum{
  font-size: 2rem;
  font-weight: 700;
  color: #5979FB;
  margin: 0 1.5rem;
}
.tishi{
  font-size: 1rem;
  font-weight: 700;
}
</style>
