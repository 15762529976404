import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {
	Toast,
	Dialog
} from 'vant'
import i18n from '@/i18n'

//接口配置

var instance = axios.create({
	baseURL: (localStorage['CurrLine'] || ApiUrl) + '/',
	timeout: 1000 * 12, // 创建axios实例,设定超时时间是12s
	// withCredentials: true,
	// headers: {
	// 	'Content-Type': 'application/x-www-form-urlencoded',
	// }
})


/*取消请求*/
var source = axios.CancelToken.source();

// 添加请求拦截器
instance.interceptors.request.use(
	config => {

		// 在发送请求之前做些什么
		config.baseURL = (localStorage['CurrLine'] || ApiUrl) + '/';

		config.cancelToken = source.token;
		config.data = config.data || {};
		config.data.lang = localStorage['Language'] || 'en';


		//    config.data.lang = localStorage['Language']|| Language;
		if (!config.noLogin) {
			config.data.token = localStorage['Token'];
		}
		if (!config.fromData) {
			config.data = $.param(config.data);
		}
		return config;
	},
	error => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
)

// 添加响应拦截器
instance.interceptors.response.use(
	response => {
		// 对响应数据做点什么
		var Api = response.request.responseURL.slice(response.request.responseURL.lastIndexOf('\/'));
		// console.log('拦截器',response);
		if (response.data.result > 200) {
			localStorage.removeItem('Token');
      localStorage.removeItem('is_email');
			localStorage.removeItem('UserInfo');
			localStorage.removeItem('BankCardList');
			store.dispatch('UpdateUserInfo', '');
			store.dispatch('UpdateBankCardList', []);
			Toast.clear();
			source.cancel();
			Dialog.alert({
				title: i18n.t('dialog[0]'),
				message: response.data.info,
				closeOnPopstate: true,
				confirmButtonText: i18n.t('dialog[1]'),
			}).then(() => {
				router.push('/login');
				source = axios.CancelToken.source();
			}).catch(() => {
				router.push('/login');
				source = axios.CancelToken.source();
			});
		}
		// console.log('拦截器',response);
		// if (error.response.data.code === 401) {
		//       let res = ''
		//       if (localStorage.getItem('tink_var') === 'pt-br') {
		//         res = 'As informações de login expiraram, faça o login novamente'
		//       } else {
		//         res = 'As informações de login expiraram, faça o login novamente'
		//       }
		//       Toast({
		//         message: res,
		//         className: 'noticeWidth' // 所加样式名
		//       })
		//       localStorage.removeItem('token')
		//       sessionStorage.removeItem('show')
		//       sessionStorage.removeItem('gonggaoshow')
		//       setTimeout(() => {
		//         router.push('/login')
		//       }, 1000)
		//     }
		
		return response;
	},
	error => {
		console.log('拦截器',error.response.data)
         if(error.response.data.code==401) {
			 Toast({
			 	message: error.response.data.msg
			 });
			 router.push('/login');
		 }
		// 对响应错误做点什么
		const err = /<h1>(.*?)<\/h1>/ig.exec(error.response.data)[1];
		Toast({
			message: err
		});
		// const config = error.config;
		// if (!config || !config.retry) return Promise.reject(error);
		// config.__retryCount = config.__retryCount || 0;
		// if (config.__retryCount >= config.retry) {
		// 	return Promise.reject(error);
		// }
		// config.__retryCount += 1;
		// const backoff = new Promise(function(resolve) {
		// 	setTimeout(function() {
		// 		resolve();
		// 	}, 2000);
		// });
		// return backoff.then(function() {
		// 	return axios(config);
		// });
	}
)

export default instance