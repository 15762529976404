import { Dialog, Toast} from 'vant';
import i18n from '@/i18n'

const dialog = {
    Confirm(msg,event,btnText,title) {
        Dialog.confirm({
            title: title||i18n.t('dialog[0]'),
            message: msg,
			cancelButtonText:i18n.t('dialog[2]'),
            confirmButtonText: btnText||i18n.t('dialog[1]'),
            closeOnPopstate: true,
            
        }).then(event).catch(()=>{});
    },
    Alert2(msg,event,btnText,title) {
        Dialog.confirm({
            title: title||i18n.t('dialog[0]'),
            message: msg,
            confirmButtonText: btnText||i18n.t('dialog[1]'),
            cancelButtonText:i18n.t('dialog[2]'),
            closeOnPopstate: true,
            showCancelButton:true
        }).then(event).catch(event);
        
    },
    Alert(msg,event,btn) {
        Dialog.alert({
            title: i18n.t('dialog[0]'),
            message: msg,
            confirmButtonText: btn||i18n.t('dialog[1]'),
            closeOnPopstate: true,
            closeOnClickOverlay: true,
             showCancelButton:true
        }).then(event).catch(()=>{});
    },
    Toast(msg,position) {
        Toast({
            message: msg,
            position: position||'bottom'
        });
    },
    Loading(msg,el) {
        Toast.loading({
            message: msg||i18n.t('dialog[2]'),
            duration: 0,
            forbidClick: true,
            getContainer: el||'body'
        });
    },
    Close() {
        Toast.clear();
    },
}

export default dialog