<template>
	<!-- 首页 -->
	<!-- <div class="Homebg"> -->
	<div class="homevue">
		<!-- <div class="top">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="title">Xiao-i Robot</div>
      <div class="signIn" @click="tosignin">
        <img src="../assets/sign.png" alt="" />
      </div>
    </div> -->
		<div class="top">
			<div class="topone">
				<div>{{ $t('home.top') }}</div>
				<div class="toponeright">
					<img src="../assets/yuyannew.png" @click="rechangelang" />
					<img src="../assets/indextop.png" @click="appxz" />
					<img src="../assets/indextop2.png" @click="$router.push('/announcement')" />
					<img src="../assets/indextop3.png" @click="tokefu" style="margin-right: 0;" />
				</div>
			</div>
			<div class="toptwo">
				{{username}}
			</div>
		</div>
		<div class="Broadcast">
			<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="(i, v) in bannerlist" :key="v" @click="togift(i.href)">
					<img class="hornimg" :src="i.url" alt="" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="infos" @click="toAnnouncement">
			<div class="megaphone">
				<img src="../assets/horn.png" alt="" />
			</div>
			<div class="gonggao">
				<marquee class="announcement" style="margin: 0.4rem 0.625rem">
					<span style="
                  font-size: 1.4rem;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #000000;
                ">{{ announcement }}</span>
				</marquee>
			</div>
			<div class="arrow">
				<img src="../assets/arrowheads.png" alt="" />
			</div>
		</div>
		<!-- <div class="usdt">
      <div class="usdtleft">
        <img src="../assets/indexlin.png" />
        <div>Latest News</div>
      </div>
      <div class="ustright">
        <div>+{{coin}}&nbsp;</div>
        <div>usdt</div>
      </div>
    </div> -->
		<!-- <div class="content">
      <div class="time">
        <div class="time-DAY">
          <div class="time-title">{{ $t("home.timeday") }}</div>
          <div>{{ day }}</div>
        </div>
        <div class="time-MIN">
          <div class="time-title">{{ $t("home.timehour") }}</div>
          <div>{{ shi }}</div>
        </div>
        <div class="time-HOUR">
          <div class="time-title">{{ $t("home.timemin") }}</div>
          <div>{{ fen }}</div>
        </div>
        <div class="time-SEC">
          <div class="time-title">{{ $t("home.timesec") }}</div>
          <div>{{ miao }}</div>
        </div>
      </div>
    </div> -->
		<!-- 11.14修改one-->
		<div class="inbody">
			<div class="bodyone">
				<div class="bodyonediv" @click="$router.push('/companycetails')">
					<img src="../assets/inbody.png" />
					<div>{{$t('addhome.inbodyyi')}}</div>
				</div>
				<div class="bodyonediv" @click="$router.push('/recharges')">
					<img src="../assets/inbody2.png" />
					<div>{{$t('addhome.inbodyer')}}</div>
				</div>
				<div class="bodyonediv" @click="towithdraw">
					<img src="../assets/inbody3.png" />
					<div>{{$t('addhome.inbodysan')}}</div>
				</div>
				<!-- <div class="bodyonediv" v-if="dwstatus == 0" @click="torecharge">
          <img src="../assets/inbody3er.png" />
          <div>Withdrawal</div>
        </div>  -->
			</div>
			<div class="bodyone" style="margin-top: 1rem;">
				<div class="bodyonediv" style="margin-top: 1rem;">
					<img src="../assets/inbody4.png" @click="$router.push('/receive')" />
					<div>{{$t('addhome.inbodysi')}}</div>
				</div>
				<div class="bodyonediv" @click="$router.push('/party')">
					<img src="../assets/inbody5.png" />
					<div>{{$t('addhome.inbodywu')}}</div>
				</div>
				<div class="bodyonediv" @click="$router.push('/Activity')">
					<img src="../assets/inbody6.png" />
					<div>{{$t('addhome.rw')}}</div>
				</div>
			</div>
		</div>
		<!-- 11.14修改My wallet-->
		<div class="wallet">
			<div class="wallettitle">{{$t('addhome.wallet')}}</div>
			<div class="walletbody">
				<div>
					<span class="wallzione">{{$t('addhome.walletyi')}}: </span><span
						class="wallzitwo">{{parseFloat(walletarr.total_balance).toFixed(2)}} USDT</span>
				</div>
				<div class="walletmargin">
					<span class="wallzione">{{$t('addhome.walleter')}}: </span><span
						class="wallzitwo">{{parseFloat(money).toFixed(2)}} USDT</span>
				</div>
				<div class="walletmargin">
					<span class="wallzione">{{$t('addhome.walletsan')}}: </span><span
						class="wallzitwo">{{parseFloat(award_profit).toFixed(2)}} USDT</span>
				</div>
			</div>
			<div class="walletbody" style="margin-top: 2rem;">
				<div>
					<span class="wallzione">{{$t('addhome.walletsi')}}: </span><span
						class="wallzitwo">{{parseFloat(today_earn).toFixed(2)}} USDT</span>
				</div>
				<div class="walletmargin">
					<span class="wallzione">{{$t('addhome.walletwu')}}: </span><span
						class="wallzitwo">{{parseFloat(walletarr.coin).toFixed(2)}} AMP</span>
				</div>
				<div class="walletmargin">
					<span class="wallzione">{{$t('addhome.walletliu')}}: </span><span
						class="wallzitwo">{{parseFloat(walletarr.coin_money).toFixed(2)}} USDT</span>
				</div>
			</div>
		</div>
		<!-- 11.14修改插图-->
		<div class="ctimg" @click="$router.push('/Activity')">
			<img src="../assets/indexhbsc.png" />
		</div>
		<!-- 抽奖-->
		<div class="indexcj">
			<img src="../assets/indexcj.png" @click="tocj()" />
			<!-- <div class="indexbx" v-if="turntable == 1" @click="$router.push('/turntable')" >
        <img src="../assets/cjX.png" class="cjX" @click="turntable = 0" />
        <img src="../assets/homebx.gif" class="bx"  />
      </div> -->
		</div>
		<div class="indexbody">
			<div class="currencies">{{ $t("home.currencies") }}</div>
			<div v-if="btclist.length>0" class="bibody">
				<!-- 第一种币 -->
				<div class="Bitcoin" @click="todiagram(btclist[0].symbol, btclist[0].rate, btclist[0].zf)">
					<div class="Bitcoin-left">
						<div class="Bitcoin-img">
							<img src="../assets/coin1.png" alt="" />
						</div>
						<div class="Bitcoin-name">
							<div style="font-size: 1.4rem;font-weight: bold;">
								{{ btclist[0].name }}
							</div>
							<div style="font-size: 1rem;color: #7C7D81;">
								{{ btclist[0].category }}
							</div>
						</div>
					</div>
					<div class="Bitcoin-right">
						<div class="Price">$ {{ btclist[0].price }}</div>
						<!-- <div :class="{ red: btclist[0].rate.indexOf('-') !== -1 }" class="increase">
              {{
                parseFloat(btclist[0].rate) > 0
                ? `+${btclist[0].rate}` grend
                : btclist[0].rate
              }}
            </div> -->
						<div v-if="btclist[0].zf < 0" :class="{ 'red' : btclist[0].zf < 0 }">- {{btclist[0].rate}}</div>
						<div v-if="btclist[0].zf == 0">{{btclist[0].rate}}</div>
						<div v-if="btclist[0].zf > 0" :class="{ 'grend' : btclist[0].zf > 0 }">+ {{btclist[0].rate}}
						</div>
					</div>
				</div>
				<div class="indexbodyx"></div>
				<!-- 第二种币 -->
				<div class="Bitcoin1" @click="todiagram(btclist[1].symbol, btclist[1].rate, btclist[1].zf)">
					<div class="Bitcoin-left">
						<div class="Bitcoin-img">
							<img src="../assets/coin2.png" alt="" />
						</div>
						<div class="Bitcoin-name">
							<div style="font-size: 1.4rem;font-weight: bold;">
								{{ btclist[1].name }}
							</div>
							<div style="font-size: 1rem;color: #7C7D81;">
								{{ btclist[1].category }}
							</div>
						</div>
					</div>
					<div class="Bitcoin-right">
						<div class="Price">$ {{ btclist[1].price }}</div>
						<!-- <div :class="{ red: btclist[1].rate.indexOf('-') !== -1 }" class="increase">
              {{
                parseFloat(btclist[1].rate) > 0
                ? `+${btclist[1].rate}`
                : btclist[1].rate
              }}
            </div> -->
						<div v-if="btclist[1].zf < 0" :class="{ 'red' : btclist[1].zf < 0 }">- {{btclist[1].rate}}</div>
						<div v-if="btclist[1].zf == 0">{{btclist[1].rate}}</div>
						<div v-if="btclist[1].zf > 0" :class="{ 'grend' : btclist[1].zf > 0 }">+ {{btclist[1].rate}}
						</div>
					</div>
				</div>
				<div class="indexbodyx"></div>
				<!-- 第三种币 -->
				<div class="Bitcoin2" @click="todiagram(btclist[2].symbol, btclist[2].rate, btclist[2].zf)">
					<div class="Bitcoin-left">
						<div class="Bitcoin-img">
							<img src="../assets/coin3.png" alt="" />
						</div>
						<div class="Bitcoin-name">
							<div style="font-size: 1.4rem;font-weight: bold;">
								{{ btclist[2].name }}
							</div>
							<div style="font-size: 1rem;color: #7C7D81;">
								{{ btclist[2].category }}
							</div>
						</div>
					</div>
					<div class="Bitcoin-right">
						<div class="Price">$ {{ btclist[2].price }}</div>
						<!-- <div :class="{ red: btclist[2].rate.indexOf('-') !== -1 }" class="increase">
              {{
                parseFloat(btclist[2].rate) > 0
                ? `+${btclist[2].rate}`
                : btclist[2].rate
              }}
            </div> -->
						<div v-if="btclist[2].zf < 0" :class="{ 'red' : btclist[2].zf < 0 }">- {{btclist[2].rate}}</div>
						<div v-if="btclist[2].zf == 0">{{btclist[2].rate}}</div>
						<div v-if="btclist[2].zf > 0" :class="{ 'grend' : btclist[2].zf > 0 }">+ {{btclist[2].rate}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 11.14修改 Help-->
		<div class="help">
			<div class="helptitle">{{$t('addhome.help')}}</div>
			<div class="helpbody">
				<div class="helpbodyone" v-for="(item,index) in bangzhulist" :key="index" @click="tohelp(item.id)">
					<div>{{item.title}}</div>
					<img src="../assets/helpright.png" />
				</div>
				<!-- <div class="helpbodyone">
          <div>Company Profile</div>
          <img src="../assets/helpright.png" />
        </div>
        <div class="helpbodyone">
          <div>Company Profile</div>
          <img src="../assets/helpright.png" />
        </div>
        <div class="helpbodyone" style="border-bottom: none;">
          <div>Company Profile</div>
          <img src="../assets/helpright.png" />
        </div> -->
			</div>
		</div>
		<!-- 11.14修改bottom-->
		<div class="bottomone">
			<span style="font-weight: 800;">{{$t('addhome.bottomamp')}}</span>{{$t('addhome.bottom')}}<br />
			<span style="font-weight: 800;">{{$t('addhome.bottomamp')}}</span>{{$t('addhome.bottomer')}}
		</div>
		<!-- 11.14修改bottomer-->
		<div class="bottomer">
			<div class="left" style="margin-right: 3rem;">
				<div>{{$t('addhome.bottomtwo')}}</div>
				<div>{{day}} {{$t('addhome.bottomtwoday')}}</div>
			</div>
			<div class="left">
				<div>{{$t('addhome.bottomtwoer')}}</div>
				<div>{{total_revenue}} USDT</div>
			</div>
		</div>
		<!-- 11.20合作平台-->
		<div class="hezuo">
			<div class="hztitle">{{$t('addhome.hezuotitle')}}</div>
			<div class="hzbody">
				<div class="bodyflex">
					<div calss="one">
						<div class="onediv">1.
							<img src="../assets/hz1.png" />{{$t('addhome.hezuoyi')}}
						</div>
						<div class="onediv">3.
							<img src="../assets/hz2.png" />{{$t('addhome.hezuoer')}}
						</div>
						<div class="onediv">5.
							<img src="../assets/hz4.png" />{{$t('addhome.hezuosi')}}
						</div>
						<div class="onediv">7.
							<img src="../assets/hz6.png" />{{$t('addhome.hezuosi')}}
						</div>
						<div class="onediv">9.
							<img src="../assets/hz8.png" />{{$t('addhome.hezuowu')}}
						</div>
					</div>
					<div calss="one">
						<div class="onediv" style="margin-left: 0.8rem;">2.
							<img src="../assets/hz10.png" />{{$t('addhome.hezuoliu')}}
						</div>
						<div class="onediv" style="margin-left: 0.8rem;">4.
							<img src="../assets/hz3.png" />{{$t('addhome.hezuoqi')}}
						</div>
						<div class="onediv" style="margin-left: 0.8rem;">6.
							<img src="../assets/hz5.png" />{{$t('addhome.hezuoba')}}
						</div>
						<div class="onediv" style="margin-left: 0.8rem;">8.
							<img src="../assets/hz7.png" />{{$t('addhome.hezuojiu')}}
						</div>
						<div class="onediv">10.
							<img src="../assets/hz9.png" />{{$t('addhome.hezuoshi')}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 弹出框 -->
		<van-popup round v-model="show"> <!-- @click-overlay="gonggaoclcik"-->
			<div class="SignIn-content">
				<div class="content-box">
					<div class="box" :class="{ bgcolor: bgcolor >= 1 }">
						<div class="day">{{ $t("home.SignIn1") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 2 }">
						<div class="day">{{ $t("home.SignIn2") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 3 }">
						<div class="day">{{ $t("home.SignIn3") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 4 }">
						<div class="day">{{ $t("home.SignIn4") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 5 }">
						<div class="day">{{ $t("home.SignIn5") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 6 }">
						<div class="day">{{ $t("home.SignIn6") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>

					<div class="Seven" :class="{ bgSeven: bgcolor >= 7 }"
						style="padding: 0.5rem;align-items: center;padding-left: 1rem;">
						<div class="info" style="text-align: center;">
							<div class="tian">
								<div class="day" style="width: 100%;margin-bottom: 1rem;">{{ $t("SignIn.SignIn7") }}
								</div>
								<!-- <div style="font-size: 1rem">Mysterious package</div> -->
							</div>
							<div class="title" style="margin-top: 0;">
								{{$t('addhome.tcyi')}}<br />{{$t('addhome.tcer')}}
							</div>
						</div>
						<div class="img">
							<img src="../assets/gift.png" alt="" />
						</div>
					</div>
				</div>
				<div class="button">
					<button class="btn" @click="receive">{{ chick }}</button>
				</div>
			</div>
		</van-popup>
		<van-popup round v-model="gonggaoshow">
			<div class="gonggaobox">
				<img class="gonggao" src="../assets/gonggao.png" alt="" />
				<div class="boxtitle">{{$t('addhome.tcsan')}}</div>
				<div class="info">
					<!-- <div class="neirong" v-for="(v, i) in gonggaolist" :key="i" @click="togaonggao">
            <div class="neirongtitle">{{ v.title }}</div>
            <div class="right">
              <img src="../assets/RightArrow.png" alt="" />
            </div>
          </div> -->
					<div ref="detils" @click="todetils"></div>
				</div>
			</div>
		</van-popup>
		<!-- 公告图片放大-->
		<van-overlay :show="bigimgshow" @click="bigimgshow = false" z-index="2024">
			<div class="wrapper" @click.stop>
				<img :src="bigimg" />
			</div>
		</van-overlay>

		<van-popup v-model="newshow" position="bottom" :style="{ height: '30%' }">

			<!-- <div v-for="(i,v) in codelist" :key="v">{{i.code}}</div> -->
			<van-picker title="" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"
				@change="onChange" confirm-button-text='Confirmar' cancel-button-text='Cancelar' />
		</van-popup>

		<!-- 气泡拖拽-->
		<BaoXiang v-if="turntable == 1" :turn_table="turntable" @changecjx="cjx"></BaoXiang>
		<!-- /气泡拖拽-->
		<suspended v-if="telegram || whatsapp"></suspended>
		<TabBar></TabBar>
	</div>
	<!-- </div> -->
</template>

<script>
	import {
		getreceive,
		getSignin,
		gethomeapi,
		Noticelistapi,
		myuserapi,
		assistdetailsapi,
		getlanguage
	} from '@/api/index'
	// import { gethomeapi } from '@/api/index'
	import {
		Toast
	} from 'vant'
	import Suspended from '../components/Suspended.vue'
	import BaoXiang from '../components/BaoXiang.vue'
	export default {
		name: 'HoMe',
		components: {
			Suspended,
			BaoXiang
		},
		data() {
			return {
				columns: [],
				newshow: false,
				gonggaoshow: false,
				show: false,
				bgcolor: 0,
				bannerlist: [{
					id: 77,
					thumb_style: '',
					url: '/uploads/20230807/d5337373d4f168822352fec6b6c9fe79.png'
				}],
				announcement: '',
				btclist: [],
				username: '', // 用户名
				coin: '',
				time: 0,
				day: 0,
				shi: 0,
				fen: 0,
				miao: 0,
				times: null,
				chick: this.$t('SignIn.Sign'),
				float: 1,
				telegram: '',
				whatsapp: '',
				isLoading: false,
				// bfb: '-0.01%',
				statustype: 0,
				gonggaolist: [],
				detils: '', // 公告具体内容
				detilsid: 0, // 公告id
				turntable: 0,
				dwstatus: 0, // 提款状态
				walletarr: {}, // 我的内容
				profit_return: 0,
				award_profit: 0,
				today_earn: 0,
				money: 0,
				rate: 0,
				bangzhulist: [], // 帮助列表
				kfurl: '',
				total_revenue: '',
				offset: {
					x: 200,
					y: 400
				},
				download: {},
				sigintype: true,
				bigimgshow: false, // 公告图片放大
				bigimg: '' // 公告图片
			}
		},
		created() {
			this.getlanguage()
			// 判断是否绑定邮箱
			// console.log(localStorage.getItem('is_email'))
			// if (localStorage.getItem('is_email') == 0) { // eslint-disable-line
			//   console.log(111)
			//   // this.$router.push('/MailBox')
			// }else{

			// }
			// 首页数据
			this.homelist()
			// 签到天数接口
			this.Signin()
			// // 公告
			// this.Noticelistapi()
			if (parseFloat(this.statustype) === 0) {
				if (sessionStorage.getItem('show')) {
					this.show = false
				} else {
					this.show = true
					sessionStorage.setItem('show', 1)
				}
			}
			// this.gonggaoshow = true // 公告显示
			// if (!this.show) {
			//   公告弹窗
			//   if (sessionStorage.getItem('gonggaoshow')) {
			//     this.gonggaoshow = false
			//   } else {
			//     this.gonggaoshow = true
			//     sessionStorage.setItem('gonggaoshow', 1)
			//   }
			// }
			this.getmyuserapi() // 获取我的
			this.assistdetails() // 获取帮助
		},
		destroyed() {
			// clearInterval(this.times)
		},
		methods: {
			async getlanguage() {
				const res = await getlanguage()
				res.data.forEach((i)=>{
					this.columns.push(i.name)
				})
			},
			onConfirm(e) {
				console.log(e,'666');
				if(e=='English') {
					localStorage.setItem('newlang','en')
				}
				if(e=='Español') {
					localStorage.setItem('newlang','es')
				}
				localStorage.setItem('lang',e)
				
				this.$i18n.locale = e
				this.newshow = false
			},
			onCancel() {
				this.newshow = false
			},
			onChange() {

			},
			rechangelang() {
               this.newshow = true
			},
			// gonggaoclcik () {
			// console.log(111)
			// 公告弹窗
			//  this.gonggaoshow = true
			//  sessionStorage.setItem('gonggaoshow', 1)
			// },
			togaonggao() {
				this.$router.push('/Announcement')
			},
			// // 公告
			async Noticelistapi() {
				const data = {
					type: '2'
				}
				const res = await Noticelistapi(data)
				console.log(res, '公告')
				this.detils = res.data.content
				this.detilsid = res.data.id
				this.$refs.detils.innerHTML = this.detils
				// this.gonggaolist = res.data
				// this.gonggaolist = this.gonggaolist.slice(0, 2)
			},
			onRefresh() {
				setTimeout(() => {
					this.$router.go(0)
				}, 1000)
			},
			// 去抽奖
			tocj() {
				if (this.turntable == 0) {
					Toast({
						message: this.$t('newhome.title'),
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
					return false
				}
				this.$router.push('/turntable')
			},
			// 去活动页面
			togift(href) {
				this.$router.push(href)
			},
			// 去官网
			toguanwang() {
				// console.log(111)
			},
			async homelist() {
				const res = await gethomeapi()
				console.log(res, 'home')
				this.username = res.data.nickname
				this.turntable = res.data.turntable
				this.coin = res.data.coin
				this.bannerlist = res.data.banner
				this.announcement = res.data.announcement
				this.btclist = res.data.symbol
				this.time = res.data.found_time
				this.telegram = res.data.telegram
				this.whatsapp = res.data.whatsapp
				this.dwstatus = res.data.dw_status
				this.kfurl = res.data.service_link
				this.total_revenue = res.data.total_revenue
				if (parseInt(res.data.notice) === 1) {
					// 公告
					this.Noticelistapi()
					this.gonggaoshow = true
				} else {
					this.gonggaoshow = false
				}
				// console.log('6666666', this.btclist)
				this.times = setInterval(() => {
					const sjchuo = Date.now() - this.time * 1000
					this.formatDuring(sjchuo)
				}, 1000)
			},
			// 时间戳转天，时分秒
			formatDuring(mss) {
				const days = parseInt(mss / (1000 * 60 * 60 * 24))
				const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
				const seconds = ((mss % (1000 * 60)) / 1000).toFixed(0)
				// console.log(days, hours, minutes, seconds)
				this.day = days
				this.shi = hours
				this.fen = minutes
				this.miao = seconds
			},
			// 签到天数接口
			async Signin() {
				const res = await getSignin()
				console.log(res, '签到')
				this.bgcolor = res.data.sign_count
				this.statustype = res.data.status
				if (res.data.status === 0) {
					this.chick = this.$t('SignIn.Sign')
				} else {
					this.chick = this.$t('SignIn.chick')
				}
			},
			// 签到按钮
			async receive() {
				// this.sigintype = true
				if (this.sigintype) {
					this.sigintype = false
					const res = await getreceive()
					if (res.code !== 1) {
						Toast({
							message: res.msg,
							duration: 1200,
							className: 'noticeWidth' // 所加样式名
						})
					} else {
						Toast({
							message: res.msg,
							duration: 1200,
							className: 'noticeWidth' // 所加样式名
						})
						this.Signin()
					}
					setTimeout(() => {
						this.show = false
						this.sigintype = true
					}, 1000)
				}
				// this.sigintype = false
				// setTimeout(() => {
				//   // 公告弹窗
				//   if (sessionStorage.getItem('gonggaoshow')) {
				//     this.gonggaoshow = false
				//   } else {
				//     this.gonggaoshow = true
				//     sessionStorage.setItem('gonggaoshow', 1)
				//   }
				// }, 1600)
			},
			// 去公告页面
			toAnnouncement() {
				this.$router.push('/announcement')
			},
			// 去签到页面
			tosignin() {
				this.$router.push('/signin')
			},
			// 去k线图
			todiagram(name, bfb, zf) {
				// console.log(name)
				// console.log(bfb)
				this.$router.push({
					path: '/diagram',
					query: {
						name,
						bfb,
						zf
					}
				})
				// this.$router.push({
				//   path: '/gift'
				// })
			},
			//11.15
			// 去提现
			towithdraw() {
				this.$router.push('/withdraw')
				// if(this.dwstatus == 1){
				//   this.$router.push('/withdraw')
				// }else{
				//   Toast({
				//     message: 'Please activate the machine first',
				//     duration: 2000,
				//     className: 'noticeWidth' // 所加样式名
				//   })
				//   this.$router.push('/mining')
				// }
			},
			torecharge() {
				// 去充值页面
				this.$router.push('/recharges')
			},
			//get 我的钱包
			async getmyuserapi() {
				const res = await myuserapi()
				this.walletarr = res.data.user
				this.download = res.data.download
				this.profit_return = parseFloat(this.walletarr.profit_return).toFixed(2)
				this.award_profit = parseFloat(this.walletarr.award_profit).toFixed(2)
				this.today_earn = parseFloat(this.walletarr.today_earn).toFixed(2)
				this.money = parseFloat(this.walletarr.money).toFixed(2)
				this.rate = parseFloat(res.data.rate).toFixed(2)
			},
			// get帮助列表
			async assistdetails() {
				const data = {
					type: 2
				}
				const res = await assistdetailsapi(data)
				// console.log(res)
				this.bangzhulist = res.data
			},
			tohelp(id) { // 跳转帮助详情
				this.$router.push({
					path: '/bangzhudetails',
					query: {
						id
					}
				})
			},
			tokefu() {
				// window.location.href = window.location.protocol + "//" + this.kfurl
				window.location.href = this.kfurl
				// window.open(this.kfurl, "_blank");
			},
			cjx(e) {
				// console.log(e)
				this.turntable = e
			},
			appxz() {
				console.log(this.$imgUrl + this.download.IOS.url)
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
					window.open(this.$imgUrl + this.download.IOS.url)
				} else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
					window.open(this.$imgUrl + this.download.Android.url)
				} else {
					// client = 'PC'
				}
			},
			todetils(e) {
				const target = e.target.nodeName
				// console.log(target)
				// return false
				if (target && target == 'IMG') {
					const targetsrc = e.target.src
					console.log(111, targetsrc)
					this.bigimgshow = true
					this.bigimg = targetsrc
				} else {
					// console.log(id)
					const id = this.detilsid
					this.$router.push({
						path: '/gonggaodetails',
						query: {
							id
						}
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	html {

		// background-color: #060606;
		.homevue {
			width: 100%;
			// height: 140vh;
			background-color: #fff;
			// background-image: url("../assets/indexback.png");
			background-size: cover;
			background-repeat: no-repeat;
			padding: 0rem 1.6rem;
			padding-top: 2.5rem;
			padding-bottom: 9rem;
			overflow-y: scroll;
			// .top {
			//   position: relative;
			//   width: 100%;
			//   display: flex;
			//   justify-content: center;

			//   .logo {
			//     width: 1.9rem;
			//     height: 2.1rem;
			//   }

			//   .title {
			//     color: #fff;
			//     line-height: 2.2rem;
			//     margin-left: 0.4rem;
			//     font-size: 1.6rem;
			//     font-weight: bold;
			//   }

			//   .signIn {
			//     width: 1.75rem;
			//     height: 1.75rem;
			//     position: absolute;
			//     top: 0;
			//     right: 1rem;
			//     z-index: 9;
			//   }
			// }

			.top {
				.topone {
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: #A6A6AB;
					font-size: 1.625rem;

					// opacity: 0.2;
					// filter: blur(27px);
					.toponeright {
						img {
							margin-right: 2rem;
						}
					}
				}

				.toptwo {
					color: #000;
					font-size: 2.125rem;
					font-weight: 700;
				}
			}

			.top .topone img {
				width: 2rem;
				height: 2rem;
			}

			.Broadcast {
				margin-top: 2.5rem;
				z-index: 9;

				::v-deep .van-swipe {
					z-index: 9;
				}

				::v-deep .van-swipe-item {
					width: 34.3rem;
					height: 16.8rem;
					line-height: 16, 8rem;
					text-align: center;
					z-index: 9;

					.hornimg {
						width: 100%;
						height: 100%;
						border-radius: 0.5rem !important;
						z-index: 9;
					}
				}
			}

			.infos {
				display: flex;
				justify-content: space-between;
				background: #F4F6F8;
				margin-top: 1rem;
				padding: 0 1rem;
				border-radius: 10px;

				.gonggao {
					margin-top: 0.5rem;

					.announcement {
						width: 28rem;
						// height: 100%;
					}
				}

				.megaphone {
					width: 1.5rem;
					height: 1.5rem;
					margin-top: 1rem;
				}

				.arrow {
					width: 1.5rem;
					height: 1.5rem;
					margin-top: 1rem;
				}
			}

			.content {
				// width: 33rem;
				// height: 12.1rem;
				// border: 1px solid #0A3D2D;
				// margin: 0 auto;
				// background-color: rgba(255, 255, 255, 0.1);
				border-radius: 1rem;
				margin-top: 1.825rem;
				background: #F4F6F8;
				padding: 1.5rem 0;
				color: #9B9C9D;
				// padding: 1rem;
				// box-shadow: 0rem 0.1rem 0.5rem 0.5rem rgba(255, 255, 255, 0.2);

				.content-top {
					display: flex;
					margin-top: 0.5rem;
					// margin-left: 1.8rem;

					.Money {
						width: 2.05rem;
						height: 2.05rem;
					}

					.content-title {
						line-height: 2.2rem;
						font-size: 1.4rem;
						margin-left: 0.5rem;
					}
				}

				.time {
					width: 100%;
					display: flex;
					justify-content: space-evenly;
					color: #4065FF;

					// margin-top: 2.25rem;
					.time-DAY,
					.time-MIN,
					.time-HOUR,
					.time-SEC {
						width: 25%;
						text-align: center;
						border-right: 1px solid #E1E3E5;
						font-size: 1.5rem;

						.time-title {
							margin-bottom: 0.6rem;
							color: #9B9C9D;
							font-size: 1.2rem;
						}
					}

					.time-SEC {
						border-right: 0px solid #262626;
					}
				}
			}

			.indexbody {
				// background: #26272E;
				border-radius: 0.625rem;
				// padding: 1.875rem 0;
				margin-top: 1rem;
				// .indexbodyx{
				//   width: 27rem;
				//   margin-left: 4rem;
				//   border-bottom: 0.125rem solid #3C3D43;
				// }
			}

			.currencies {
				// margin-top: 2rem;
				color: #333;
				font-size: 2rem;
				font-weight: 700;
				margin-bottom: 2rem;
			}

			.Bitcoin,
			.Bitcoin1,
			.Bitcoin2 {
				// width: 34.2rem;
				height: 5.8rem;
				// background-color: #2b2b2b;
				// background: url("../assets/btc.png") no-repeat;
				background-size: 100% 100%;
				border-radius: 1rem;
				// margin-top: 1.5rem;
				color: #333;
				display: flex;
				justify-content: space-between;
				align-items: center;
				// padding: 1.5rem;

				.Bitcoin-left {
					display: flex;
					align-items: center;

					.Bitcoin-img {
						// margin-top: 0.5rem;
						width: 2.84rem;
						height: 2.84rem;
						margin-right: 1rem;
					}

					.Bitcoin-name {
						padding-top: 0.3rem;
					}
				}

				.Bitcoin-right {
					text-align: right;

					.Price {
						color: #333;
						margin-top: 0.4rem;
						font-weight: bold;
					}

					.increase,
					.red {
						color: #fd1050;
						font-size: 1.3rem;
						// margin-right: 1.5rem;
						margin-top: 0.2rem;
					}

					.increase {
						color: #7C7D81 !important;
					}

					.grend {
						color: #2DBE64;
						font-size: 1.3rem;
						margin-top: 0.2rem;
					}
				}
			}

			.Bitcoin1 {
				// background: url("../assets/eth.png") no-repeat;
				background-size: 100% 100%;
			}

			.Bitcoin2 {
				// background: url("../assets/doge.png") no-repeat;
				background-size: 100% 100%;
			}

			.guanwang {
				width: 100%;
				height: 14rem;
				margin-top: 3rem;
				border-radius: 1rem;

				img {
					width: 100%;
					height: 100%;
					border-radius: 1rem;
				}
			}

			::v-deep .van-popup {
				background-color: #1f1f1f;
			}

			.SignIn-content {
				width: 34.3rem;
				height: 29.15rem;
				background-color: #F4F6F8;
				// margin-top: 2.5rem;
				padding: 1.7rem 1rem;
				border-radius: 1rem;

				.content-box {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.box,
					.bgcolor {
						width: 7.4rem;
						height: 9rem;
						background-color: #D5D5D5;
						text-align: center;
						margin-bottom: 0.9rem;
						border-radius: 0.5rem;

						.day {
							color: #333333;
							font-weight: 600;
							margin-top: 1rem;
						}

						.img {
							width: 3.4rem;
							height: 3.45rem;
							margin: 0 auto;
							margin-top: 1.1rem;
						}
					}

					.bgcolor {
						background-color: #f68520;
						color: #fff;
					}

					.Seven,
					.bgSeven {
						position: relative;
						width: 15.7rem;
						height: 9rem;
						background-color: #D5D5D5;
						padding: 0.8rem;
						display: flex;
						justify-content: space-between;
						// align-items: center;
						border-radius: 0.5rem;

						.info {
							font-size: 1.2rem;
							color: #000;
							font-weight: 600;

							.day {
								width: 4rem;
							}

							.tian {
								display: flex;
								justify-content: space-between;
							}

							.title {
								width: 80%;
								// font-weight: 400;
								color: #969696;
								margin-top: 1rem;
							}
						}

						.img {
							position: absolute;
							bottom: 2.2rem;
							right: 1rem;
							width: 35%;
							height: 5rem;
						}
					}

					.bgSeven {
						background-color: #f68520;
					}
				}

				.button {
					width: 100%;
					text-align: center;
					margin-top: 1.3rem;

					.btn {
						background-color: #f68520;
						border: none;
						width: 25.9rem;
						height: 4.4rem;
						border-radius: 2.2rem;
						color: #fff;
						font-size: 1.4rem;
						text-align: center;
						font-weight: bold;
						line-height: 4.4rem;
					}
				}
			}

			.gonggaobox {
				position: relative;
				text-align: center;

				.gonggao {
					width: 29rem;
				}

				.boxtitle {
					position: absolute;
					top: 9rem;
					left: 50%;
					transform: translateX(-50%);
					color: #000;
					font-size: 1.5rem;
					font-weight: 700;
					// margin-top: 2rem;
				}

				.info {
					position: absolute;
					top: 12rem;
					left: 50%;
					transform: translateX(-50%);
					width: 100%;
					height: 20rem;
					color: #000;
					text-align: left;
					padding: 0rem 1.5rem;
					padding-top: 1rem;
					overflow: auto;
					word-wrap: break-word;
					overflow-x: hidden;
					overflow-wrap: break-word;

					.neirong {
						padding-top: 2rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 1.2rem;

						.neirongtitle {
							width: 24rem;
							// overflow: hidden;
							// white-space: nowrap;
							// text-overflow: ellipsis;
							// -o-text-overflow: ellipsis;
						}

						.right {
							width: 0.8rem;
						}
					}
				}
			}
		}
	}

	/* 修改*/
	.usdt {
		color: #fff;
		font-size: 1.5rem;
		display: flex;
		background: #333;
		border-radius: 1rem;
		padding: 1.5rem;
		justify-content: space-between;
		align-items: center;
		margin-top: 1rem;

		.usdtleft {
			// display: flex;
			// align-items: center;
			width: 100%;

			// border-right: 1px solid #ccc;
			img {
				width: 3rem;
				margin-bottom: 1rem;
			}
		}

		.ustright {
			display: flex;
			width: 100%;
			justify-content: end;
			align-items: center;
		}
	}

	.usdtleft div:nth-child(1) {
		width: 2rem;
		height: 2rem;
		background: #fff;
		border-radius: 50%;
		margin-right: 2rem;
	}

	.ustright div:nth-child(1) {
		font-size: 2rem;
		color: #1FAB87;
		font-weight: 700;
	}

	.indexcj {
		margin-top: 2rem;

		.indexbx {
			display: flex;

			// justify-content: end;
			.cjX {
				width: 2rem;
				height: 2rem;
				margin-top: 2rem;
			}

			.bx {
				width: 6rem;
			}
		}
	}

	// 11.14修改
	.inbody {
		box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
		border-radius: 10px 10px 10px 10px;
		opacity: 1;
		padding: 2rem;
		margin-top: 1.5rem;

		.bodyone {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: 700;

			.bodyonediv {
				width: 7rem;
			}

			img {
				width: 5rem;
				margin-bottom: .5rem;
			}

			div {
				text-align: center;
			}
		}
	}

	// 11.14修改my wallet
	.wallet {
		.wallettitle {
			margin: 2rem 0;
			font-size: 2rem;
			color: #333;
			font-weight: 700;
		}

		.walletbody {
			box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			padding: 1.5rem 2rem;
			font-size: 1.2rem;

			.walletmargin {
				margin-top: 1.5rem;
			}

			.wallzione {
				color: #acacac;
			}

			.wallzitwo {
				color: #02D79A;
			}
		}
	}

	// 11.14 修改插图img
	.ctimg {
		margin-top: 2rem;
	}

	// 11.14 修改help
	.help {
		.helptitle {
			margin: 2rem 0;
			font-size: 2rem;
			color: #333;
			font-weight: 700;
		}

		.helpbody {
			box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			padding: 1.5rem 2rem;
			font-size: 1.4rem;

			.helpbodyone {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 2px solid #FBFBFB;
				padding-bottom: 1rem;
				margin-top: 1rem;

				div {
					font-weight: 700;
				}

				img {
					width: .5rem;
				}
			}
		}
	}

	// 11.14修改bottom
	.bottomone {
		margin-top: 2rem;
		color: #333;
		font-size: 1.3rem;
	}

	// 11.14修改bottomer
	.bottomer {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		margin-top: 2rem;
		font-weight: 700;

		.left {
			text-align: center;
		}
	}

	.bottomer .left div:nth-child(1) {
		color: #333;
	}

	.bottomer .left div:nth-child(2) {
		color: #5979FB;
		margin-top: 1rem;
	}

	.bibody {
		box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
		border-radius: 10px 10px 10px 10px;
		opacity: 1;
		padding: 1.5rem 2rem;
	}

	// 11.20合作平台
	.hezuo {
		margin: 1rem 0;
		margin-top: 2rem;

		.hztitle {
			font-size: 1.5rem;
			font-weight: 700;
		}

		.hzbody {
			box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
			border-radius: 10px 10px 10px 10px;
			// opacity: 1;
			padding: 1.5rem 2rem;
			font-size: 1.3rem;
			font-weight: 700;
			margin-top: 2rem;

			.bodyflex {
				display: flex;
				justify-content: space-between;

				.onediv {
					margin-top: 2rem;
					text-align: left;
				}

				// .onedivright{
				//   margin-left: 0.1rem !important;
				// }
			}

			img {
				width: 2rem;
				margin-right: .5rem;
			}
		}
	}
</style>