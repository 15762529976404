<template>
  <!-- 余额宝 -->
  <div class="Video">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="Video-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"/>
      <div class="title">Yu E Bao</div>
      <div class="img" @click="$router.push('/yuebaodetails')">
        <img src="../assets/record.png" alt="" />
      </div>
    </div>
    <div class="box" v-for="(i,v) in yuebaolist" :key="v" >
      <div class="btn">{{i.name}}</div>
      <div class="content">
        <div>
          <div>Dias</div>
          <div style="margin-top: 1rem; color: #fff;">Random</div>
        </div>
        <div>
          <div>Price</div>
          <div style="margin-top: 1rem; color: #FF5757;">{{i.price}} USDT</div>
        </div>
        <div>
          <div>Daily income</div>
          <div style="margin-top: 1rem; color: #15FBB5;">{{parseFloat(i.price)* i.bili}} USDT</div>
        </div>
      </div>
      <div class="receive">Superiors may receive {{bili}} commission</div>
      <div class="xian"></div>
      <!-- <div class="Number">
        <div>Número de compras</div>
        <div style="color: #fff;">ilimitado</div>
      </div> -->
      <div class="botton" @click="PurchaseonYuebao(i.id)">Request for signature</div>
    </div>
    <div class="fixed">
      <div style="font-size: 1.3rem;">Available:</div>
      <div style="margin-left: .5rem; font-size: 1.3rem; color: #FF5757;">{{balance}}</div>
      <div class="Recharge" @click="tocharge">Reload</div>
    </div>
    </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { balancepreciousapi, PurchaseonYuebaoapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      yuebaolist: [],
      bili: '',
      balance: '',
      frequency: 1,
      isLoading: false
    }
  },
  created () {
    this.Videointroduction()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 余额宝列表
    async Videointroduction () {
      const res = await balancepreciousapi()
      // console.log('666', res)
      this.yuebaolist = res.data.data
      // console.log(this.yuebaolist)
      res.data.data.forEach(i => {
        // console.log(i.bili)
        this.bili = i.bili
        i.bili = parseFloat(i.bili) * 0.01
      })
      this.balance = parseFloat(res.data.balance).toFixed(2)
    },
    // 余额宝购买
    async PurchaseonYuebao (id) {
      if (this.frequency === 1) {
        this.frequency = 0
        const data = {
          pro_id: id
        }
        const res = await PurchaseonYuebaoapi(data)
        // console.log(res)
        if (parseFloat(res.code) === 1) {
          this.frequency = 1
        } else {
          this.frequency = 0
        }
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.Videointroduction()
      } else {
        Toast({
          message: 'failed purchase',
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    // 去充值页面
    tocharge () {
      this.$router.push({
        path: '/recharges',
        query: {
          entrance: 'yuebao'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.Video {
  background-image: url('../assets/yuebao.png');
  background-size: 100% 100%;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Video-title {
     padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .box {
    width: 100%;
    // height: 26.55rem;
    background-color: #1A1A1A;
    margin-top: 1.5rem;
    padding: 1.3rem;
    border-radius: 1rem;
    .btn {
      // width: 10rem;
      width: 100%;
      // height: 2.7rem;
      background-color: #E8A622;
      border-radius: 1.4rem;
      color: #fff;
      font-size: 1.2rem;
      text-align: center;
      // line-height: 2.7rem;
      // padding: .5rem;
      display: inline;
      padding: .5rem 1.5rem;
    }
    .content {
      display: flex;
      color: rgba(255, 255, 255, .4);
      align-items: center;
      justify-content: space-between;
      text-align: center;
      margin-top: 2rem;
    }
    .receive {
      color: #E8A622;
      text-align: center;
      margin-top: 1.5rem;
    }
    .xian {
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, .2);
       margin-top: 1.5rem;
    }
    .Number {
      display: flex;
      justify-content: space-between;
      color: rgba(255, 255, 255, .4);
      margin-top: 1.5rem;
    }
    .botton {
      width: 80%;
      height: 4rem;
      margin-left: 50%;
      transform: translateX(-50%);
      background-color: #15B4FB;
      text-align: center;
      line-height: 4rem;
      color: #fff;
      font-size: 1.2rem;
      border-radius: 2.8rem;
      // margin:2rem 0rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .fixed {
    width: 100%;
    height: 5.5rem;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #1d1c1b;
    position: fixed;
    left: 0;
    bottom: 0;
    color: #fff;
    .Recharge {
     width: 9rem;
     height: 3rem;
     background-color: #15B4FB;
     text-align: center;
     line-height: 3rem;
     border-radius: 2.4rem;
     margin: 0rem 1rem;
    }
  }
}
</style>
